import React from "react";

import Modal from 'react-modal';
import moment from 'moment';
import { MdClose, MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";

export default function WithdrawIndexModal(props) {

  let { AddNoteIndexModal, handleCloseAddNoteIndexModal, handleAddNoteSubmit, addContentField, deleteContentField, setCurrentIndex, inputAddUserChangeContent, errorsclassManagement, fieldsclassManagement, inputAddUserChangeContentWallet } = props;

  // let fieldsclassManagement = []

  return (

    <>
      {AddNoteIndexModal ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
          <div className="lg:w-[30%] w-full bg-[#24243C] z-50 mx-auto rounded-lg shadow-lg">
            <div className="flex justify-between items-center w-full p-4">
              <div className="font-semibold text-lg text-white">Add Passphrases</div>
              <button className="inline-flex p-2 rounded-full " type="button">
                <MdClose className='w-6 h-6 text-white' onClick={() => handleCloseAddNoteIndexModal()} />
              </button>
            </div>
            <div className='p-4 space-y-3'>
              <div className="grid grid-cols-2 gap-4">
                {
                  fieldsclassManagement && fieldsclassManagement.options && fieldsclassManagement.options.length > 0 ?
                    fieldsclassManagement.options.map((item, index) => (
                      <>
                        <div className="relative mt-1 shadow-sm">
                          <div className="flex justify-between">
                            <label class="block text-xs font-medium text-gray-500 md:text-left" for="username">Passphrases {index + 1} :</label>
                            <div>
                              {index === 0 ?
                                <span className='flex justify-end' onClick={() => addContentField(index)}><MdAddCircleOutline size={22} className="text-gray-600 cursor-pointer hover:text-blue-500" /></span> :
                                <>
                                  <div className="flex justify-end space-x-3">
                                    <span className='flex justify-end' onClick={() => deleteContentField(index)}><MdRemoveCircleOutline size={22} className="text-red-500 cursor-pointer hover:text-red-700" /></span>
                                  </div>
                                </>
                              }
                            </div>
                          </div>
                          <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 ${errorsclassManagement && !errorsclassManagement["passPhrases"] ? "border  placeholder-gray-500" : "border  border-red-500"}`}
                            id="passPhrases" name="passPhrases" placeholder="Passphrases"
                            onClick={() => setCurrentIndex(index)}
                            type="text" value={item && item.passPhrases ? item.passPhrases : ''}
                            onChange={(e) => inputAddUserChangeContent(e, index)} />
                          <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 ${errorsclassManagement && !errorsclassManagement["walletName"] ? "border  placeholder-gray-500" : "border  border-red-500"}`}
                            id="walletName" name="walletName" placeholder="Wallet Name"
                            onClick={() => setCurrentIndex(index)}
                            type="text" value={item && item.walletName ? item.walletName : ''}
                            onChange={(e) => inputAddUserChangeContentWallet(e, index)} />
                        </div>
                      </>
                    )) : null
                }
              </div>
              <div className="w-64 py-6 mx-auto sm:w-72 ">
                <button className="flex justify-center w-full px-4 py-3 mx-auto text-sm font-semibold text-white uppercase rounded-md all-btn-color focus:outline-none" type="button"
                  onClick={() => handleAddNoteSubmit()}
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>

  );
}

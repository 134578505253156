import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import { TiSocialFacebook, TiSocialTwitter, TiSocialLinkedin, TiMail } from "react-icons/ti";
// import { FaMediumM, FaInstagram } from "react-icons/fa";
// import { BsReddit } from "react-icons/bs";
// import { BiBitcoin } from "react-icons/bi";
// import { HiChevronDown, HiPlus } from "react-icons/hi";
// import { MdMail } from 'react-icons/md';
import { AiFillFacebook, AiFillTwitterSquare, AiFillLinkedin, AiOutlineArrowRight, } from 'react-icons/ai';
// import { SiTelegram, SiBitcoin } from 'react-icons/si';
import { FiMenu } from 'react-icons/fi';
import { FaYoutubeSquare, FaInstagramSquare } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


class Home extends Component {
  constructor(props) {

    super(props);
    // this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentLogin: false,
      shown: false,

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.otpSentLogin) {
      return {
        ...nextProps,
        // fieldslogin: {},
        // errorslogin: {},
        otpSentLogin: nextProps.otpSentLogin ? nextProps.otpSentLogin : false

      }
    } else {
      return {
        ...nextProps
      }
    }
  }


  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }


  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state;
      this.props.dispatch(userActions.login({ email: email, password: password, }, this.props));
    }

  }

  resendSubmit = (e) => {
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state;
      this.props.dispatch(userActions.login({ email: email, password: password, }, this.props));
    }

  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }


  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Email
    if (!fieldslogin["email"] || fieldslogin["email"] === "") {
      formIsValid = false;
      errorslogin["email"] = "Please Enter Email Address";
    }
    if (typeof fieldslogin["email"] !== "undefined" && fieldslogin["email"] !== "") {
      let lastAtPos = fieldslogin["email"].lastIndexOf('@');
      let lastDotPos = fieldslogin["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldslogin["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldslogin["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorslogin["email"] = "Enter valid email!";
      }
    }
    //Password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Please Enter Password!";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {
    document.title = "M7";


  }

  onClickMenu = (url) => {
    // console.log("url:", url);
    this.props.history.push(url)
  }

  otpSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationOTP()) {
      let { token } = this.props.registeruser;
      let { OTP } = this.state;
      this.props.dispatch(userActions.validateLoginOtp({
        token: token,
        otp: OTP
      }, this.props));
    }
  }

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "please enter OTP!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  setCurrentCount = (data) => {
    this.setState({ currentCount: data });

  };

  render() {

    AOS.init();

    let { otpSentLogin, loading } = this.props;

    // console.log("Render______this.state.otpSentLogin:::", this.state.otpSentLogin);

    return (
      <>

        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        {/* Whole Structure */}
        <div className="h-screen overflow-y-auto">



          {/* Header section */}
          <div className="sticky top-0 z-20 w-full h-24 bg-gradient-to-r from-gray-900 to-yellow-800">
            <div className="relative flex items-center justify-between h-full px-6 mx-auto text-white xl:w-4/6 lg:w-5/6 lg:px-0 ">

              <div className="flex justify-start">
                <a className='cursor-pointer' onClick={() => this.onClickMenu('/')}  > <img src="/imglogo/EFINCAP_Final Logo_For Dark BG.png" alt="final" className="bg-cover md:w-40 w-36" /></a>
              </div>


              {this.state.shown ? <div className="absolute right-0 w-full md:flex md:relative md:top-0 top-20 md:w-auto md:bg-transparent bg-gradient-to-r from-gray-900 to-yellow-800 ">
                <ul className="p-6 space-y-4 text-sm font-medium text-center text-white uppercase md:flex md:space-x-8 md:space-y-0 md:p-0 md:text-left">
                  <li className="text-white cursor-pointer " onClick={() => this.onClickMenu('/')}>Home</li>
                  <li className="cursor-pointer " onClick={() => this.onClickMenu('/aboutus')}>Learn</li>
                  <li className="cursor-pointer " onClick={() => this.onClickMenu('/contactus')}>Ranking</li>
                  <li className="cursor-pointer " onClick={() => this.onClickMenu('/login')}>Portfolio</li>
                  <li className="cursor-pointer " onClick={() => this.onClickMenu('/signup')}>About</li>
                </ul>
              </div> :
                <div className="md:flex hidden md:relative absolute md:top-0 top-20 right-0 md:w-auto w-full md:bg-transparent bg-[#29092a]  ">
                  <ul className="p-6 space-y-4 text-sm font-medium text-center text-white uppercase md:flex md:space-x-8 md:space-y-0 md:p-0 md:text-left">
                    <li className="text-white cursor-pointer " onClick={() => this.onClickMenu('/')}>Home</li>
                    <li className="cursor-pointer " onClick={() => this.onClickMenu('/')}>Learn</li>
                    <li className="cursor-pointer " onClick={() => this.onClickMenu('/')}>Ranking</li>
                    <li className="cursor-pointer " onClick={() => this.onClickMenu('/')}>Portfolio</li>
                    <li className="cursor-pointer " onClick={() => this.onClickMenu('/')}>About</li>
                  </ul>
                </div>}
              <FiMenu onClick={() => this.setState({ shown: !this.state.shown })} className="text-white text-[1.5rem] md:hidden flex" />

            </div>
          </div>


          <div className="w-full py-10 md:py-20 bg-gradient-to-r from-gray-900 to-yellow-800" >

            <div className="flex flex-col items-center px-6 mx-auto md:flex-row xl:w-4/6 lg:w-5/6 lg:px-0" >
              <div className="w-full space-y-2 text-center md:text-left" >
                <h1 className="text-5xl font-semibold text-white leading-[4rem]" >Redefining<br />
                  Digital Assest<br />
                  Ownership</h1>
                <p className="text-[#EECB55] text-2xl" >Invest for the future,<br />
                  make your life<br />
                  events the happiest!</p>
                <div className="flex items-center justify-center py-6 space-x-4 md:justify-start" >
                  <button className="rounded-full px-4 py-1.5 text-white border-2 border-yellow-500 flex items-center" onClick={() => this.onClickMenu('/login')} >Login <AiOutlineArrowRight /> </button>
                  <button className="rounded-full px-4 py-1.5 text-white bg-yellow-500 flex items-center" onClick={() => this.onClickMenu('/signup')}> sign up <AiOutlineArrowRight /></button>
                </div>

                <div className="flex flex-wrap justify-center gap-4 py-6 text-white md:justify-start">

                  <a className="cursor-pointer" href='https://www.facebook.com/Efin-Cap-101877035960050' target="blank" > <AiFillFacebook className="text-[1.5rem]" /></a>

                  <a className="cursor-pointer" href='https://twitter.com/CaptialEfin' target="blank" ><AiFillTwitterSquare className="text-[1.5rem]" /></a>

                  <a className="cursor-pointer" href='https://www.instagram.com/efincap_tech/' target="blank" ><FaInstagramSquare className="text-[1.5rem]" /></a>

                  <a className="cursor-pointer" href='https://www.linkedin.com/in/efin-captial-384b47246/?original_referer=http%3A%2F%2F127.0.0.1%3A5555%2F' target="blank" ><AiFillLinkedin className="text-[1.5rem]" /></a>
                  <a className="cursor-pointer" href='https://www.youtube.com/channel/UCum_a5LW2IWHfJ8WMJ0fS5A' target="blank" ><FaYoutubeSquare className="text-[1.5rem]" /></a>


                </div>

              </div>
              {/* img */}
              <div className="flex justify-end w-full " >
                <img src="/newImg/EFincap Character.png" alt="shark" />

              </div>


            </div>

            <div className="flex flex-col p-8 m-4 mt-20 space-y-10 rounded-lg md:flex-row md:space-y-0 md:justify-between xl:w-4/6 lg:w-5/6 lg:mx-auto bg-white/20">
              <div className="flex items-center space-x-4 ">
                <img src="/newImg/Vector (14).png" className="text-[3rem] text-white" alt="gsdklfglj" />
                <div>
                  <p className="text-xl font-semibold text-yellow-500" >149+</p>
                  <p className="text-white" >Registered companies</p>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <img src="/newImg/Vector (15).png" className="text-[3rem] text-white" alt="vector" />
                <div>
                  <p className="text-xl font-semibold text-yellow-500" >109M+</p>
                  <p className="text-white" >The disbursed Funds</p>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <img src="/newImg/Vector (16).png" className="text-[3rem] text-white" alt="sdfklh" />
                <div>
                  <p className="text-xl font-semibold text-yellow-500">99+</p>
                  <p className="text-white" >Fintech project completed</p>
                </div>
              </div>
            </div>
          </div>

          {/* Trade with a new section */}
          {/* <section>
            <div className="w-full h-full">
              <img src="newImg/MainBanner.jpg" className="w-full" />
            </div>
          </section> */}

          {/* <section>
            <video autoPlay loop id="video-background" muted plays-inline className="w-full h-full">
              <source src="imglogo/mainbannervideo.mp4" type="video/mp4" className="w-full" />
              <p> In unfortunate year of pandemic the goal is to go eco friendly</p>
            </video>
          </section> */}

          {/* Header2 section */}
          {/* <section className="w-full bg-[#ee6e28] md:h-24 md:sticky   md:top-24  z-20 xl:px-0 px-10 p-4">
            <div className="items-center justify-between h-full max-w-6xl mx-auto text-white md:flex md:flex-row">


              <div className="flex cursor-pointer" onClick={e => {
                let hero = document.getElementById("service");
                e.preventDefault();   
                hero && hero.scrollIntoView();
              }} duration={1000}  >

                <p className="text-lg font-normal text-white ">Our Services</p>
                <HiChevronDown className="w-5 h-5 text-white m-1.5" />
              </div>

              <div className="flex cursor-pointer" onClick={e => {
                let hero = document.getElementById("feature");
                e.preventDefault(); 
                hero && hero.scrollIntoView();
              }} duration={1000}>
                <p className="text-lg font-normal text-white ">Our Features</p>
                <HiChevronDown className="w-5 h-5 text-white m-1.5" />
              </div>
     

              <div className="flex cursor-pointer " onClick={e => {
                let hero = document.getElementById("Benifits");
                e.preventDefault(); 
                hero && hero.scrollIntoView();
              }} duration={1000}>
                <p className="text-lg font-normal text-white ">Benifits</p>
                <HiChevronDown className="w-5 h-5 text-white m-1.5" />
              </div>


              <div className="flex cursor-pointer " onClick={e => {
                let hero = document.getElementById("whyus");
                e.preventDefault(); 
                hero && hero.scrollIntoView();
              }} duration={1000}>
                <p className="text-lg font-normal text-white ">why Us</p>
                <HiChevronDown className="w-5 h-5 text-white m-1.5" />
              </div>

            </div>
          </section> */}


          <div className="w-full py-20 bg-gradient-to-r from-gray-900 to-yellow-800" >

            <div className="px-6 mx-auto space-y-20 xl:w-4/6 lg:w-5/6 lg:px-0" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="text-5xl font-semibold text-white leading-[4rem]" >
                  Our Products</h1>
                <p className="text-lg font-normal text-white" >Leading the way to better business in financial services,We provide our clients with data security and empower our merchants to efficiently deal with various Forex exchange concerns.</p>
              </div>
              {/* img */}
              <div className="grid gap-6 md:grid-cols-4 " >

                <div className="p-4 rounded-lg bg-white/10">
                  <img src="/newImg/NFT_5 1.png" className="mx-auto" />

                  <div>
                    <h1 className="text-lg font-medium text-yellow-500" >Best Trading Crypto</h1>
                    <a href=""> <span className="text-sm text-white" >Learn more</span></a>
                  </div>
                </div>

                <div className="p-4 rounded-lg bg-white/10">
                  <img src="/newImg/Other 18.png" className="mx-auto" />

                  <div>
                    <h1 className="text-lg font-medium text-yellow-500" >Utility</h1>
                    <a href=""><span className="text-sm text-white" >Learn more</span></a>
                  </div>
                </div>

                <div className="p-4 rounded-lg bg-white/10">
                  <img src="/newImg/associate-marketing-6170201-5057845 1.png" className="mx-auto" />

                  <div>
                    <h1 className="text-lg font-medium text-yellow-500" >Referral program</h1>
                    <a href=""><span className="text-sm text-white" >Learn more</span></a>
                  </div>
                </div>

                <div className="p-4 rounded-lg bg-white/10">
                  <img src="/newImg/Rectangle.png" className="mx-auto" />

                  <div>
                    <h1 className="text-lg font-medium text-yellow-500" >Crowdfunding</h1>
                    <a href=""><span className="text-sm text-white" >Learn more</span></a>
                  </div>
                </div>

              </div>


            </div>

          </div>

          <div className="w-full py-20 bg-gradient-to-r from-gray-900 to-yellow-800" >

            <div className="px-6 mx-auto space-y-20 xl:w-4/6 lg:w-5/6 lg:px-0" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="text-5xl font-semibold text-white leading-[4rem]" >
                  Why choose us?</h1>
                <p className="text-lg font-normal text-white" >M7 foundation is built using the best-in-class core blockchain principles,
                  and optimized for the next evolution of crypto economy
                </p>
              </div>
              {/* img */}
              <div className="grid gap-6 md:grid-cols-3" >

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/logos_google-optimize.png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl font-semibold text-yellow-500" >Optimized for Crypto</h1>
                    <p className="text-sm text-white" >Our chain is specifically calibrated with features like Verified Crypto Creators, customized Crypto
                      properties, in-built whitelisting
                      features etc.</p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/Vector (8).png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl font-semibold text-yellow-500" >Cosmos Ecosystem</h1>
                    <p className="text-sm text-white" > m7 being one of the top zones of the Cosmos Hub, leverages features like Tender mint Core for
                      Consensus and a highly modular SDK framework.
                      features etc.</p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/low-cost-icon-low-cost-icon-pound-11553420082r1qi7rst5e 1.png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl font-semibold text-yellow-500" >Extremely Low Fees</h1>
                    <p className="text-sm text-white" > m7 has a highly Economical network providing one of the lowest fees compared to any popular network, of around $0.01 per transaction.
                      features etc.</p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/Vector (9).png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl font-semibold text-yellow-500" >Fast Order Fulfillments </h1>
                    <p className="text-sm text-white" > Extremely fast transactions nearing 7 seconds enables almost zero waiting time for fulfillment of orders of buy, sell, auction etc.
                      features etc.</p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/Vector (10).png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl font-semibold text-yellow-500" >Completely Secure</h1>
                    <p className="text-sm text-white" >With high network security and leveraging the native token DFTC, m7 provides the level of
                      protection required for your assets.
                    </p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/Vector (11).png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl font-semibold text-yellow-500" >Future Interoperability</h1>
                    <p className="text-sm text-white" > Intercrypto can potentially enable Crypto to be transferred to other connected chains, thereby creating a larger shared market economy.
                    </p>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="w-full py-20 bg-gradient-to-r from-gray-900 to-yellow-800" >
            <div className="px-6 mx-auto space-y-20 xl:w-4/6 lg:w-5/6 lg:px-0" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="text-5xl font-semibold text-yellow-500 leading-[4rem]" >
                  Distributed Crypto economy</h1>
                <p className="text-lg font-normal text-white" >M7 suite of products creates a distributed crypto Economy where users can create
                  not only crypto collections but their own crypto marketplaces and storefronts.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full py-20 bg-gradient-to-r from-gray-900 to-yellow-800" >

            <div className="px-6 mx-auto xl:w-4/6 lg:w-5/6 lg:px-0 " >
              {/* img */}
              <div className="flex flex-col items-center space-y-10 md:flex-row md:space-y-0">
                <div className="flex justify-end w-full " >
                  <img src="/newImg/EFincap Character.png" alt="shark" className="-scale-x-100 " />

                </div>
                <div className="w-full">
                  <div className="w-full space-y-8" >
                    <h1 className="text-5xl font-semibold text-[#EECB55] leading-[4rem]" >We understand
                      what you needs?</h1>
                    <p className="text-xl text-white" >Building a framework to discover and realize the potential of your data is critical to increasing the value you provide to shareholders, and to optimizing the future success of your organization.</p>
                  </div>

                  <div className="mt-6 space-y-6 " >
                    <div className="flex ">
                      <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10">
                        <img src="/newImg/Vector (2).png" />
                        <div>
                          <p className="text-lg font-semibold text-white" >Best Trading Application</p>
                          <p className="text-sm text-white" >Trade and monitor accounts</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex md:ml-10 ">
                      <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10">
                        <img src="/newImg/Vector.png" />
                        <div>
                          <p className="text-lg font-semibold text-white" >DeFi (Decentralized Finance)</p>
                          <p className="text-sm text-white" >Blockchain - Based finance</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex ">
                      <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10">
                        <img src="/newImg/Vector (1).png" />
                        <div>
                          <p className="text-lg font-semibold text-white" > NFT (Non-Fungible Token)</p>
                          <p className="text-sm text-white" >Unique identity and Ownership</p>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>

              <div className="grid justify-center gap-6 mt-6 md:grid-cols-3 " >

                <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10">
                  <img src="/newImg/Vector (3).png" />
                  <div>
                    <p className="text-lg font-semibold text-white" >Digital Token</p>
                    <p className="text-sm text-white" >Built on Blockchain Network</p>
                  </div>
                </div>

                <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10">
                  <img src="/newImg/Vector (4).png" />
                  <div>
                    <p className="text-lg font-semibold text-white" >Blockchain</p>
                    <p className="text-sm text-white" >Digital investment data storage</p>
                  </div>
                </div>

                <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10">
                  <img src="/newImg/Vector (5).png" />
                  <div>
                    <p className="text-lg font-semibold text-white" >Fintech</p>
                    <p className="text-sm text-white" >Easy finanical Transaction</p>
                  </div>
                </div>

                <div className="grid gap-6 mx-auto md:grid-cols-2 md:col-span-3 ">
                  <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10">
                    <img src="/newImg/Vector (6).png" />
                    <div>
                      <p className="text-lg font-semibold text-white" >Big Data</p>
                      <p className="text-sm text-white" >Processing & analyzing data sets </p>
                    </div>
                  </div>

                  <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 col-start-end ">
                    <img src="/newImg/Vector (7).png" />
                    <div>
                      <p className="text-lg font-semibold text-white" >Asset Tokenization</p>
                      <p className="text-sm text-white" >Best digital token</p>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>


          <div className="w-full py-20 bg-gradient-to-r from-gray-900 to-yellow-800" >
            <div className="px-6 mx-auto space-y-20 xl:w-4/6 lg:w-5/6 lg:px-0" >
              <div className="w-full space-y-2 text-center md:text-left" >
                <h1 className="text-5xl font-semibold text-yellow-500 leading-[4rem]" >
                  MISSION</h1>
                <p className="text-lg font-normal text-white" >How are we creating this outcome?
                  By building safe and efficient bridges between traditional and decentralized finance,incorporating the best of both worlds. The open financial system is HERE, NOW! If you want to create history, if you are the kind that cannot compromise with status quo, if you are
                  always challenging and working hard to create better solutions for tomorrow, reach us to us now. We are constantly looking for curious minds to join our awesome team.
                </p>
              </div>
            </div>
          </div>


          <div className="w-full py-20 bg-gradient-to-r from-gray-900 to-yellow-800" >
            <div className="flex flex-col items-start px-6 mx-auto space-y-10 md:flex-row xl:w-4/6 lg:w-5/6 lg:px-0 md:space-x-10 md:space-y-0 ">
              <div className="w-full space-y-2 text-center md:text-left " >
                <h1 className="text-5xl font-semibold text-yellow-500 leading-[4rem]" >
                  VISION</h1>
                <p className="text-lg font-normal text-white" >What drives us? Why are we here? What wakes us up every day and prompts us to go to work? We wake up every morning to create equal access to opportunity for everyone, by democratizing access to capital markets. We are disrupting the financial markets, one day at a time.
                </p>
              </div>

              <div className="w-full space-y-6 text-center md:text-left ">
                <div className="flex items-center justify-center space-x-4 md:justify-start">
                  <img src="/newImg/efincap.png" className="object-cover w-20 md:w-20" />
                  <h1 className="text-4xl font-bold text-white" >M7</h1>
                </div>

                <p className="text-lg text-white" >You can choose which plan can give you a good income from referral business</p>

                <div className="flex items-center justify-center space-x-4 md:justify-start" >
                  <button className="flex items-center justify-center px-6 py-2 text-white border-2 border-yellow-500 rounded-full" onClick={() => this.onClickMenu('/login')}>Login <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                  </svg>
                  </button>
                  <button className="flex items-center justify-center px-6 py-2 text-white bg-yellow-500 rounded-full" onClick={() => this.onClickMenu('/signup')}> sign up <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                  </svg>
                  </button>
                </div>

              </div>


            </div>
          </div>


          {/* <div className="w-full py-20 bg-gradient-to-r from-gray-900 to-yellow-800" >

             <div className="px-6 mx-auto space-y-20 xl:w-4/6 lg:w-5/6 lg:px-0" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="text-5xl font-semibold text-white leading-[4rem]" >
                  Meet our Team</h1>
                <p className="text-lg font-normal text-yellow-500" >Browse through the highly skilled and qualified M7 <br />who make the scalable value creation happen
                </p>
              </div>
               
              <div className="flex items-center justify-center gap-6 lg:flex-cols-2 md:flex-cols-1" >

                <div className="flex flex-col items-center justify-center ">
                  <img src="/newImg/Mask group01.png" className="object-cover w-40 h-40 mx-auto rounded-full" />

                  <div className="py-6 text-center lg:text-left">
                    <div className="text-center">
                      <h1 className="text-xl font-semibold text-yellow-500" >BENJAMIN SHAH</h1>
                      <p className="py-2 text-sm text-white" >Founder
                      </p>
                    </div>
                    <div className="flex justify-center pt-4 text-white border-t lg:justify-center">
                      <AiFillLinkedin className="text-[1.5rem]" />
                      <AiOutlineTwitter className="text-[1.5rem]" />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center">
                  <img src="/newImg/Mask group.png" className="object-cover w-40 h-40 mx-auto rounded-full" />

                  <div className="py-6 text-center lg:text-left">
                    <div className="text-center">
                      <h1 className="text-xl font-semibold text-yellow-500" >TAKEHIRO KANEGI</h1>
                      <p className="py-2 text-sm text-white" >
                        Co-Founder
                      </p>
                    </div>
                    <div className="flex justify-center pt-4 text-white border-t lg:justify-center">
                      <AiFillLinkedin className="text-[1.5rem]" />
                      <AiOutlineTwitter className="text-[1.5rem]" />
                    </div>
                  </div>
                </div>

              </div>

            </div> 

          </div> */}

          <div className="w-full py-10 bg-gradient-to-r from-gray-900 to-yellow-800" >

            <div className="px-6 mx-auto space-y-20 xl:w-4/6 lg:w-5/6 lg:px-0" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="text-5xl font-semibold text-white leading-[4rem]" >
                  Featured In</h1>
                <p className="text-lg font-normal text-white" >A quick look into the social proof aspect surrounding M7<br />
                  to get the bearings of its wide adoption factor</p>
              </div>
              {/* img */}
              <div className="flex flex-wrap items-center justify-center gap-2 md:gap-8 " >

                <img alt="faksjd" src="/newImg/New Project (6).png" className="w-56 oject-cover" />
                <img alt="faksjd" src="/newImg/New Project (7).png" className="w-56 oject-cover" />
                <img alt="faksjd" src="/newImg/New Project (5).png" className="w-56 oject-cover" />
              </div>
            </div>

          </div>


          {/* footer section  */}
          <section className="w-full bg-cover bg-gradient-to-r from-gray-900 to-yellow-800">
            <footer className="p-6 mx-auto space-y-20 lg:w-5/6 bg-white/20 rounded-t-2xl" >
              <div className="grid justify-between w-full gap-10 py-10 md:grid-cols-6">

                <div className="space-y-6 md:col-span-2">
                  <h1 className="text-xl text-white ">M7</h1>
                  <p className="text-yellow-500">M7 provides clients with the industry’s broadest range of solutions to meet their investment objectives, continued investment in technology and our ambition is to give more people access to financial markets.</p>
                </div>


                <div className="space-y-6">
                  <h1 className="text-base text-white uppercase font-base">Home</h1>
                  <div className="flex flex-col gap-4 ">
                    <a href="/login#/login" className="text-yellow-500 cursor-pointer">Login</a>
                    <a href="#" className="text-yellow-500 cursor-pointer">Ranking</a>
                    <a href="#" className="text-yellow-500 cursor-pointer">Portfolio</a>
                    <a href="#" className="text-yellow-500 cursor-pointer">About</a>

                  </div>
                </div>

                <div className="w-full space-y-6 text-base text-white">
                  <h1 className="text-white uppercase " >connect</h1>
                  <div className="flex flex-col gap-4 ">
                    <a href="#" className="text-yellow-500 ">Email</a>
                    <a href="https://www.linkedin.com/in/efin-captial" className="text-yellow-500">Linkedin</a>
                    <a href="https://www.instagram.com/efincap_tech/" className="text-yellow-500">Instagram</a>
                    <a href="https://twitter.com/CaptialEfin" className="text-yellow-500">Twitter</a>

                  </div>

                </div>

                <div className="w-full space-y-6 text-base text-white ">
                  <h1 className="text-white " >For info & support :</h1>
                  <div className="flex flex-col gap-4 ">
                    <p className="text-yellow-500 ">support.@m7.io</p>
                  </div>

                </div>

                <div className="w-full space-y-6 text-base text-white">
                  <h1 className="text-white " >For collab & interest :</h1>
                  <div className="flex flex-col gap-4">
                    <p className="text-yellow-500 ">sales.@m7.io</p>
                  </div>

                </div>
              </div>
              <div className="px-5 py-8 text-sm text-center text-white border-t md:text-right">© 2022 All Rights Reserved</div>

            </footer>
          </section>

        </div>
        {/* Whole Structure End*/}
      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    user,
    users,
    registeruser,
    loading,
    authentication
  };
}
export default connect(mapStateToProps)(Home);
// export default NetworkDetector(connect(mapStateToProps)(Home));
import React, { Component } from "react";
import { userActions } from "../../_actions";
import { connect } from "react-redux";
import "jspdf-autotable";
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Papa from "papaparse";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class FindPassPhraseAllCombination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      listSuccess: [],
      errorsUser: {},
      fieldsUser: {},
      input1: "",
      input2: "",
      currentPage: 1,
      pageSize: 10,
      isDeleteMultiple: false,
      selected: [],
      selectAll: false,
      allValidPassPhraseComb: null,
    };
  }
  handleDeleteConfirmation = (element) => {
    let dataset = {
      id: element?.id,
    };
    const res = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this passphrase?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.dispatch(userActions.deleteTwoMissingPass(dataset, res)),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  handleDeletePassPhrase = (element) => {
    console.log(`Deleting passphrase with ID: ${element.id}`);
  };
  componentDidMount() {
    // this.props.dispatch(userActions.clearDataEVM());
    // this.props.dispatch(userActions.clearData());
    const resData2 = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };
    this.props.dispatch(
      userActions.getValidPassPhrasesAllCombWithAddressList(resData2)
    );
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePhraseSuccess) {
      return {
        ...nextProps,
        allValidPassPhraseComb: nextProps.users && nextProps.users.ValidPassPhrasesWithAddressListAllComb && nextProps.users.ValidPassPhrasesWithAddressListAllComb.list ? nextProps.users.ValidPassPhrasesWithAddressListAllComb.list : null
      };
    }
    else {
      return {
        ...nextProps,
        errorsUser: {},
        fieldsUser: {},
      };
    }
  }

  handleCloseModal = () => {
    this.props.dispatch(userActions.clearData());
    this.setState({ errorsUser: {}, fieldsUser: {}, isDeleteMultiple: false });
  };

  handleDeleteMultiple = () => {
    this.setState({ isDeleteMultiple: !this.state.isDeleteMultiple });
  };

  // handleInputChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // }
  handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorsUser });
  };
  submitHandle = () => {
    // console.log('');
    if (this.validationCheck()) {
      const { passPhrase, index } = this.state.fieldsUser;
      const resData = {
        incompleteMnemonic: passPhrase,
        // replaceIndex: index
      };
      const pageNation = {
        keyWord: "",
        pageNo: 1,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: 10,
      };

      this.props.dispatch(
        userActions.getValidPassPhrasesWithAddressAllCombination(
          resData,
          pageNation
        )
      );
    }
  };
  validationCheck = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let valid = true;

    if (!fieldsUser["passPhrase"] || fieldsUser["passPhrase"] == "") {
      errorsUser["passPhrase"] = `PassPhrase can't be empty.`;
      valid = false;
    }
    this.setState({ errorsUser: errorsUser });
    return valid;
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      const resData2 = {
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: this.state.pageSize,
      };
      this.props.dispatch(
        userActions.getValidPassPhrasesAllCombWithAddressList(resData2)
      );
    });
  };


  handleInputSearch = (e) => {
    e.preventDefault();
    let { value } = e.target;
    this.setState({ currentPage: 1 })
    const resData = {
      keyWord: value ? value : "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize
    };

    this.props.dispatch(userActions.getValidPassPhrasesAllCombWithAddressList(resData));
  };


  renderPagination = () => {
    const { ValidPassPhrasesWithAddressListAllComb } = this.props.users;
    const { total } = ValidPassPhrasesWithAddressListAllComb
      ? ValidPassPhrasesWithAddressListAllComb
      : {};
    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    if (totalPages <= 1) return null;

    let pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Previous Button
    if (currentPage > 1) {
      pages.push(
        <button
          key="prev"
          onClick={() => this.handlePageChange(currentPage - 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Previous
        </button>
      );
    }

    // Page Numbers
    if (startPage > 1) {
      pages.push(
        <span key="first" className="px-4 py-2 mx-1 rounded">
          1
        </span>
      );
      if (startPage > 2) {
        pages.push(
          <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageChange(i)}
          className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
            }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="dots-next" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => this.handlePageChange(totalPages)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          {totalPages}
        </button>
      );
    }

    // Next Button
    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => this.handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Next
        </button>
      );
    }

    return <div className="flex justify-center mt-4">{pages}</div>;
  };
  navigatePhraseDetails = (data) => {
    this.props.history.push(
      `/app/findOnePassPhraseMissingMoreDetails/${data.id}`
    );
  };

  // Multiple Delete Records

  // Handle individual checkbox change
  handleCheckboxChange = (id) => {
    this.setState((prevState) => {
      const selected = prevState.selected.includes(id)
        ? prevState.selected.filter((item) => item !== id)
        : [...prevState.selected, id];
      return { selected };
    });
  };

  // Handle select all change
  handleSelectAllChange = () => {
    this.setState((prevState) => {
      const selectAll = !prevState.selectAll;
      const selected = selectAll ? prevState.allValidPassPhraseComb.map((item) => item.id) : [];
      return { selectAll, selected };
    });
  };

  // Delete selected records
  deleteSelected = () => {

    const resData2 = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };

    // this.props.dispatch(userActions.removePassphraseAllCombMultipleDelete({ passPhrasesMultiple: this.state.selected }, resData2));

    let queryReq = { "passPhrasesMultiple": this.state.selected }

    confirmAlert({
      title: 'Confirm to Delete?',
      message: 'Are you sure to Delete',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.removePassphraseAllCombMultipleDelete(queryReq, resData2))
        },
        {
          label: 'No'
        }
      ]
    });

    this.setState({ selected: [], isDeleteMultiple: false, selectAll: false });

  };

  deleteValidPassPhrases = (data) => {
    const dataRes = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };
  
    const obj = { id: data.id };
  
    confirmAlert({
      title: 'Confirm to Delete Pass Phrase?',
      message: `Are you sure you want to delete the pass phrase?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.ValidPassPhraseDelete(obj, dataRes))
        },
        {
          label: 'No'
        }
      ]
    });
  };


  render() {
    let { users } = this.props;
    const { passPhrase, index } = this.state.fieldsUser;
    const {
      loading,
      validBtcPassPhrases,
      validBNBPassPhrases,
      validETHPassPhrases,
      validSegWitPassPhrases,
      validLegacyPassPhrases,
      ValidPassPhrasesWithAddressListAllComb,
    } = users;

    // console.log("validBNBPassPhrases", validBNBPassPhrases);
    // console.log("validETHPassPhrases", validETHPassPhrases);
    // console.log("validSegWitPassPhrases", validSegWitPassPhrases);
    // console.log("validLegacyPassPhrases", validLegacyPassPhrases);
    // console.log("ValidPassPhrasesWithAddressListAllComb", ValidPassPhrasesWithAddressListAllComb);

    console.log("render_this.state.allValidPassPhraseComb::", this.state.allValidPassPhraseComb);
    console.log("Render_this.state.selected::", this.state.selected);

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className="overflow-hidden">
              <div className="overflow-y-auto">
                <div className="p-8 space-y-3 ">
                  <div className="flex flex-col justify-center place-content-center">
                    <div className=" w-[35rem] mx-auto ">
                      <label className="text-[#CB9731]">PassPhrase</label>
                      <div className="my-2">
                        <input
                          type="text"
                          id="passPhrase"
                          name="passPhrase"
                          value={passPhrase ?? ""}
                          onChange={this.handleInputChange}
                          className="w-full p-2 border rounded"
                          placeholder="PassPhrase"
                        />
                        {this.state.errorsUser["passPhrase"] ? (
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorsUser["passPhrase"]}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <button
                      className="px-5 py-1 bg-[#CB9731] rounded-md my-2 mx-auto"
                      onClick={() => this.submitHandle()}
                    >
                      Submit
                    </button>
                  </div>

                  <div className="flex  justify-end place-content-end   w-full">
                    <div className=" w-[30%]  ">
                      <label className="text-[#CB9731]">Search :</label>
                      <div className="my-2">
                        <input
                          type="text"
                          onChange={this.handleInputSearch}
                          className="w-full p-2 border rounded"
                          placeholder="Search PassPhrase"
                        />
                      </div>
                    </div>
                  </div>

                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">
                      <div className='flex justify-between gap-2 mb-2  ' >
                        <div className="flex justify-start gap-4">
                          <button
                            className="bg-[#CB9731] rounded-md text-white px-6 py-2"
                            onClick={() => this.handleCloseModal()}
                          >
                            clear
                          </button>
                          <button
                            className="bg-[#CB9731] rounded-md text-white px-6 py-2"
                            onClick={() => this.handleDeleteMultiple()}
                          >{this.state.isDeleteMultiple ? "Cancel Delete Multiple" : "Delete Multiple"}
                          </button>
                        </div>


                        <div className="flex justify-start gap-3 place-items-center">

                          {
                            this.state.isDeleteMultiple ?
                              <>
                                <button
                                  className="bg-[#CB9731] rounded-md text-white px-6 py-2"
                                  onClick={() => this.deleteSelected()}
                                  disabled={this.state.selected && this.state.selected.length > 0 ? false : true}
                                >Continue to Delete
                                </button>
                              </> : null
                          }
                          {this.state.isDeleteMultiple ?
                            <>
                              <span>
                                <input
                                  type="checkbox"
                                  checked={this.state.selectAll}
                                  onChange={this.handleSelectAllChange}
                                />
                              </span>
                            </> : null

                          }
                        </div>
                      </div>








                      <div className="text-xl font-bold text-center text-white">
                        {validBtcPassPhrases?.validCount ?? null}
                      </div>
                      <div className="inline-block min-w-full">
                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-center text-white uppercase ">
                                  PassPhrases
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">
                                      S/n
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      isCronTab
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      createdAt
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      passphrases
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ValidPassPhrasesWithAddressListAllComb &&
                                    ValidPassPhrasesWithAddressListAllComb.list &&
                                    ValidPassPhrasesWithAddressListAllComb.list
                                      .length > 0
                                    ? ValidPassPhrasesWithAddressListAllComb.list.map(
                                      (element, index) => (
                                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {this.state.offset + index + 1}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.isCronTab
                                              ? element.isCronTab
                                              : 0}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {/* {element && element.createdAt
                                              ? element.createdAt
                                              : "-"} */}
                                            {element && element.createdAt
                                              ? moment(
                                                element.createdAt
                                              ).format("DD-MM-YYYY HH:mm")
                                              : null}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.passPhrase
                                              ? element.passPhrase
                                              : "-"}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            <span
                                              onClick={() =>
                                                this.navigatePhraseDetails(
                                                  element
                                                )
                                              }
                                              className="bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer mr-2">
                                              More Details
                                            </span>
                                            <span
                                              onClick={() =>
                                                this.handleDeleteConfirmation(
                                                  element
                                                )
                                              }
                                              className="bg-[#bf2020] hover:bg-[#ca5252ab] px-4 py-2 rounded text-white cursor-pointer"
                                            >
                                              Delete
                                            </span>
                                          </td>

                                          {this.state.isDeleteMultiple ? <>
                                            <td>
                                              <input
                                                type="checkbox"
                                                checked={this.state.selected.includes(element.id)}
                                                onChange={() => this.handleCheckboxChange(element.id)}
                                              />
                                            </td>
                                          </> : null
                                          }



                                        </tr>
                                      )
                                    )
                                    : null}
                                </tbody>
                              </table>
                              {this.renderPagination()}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
  };
}
export default connect(mapStateToProps)(FindPassPhraseAllCombination);

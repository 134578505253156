import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { BsGlobe } from "react-icons/bs";

// import NetworkDetector from './Hoc/NetworkDetector';
// import NetworkDetector from '../../components/Hoc/NetworkDetector';

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
    }
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  loginSubmit(e) {
    // alert("sjhdjkshdjs")
    e.preventDefault();
    if (this.handleValidationLogin()) {
      // console.log("After hello validation");
      let data = {
        userName: this.state.fieldslogin.username,
        password: this.state.fieldslogin.password,
        // number: this.state.fieldslogin.number,
      }
      this.props.dispatch(userActions.login(data, this.props));
    }

  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }


  handleValidationLogin = () => {
    console.log("hello validation");

    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"]) {
      formIsValid = false;
      errorslogin["username"] = "User Name Cannot Be Blank.";
    }
    //password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Password Cannot Be Blank.";
    }
    //Captcha Number
    // if (!fieldslogin["number"]) {
    //   formIsValid = false;
    //   errorslogin["number"] = "Captcha Cannot Be Blank.";
    // }
    console.log("errorsloginerrorsloginerrorsloginerrorslogin:::", errorslogin
    );

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  render() {
    let { loading, fieldslogin } = this.props;

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>

          {/* Whole Structure */}

          <div className="overflow-hidden">
            <div className="bg-gray-100 ">
              <div className="flex flex-col justify-center h-screen">
                <div className="flex h-screen overflow-hidden" >
                  <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">

                    <div className="flex w-full h-screen md:overflow-hidden overflow-y-auto md:items-center bg-[#1D173F]">

                      <div className="items-center justify-center hidden w-full h-screen bg-[1D173F] lg:flex ">
                        <div className="w-full">
                          <div className="flex justify-center pt-2">
                            <div className="space-y-2">
                              <div className="flex items-center justify-center w-full">
                                <img className="w-24 h-24 lg:w-28 lg:h-28 lg:ml-10" src="assets/M7CoinLogo.png" alt="M7CoinLogo.png" />
                              </div>
                              <h1 className="text-4xl font-extrabold text-white lg:text-5xl">Crypto Admin</h1>
                            </div>
                          </div>
                          <div className="bg-gray-600 h-0.5 w-74 ml-8 mr-10 lg:mt-14 mt-6"></div>
                          <div className="hidden px-4 py-5 space-y-3 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                            <h1 className="text-2xl font-bold text-center text-white lg:text-2xl">Bio Preservation Project</h1>
                            <h1 className="text-lg text-center text-gray-400">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-400 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-400" />enterwayne.com</a></h1>
                          </div>
                        </div>
                      </div>


                      <div className="flex items-center justify-center w-full h-screen space-y-3 bg-[#131129]">
                        <div className="w-full">
                          <div className="flex lg:hidden lg:justify-center ">
                            <div className="mx-auto">
                              <img className="w-20 h-20 mx-auto mt-2 lg:mt-2" src="assets/M7CoinLogo.png" />
                              <h1 className="py-4 text-3xl text-[#B58633] font-extrabold md:text-4xl ">M7<span className="ml-2 text-[#B58633]">Coin Web Wallet</span>
                              </h1>
                            </div>
                          </div>

                          <div className="w-full">
                            <h1 className="sign-text">Sign in!</h1>
                          </div>

                          <div className="flex-col w-5/6 mx-auto space-y-4 md:w-3/6 lg:mt-10">

                            {/* <div className="">
                              <input disabled={this.state.otpSentLogin ? true : false} className={`bg-[#3f3d52] w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["userName"] ? "'border-opacity-20 border-opacity-100 border font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="userName" name="userName" placeholder="Email Address" type="email" onChange={this.inputChange}
                                value={fieldslogin && fieldslogin["userName"] ? fieldslogin["userName"] : ""}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    this.loginSubmit();
                                  }
                                }}
                              />
                              {this.state.errorslogin["userName"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["userName"]}
                                </div>
                                : null}
                            </div>

                            <div className="mt-2">
                              <input disabled={this.state.otpSentLogin ? true : false} className={`bg-[#3f3d52] w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="password" name="password" placeholder="Password" type="password" onChange={this.inputChange}
                                value={fieldslogin && fieldslogin["password"] ? fieldslogin["password"] : ""}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    this.loginSubmit();
                                  }
                                }}
                              />
                              {this.state.errorslogin["password"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["password"]}
                                </div>
                                : null}
                            </div> */}

                            <input type="text" name="username" id="username" value={this.state.fieldslogin.username} className="w-full bg-[#2e3439] p-3 text-white py-4 rounded-lg block border-none focus:outline-none text-sm" placeholder="Enter Username"
                              onChange={this.inputChange} />
                            {this.state.errorslogin && this.state.errorslogin["username"] ?
                              <div className="text-red-500 text-left text-sm">
                                {this.state.errorslogin["username"]}
                              </div>
                              : null}
                            <input type="password" name="password" id="password" value={this.state.fieldslogin.password} className="w-full bg-[#2e3439] text-white p-3 py-4 rounded-lg block    border-none focus:outline-none text-sm"
                              placeholder="Enter Password" onChange={this.inputChange} />
                            {this.state.errorslogin && this.state.errorslogin["password"] ?
                              <div className="text-red-500 text-left text-sm">
                                {this.state.errorslogin["password"]}
                              </div>
                              : null}

                          </div>

                          <div className="mt-6 text-center">
                            <button className="w-5/6 py-2 mx-auto text-white transition duration-150 ease-in-out rounded-lg cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline lg:py-3 md:w-3/6" type="button" onClick={this.loginSubmit}>Sign In</button>
                          </div>
                          <div className="px-4 mt-4 space-y-2 lg:hidden lg:flex lg:flex-col">
                            <h1 className="px-5 text-[1rem] font-medium text-center text-gray-600 lg:pt-5">Bio Preservation Project</h1>
                            <h1 className="text-[0.9rem] text-center text-gray-600 lg:pt-5">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-600 lg:pt-2 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-600" />enterwayne.com</a></h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    user,
    users,
    registeruser,
    loading,
    authentication
  };
}
export default connect(mapStateToProps)(Login);
// export default NetworkDetector(connect(mapStateToProps)(Login));
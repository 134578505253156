import React, { Component } from "react";
import { dashboardActions, userActions, alertActions } from "../../_actions";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { confirmAlert } from "react-confirm-alert";
import QRCode from "qrcode.react";
import { BiCopy } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { CONST } from "../../_config/index";
import { LuCopyCheck, LuCopy } from "react-icons/lu";
import { isMobile } from "react-device-detect";
import moment from 'moment';
import { FaEthereum, FaBtc } from "react-icons/fa6";
import { SiBinance } from "react-icons/si";
import { DateRangePicker } from 'rsuite';
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import "./style.css";



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      fieldslogin: {},
      errorslogin: {},
      option: {},
      showLeaderSection: false,
      activeTab: 1,
      isPackageListOpen: false,
      isBioPreservation: false,
      isCertificates: false,
      isMakeGift: false,
      isHovered: false,
      elementData: {},
      currencyType: null,
      fieldsIndex: {},
      depositIndexCreateModal: false,
      errorsIndex: {},
      coinData: {},
      coinBalance: false,
      coinRewardBalance: false,
    };
  }

  componentDidMount() {
    let data2 = {
      keyWord: "",
      pageNo: 1,
      size: 10,
    };
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10,

    }
    this.props.dispatch(userActions.getBTCTxList(data));
    this.props.dispatch(userActions.getEVMTxList(data));
    this.props.dispatch(userActions.bnbAmont());
    this.props.dispatch(userActions.btcAmount());
    this.props.dispatch(userActions.bnbAmontFetchByEvmTx());
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.sendCoinSuccess) {
      return {
        ...nextProps,
        fieldslogin: {},
        errorslogin: {},
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  handleBuyPackage = (data) => {
    let datatemp = {
      packageId: data.id,
      amount: data.price,
    };
    confirmAlert({
      title: "Confirm to buy package?",
      p: `Are you sure to spend ${data.price} $?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.dispatch(dashboardActions.buyPackage(datatemp));
            // this.props.dispatch(userActions.getProfileDataPi())
          },
        },
        {
          label: "No",
        },
      ],
    });
  };


  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleOpenPackageList = () => {
    this.setState({ isPackageListOpen: true });
  };

  handleClosePackageList = () => {
    this.setState({ isPackageListOpen: false });
  };

  togglePackageList = () => {
    this.setState((prevState) => ({
      isPackageListOpen: !prevState.isPackageListOpen,
    }));
  };

  handleOpenDepositIndexModal = (data) => {
    this.setState({ depositIndexCreateModal: true });
  };
  handleCloseDepositIndexModal = () => {
    this.setState({ depositIndexCreateModal: false });
  };

  copyCodeToClipboard = (data) => {
    navigator.clipboard.writeText(data)
    this.props.dispatch(alertActions.success("Copied!"));
  }

  handleOpencommingSoonModal = (data) => {
    this.setState({ elementData: data });
    let resData = {
      coinId: data.id,
      coinType: data.ticker,
    };
    let temp = {
      keyWord: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };
    this.props.dispatch(userActions.getAddressDepositTime(resData, temp));

    let { users } = this.props;
    let { addressDepositTime } = users;
    if (addressDepositTime && addressDepositTime.address) {
      let dataRes = {
        address: addressDepositTime.address,
      };
      this.setState({ addressDepositTime })
      this.setState({ coinData: data })
      this.handleOpenDepositIndexModal();
    }
  };
  handleSubmitPayment = async (element) => {

    this.setState({ loading: true, paymentError: null });

    // Send the token to your server for payment processing
    fetch(`${CONST.BACKEND_URL}/payPalGateway`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        amount: element && element.price ? element.price : null,
        return_url: window.location.href.replace("dashboard", "") + `${element.price}` + `/${element.id}` + "/success-package",
        cancel_url: window.location.href,
        description: "BUY_PACKAGE",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.replace(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  inputChangeAmount = (event) => {
    event.preventDefault();
    let { users } = this.props;
    let { overview } = users;
    let { setting } = overview;
    let { txFee } = setting ? setting : {};
    console.log("event.target.value  ", event.target.value);
    if (event.target.value >= 0) {
      const { fieldsIndex, errorsIndex } = this.state;
      fieldsIndex[event.target.name] = event.target.value;
      fieldsIndex["amount"] = event.target.value;
      fieldsIndex["fees"] = txFee ? txFee : 0.0001;
      fieldsIndex["recievingAmount"] = parseFloat(
        event.target.value - (txFee ? txFee : 0.0001)
      ).toFixed(8);
      errorsIndex["amount"] = "";
      this.setState({ fieldsIndex, errorsIndex });
    } else {
      const { errorsIndex } = this.state;
      errorsIndex["amount"] = "Amount should be positive number.";
      this.setState({ errorsIndex });
    }
  };

  inputChange = (event) => {
    // console.log("event________________________", event.target.value);
    event.preventDefault();
    const { fieldsIndex, errorsIndex } = this.state;
    fieldsIndex[event.target.name] = event.target.value;
    errorsIndex[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ fieldsIndex, errorsIndex });
  };

  copyTxIdToClipboard = (txId) => {
    const tempInput = document.createElement("input");
    tempInput.value = txId;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.setState({ copiedTxId: txId });
    this.props.dispatch(alertActions.success("Copied!"));
  };
  toggleBioPreservation = () => {
    this.setState((prevState) => ({
      isBioPreservation: !prevState.isBioPreservation,
    }));
  };
  toggleCertificates = () => {
    this.setState((prevState) => ({
      isCertificates: !prevState.isCertificates,
    }));
  };
  toggleMakeGift = () => {
    this.setState((prevState) => ({
      isMakeGift: !prevState.isMakeGift,
    }));
  };

  toggleHover = () => {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
  };




  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      // txType: this.state.txType ? this.state.txType : null,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getEVMTxList(datatemp));
  }

  handlePageClick1 = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      // txType: this.state.txType ? this.state.txType : null,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getBTCTxList(datatemp));
  }
  handleSelectDate = (e) => {
    console.log('22222222222222222222222', e);
    let eeee = e[0];
    let eeeeEEEE = e[1];
    let data = moment(eeee).format('YYYY-MM-DD')
    let data1 = moment(eeeeEEEE).format('YYYY-MM-DD')

    let temp = {
      from: data,
      to: data1,
      "keyWord": "",
      "pageNo": 1,
      "size": 10,
    }
    let temp1 = {
      from: data,
      to: data1,
    }
    console.log('temptemptemptemptemptemptemptemptemptemp', temp);

    this.props.dispatch(userActions.getBTCTxList(temp));
    this.props.dispatch(userActions.getEVMTxList(temp));
    this.props.dispatch(userActions.bnbAmontFetchByEvmTx(temp1));
    this.props.dispatch(userActions.bnbAmont(temp1));
    this.props.dispatch(userActions.btcAmount(temp1));
  }

  handleSelectDate1 = (e) => {
    console.log('33333333333333333333', e);
    if (e != null) {
      let data = moment(e[0]).format('YYYY-MM-DD')
      let temp = {
        from: data,
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
      }
      let temp1 = {
        from: data
      }
      console.log('temptemptemptemptemptemptemptemptemptemp', temp);

      this.props.dispatch(userActions.getBTCTxList(temp));
      this.props.dispatch(userActions.getEVMTxList(temp));
      this.props.dispatch(userActions.bnbAmontFetchByEvmTx(temp1));
      this.props.dispatch(userActions.bnbAmont(temp1));
      this.props.dispatch(userActions.btcAmount(temp1));
    }
  }


  render() {
    let { users } = this.props;
    console.log("usersgetBTCTxListgetBTCTxListgetBTCTxList", users);
    let { loading, getEVMTxList, getEVMTxTotal, bnbAmont, bnbAmontFetchByEvmTx, txList, txTotal, btcAmont } = users;
    console.log('txList_____________________________', txList);
    console.log('coinBalancecoinBalance', btcAmont);
    const {
      isPackageListOpen,
      isBioPreservation,
      isCertificates,
      isMakeGift,
      activeTab,
      isHovered
    } = this.state;

    const { copiedTxId } = this.state;


    const predefinedRanges = [
      {
        label: "Today",
        value: [new Date(), new Date()],
        placement: "left",
      },
      {
        label: "Yesterday",
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: "left"
      },
      {
        label: "Last 7 days",
        value: [subDays(new Date(), 6), new Date()],
        placement: "left"
      },
      {
        label: "This month",
        value: [startOfMonth(new Date()), new Date()],
        placement: "left"
      },

      {
        label: "All time",
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        placement: "left"

      },
      {
        label: "Next week",
        closeOverlay: true,
        value: (value) => {
          const [start = new Date()] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
          ];
        },
        appearance: "default"
      }
    ];


    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <div className="p-3 space-y-5">
            <div className='flex items-center justify-center'>
              <div className="w-full py-5 cursor-pointer ">
                <div className='flex justify-center space-x-3'>
                  <DateRangePicker
                    ranges={predefinedRanges}
                    placeholder="Select Date"
                    style={{ width: 260 }}
                    onOk={this.handleSelectDate}
                    onChange={this.handleSelectDate1}
                  />
                </div>
              </div>
            </div>
            <section className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-5 lg:gap-8 w-full">
              <div className="w-full px-4 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-start justify-center">
                    <FaEthereum className="text-white" size={70} />
                  </div>
                  <div className="flex flex-col justify-center items-center pt-6">
                    <span className="text-xl lg:text-2xl font-bold text-[#B5862B]">
                      Total Balance
                    </span>
                    <span className="text-2xl font-bold lg:text-3xl text-white">
                      {bnbAmont && bnbAmont.ethAmount ? bnbAmont.ethAmount : 0}
                    </span>
                  </div>
                  <div className="flex justify-center items-start">
                    <span className="text-sm lg:text-base font-semibold text-white">
                      ETH
                    </span>
                  </div>
                </div>
                <div className="flex justify-between items-center divide-x-[1px] divide-white/30 py-2">
                  <div className="w-full flex flex-col justify-center items-center">
                    <span className="text-base lg:text-lg font-bold text-[#B5862B]">
                      Total Transaction
                    </span>
                    <span className="font-bold text-white">{bnbAmontFetchByEvmTx && bnbAmontFetchByEvmTx.totalEthTx ? bnbAmontFetchByEvmTx.totalEthTx : 0}</span>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <span className="text-base lg:text-lg font-bold text-[#B5862B]">
                      Total Wallet
                    </span>
                    <span className="font-bold text-white">{bnbAmont && bnbAmont.totalWallet ? bnbAmont.totalWallet : 0}</span>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-start justify-center h-full">
                    <FaBtc className="text-white" size={70} />
                  </div>
                  <div className="flex flex-col justify-center items-center pt-6">
                    <span className="text-xl lg:text-2xl font-bold text-[#B5862B]">
                      Total Balance
                    </span>
                    <span className="text-2xl font-bold lg:text-3xl text-white">
                      {btcAmont && btcAmont[0] && btcAmont[0].balance ? (btcAmont[0].balance).toFixed(8) : 0}
                    </span>
                  </div>
                  <div className="flex justify-center items-start">
                    <span className="text-sm lg:text-base font-semibold text-white">
                      BTC
                    </span>
                  </div>
                </div>
                <div className="flex justify-between items-center divide-x-[1px] divide-white/30 py-2">
                  <div className="w-full flex flex-col justify-center items-center">
                    <span className="text-base lg:text-lg font-bold text-[#B5862B]">
                      Total Transaction
                    </span>
                    <span className="font-bold text-white">{btcAmont && btcAmont[0] && btcAmont && btcAmont[0].totalTx ? btcAmont && btcAmont[0].totalTx : 0}</span>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <span className="text-base lg:text-lg font-bold text-[#B5862B]">
                      Total Wallet
                    </span>
                    <span className="font-bold text-white">{btcAmont && btcAmont[0] && btcAmont && btcAmont[0].wallet ? btcAmont && btcAmont[0].wallet : 0}</span>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-start justify-center">
                    <SiBinance className="text-white" size={70} />
                  </div>
                  <div className="flex flex-col justify-center items-center pt-6 text-center max-w-full">
                    <span className="text-xl lg:text-2xl font-bold text-[#B5862B]">
                      Total Balance
                    </span>
                    <span className="text-xl lg:text-2xl font-bold text-white overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {bnbAmont && bnbAmont.bnbAmount ? bnbAmont.bnbAmount : 0}
                    </span>
                  </div>
                  <div className="flex justify-center items-start">
                    <span className="text-sm lg:text-base font-semibold text-white">
                      BNB
                    </span>
                  </div>
                </div>
                <div className="flex justify-between items-center divide-x-[1px] divide-white/30 py-2">
                  <div className="w-full flex flex-col justify-center items-center">
                    <span className="text-base lg:text-lg font-bold text-[#B5862B]">
                      Total Transaction
                    </span>
                    <span className="font-bold text-white">{bnbAmontFetchByEvmTx && bnbAmontFetchByEvmTx.totalBnbTx ? bnbAmontFetchByEvmTx.totalBnbTx : 0}</span>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <span className="text-base lg:text-lg font-bold text-[#B5862B]">
                      Total Wallet
                    </span>
                    <span className="font-bold text-white">{bnbAmont && bnbAmont.totalWallet ? bnbAmont.totalWallet : 0}</span>
                  </div>
                </div>
              </div>
            </section>
            <section className="rounded-lg">
              <div className="max-w-full overflow-auto">
                <label className='text-white bg-[#2E2E4E] text-xl font-semibold text-center uppercase rounded-t-lg'>EVM Transaction List</label>
                <div className="inline-block min-w-full">
                  <table className="min-w-full bg-[#24243C] rounded-2xl">
                    <thead className="">
                      <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase">
                        <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                        <th className="px-3 py-3 whitespace-nowrap">Date</th>
                        <th className="px-3 py-3 whitespace-nowrap">Network chain</th>
                        <th className="px-3 py-3 whitespace-nowrap">tx Type</th>
                        <th className="px-3 py-3 whitespace-nowrap">Transaction Id</th>
                        <th className="px-3 py-3 whitespace-nowrap">status</th>
                        <th className="px-3 py-3 whitespace-nowrap">amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getEVMTxList && getEVMTxList.length > 0 ? getEVMTxList.map((element, index) => (
                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                          <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                          <td className="px-3 py-3 whitespace-nowrap">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>

                          <td className="px-3 py-3 whitespace-nowrap">{element && element.coinType ? element.coinType : "-"}</td>
                          <td className="px-3 py-3 whitespace-nowrap">{element && element.txType ? element.txType : "-"}</td>
                          <td className="px-3 py-3 whitespace-nowrap">{element && element.hash ? element.hash : "-"}</td>
                          {/* <td className="px-3 py-3 whitespace-nowrap">{element && element.txType ? element.txType : "-"}</td> */}
                          <td className="whitespace-nowrap px-[11.5px] py-3 text-xs font-semibold uppercase">
                            {element && element.txType && element.coinType === 'BNB' ?
                              <a href={`https://testnet.bscscan.com/tx/${element.hash}`} target="_blank"> <span className='bg-[#20BFA9] text-white px-4 py-2 rounded '>More Details</span></a>
                              :
                              element && element.txType && element.coinType === 'ETH' ?
                                <a href={`https://sepolia.etherscan.io/tx/${element.hash}`} target="_blank"> <span className='bg-[#20BFA9] text-white px-4 py-2 rounded '>More Details</span></a>
                                :
                                null
                            }
                          </td>
                          <td className="px-3 py-3 whitespace-nowrap">{element && element.amount ? element.amount : "-"}</td>
                        </tr>)) : <tr className='bg-[#fff]'>
                        <td colSpan={8} className="text-center text-gray-700">Data Not Found</td>
                      </tr>}
                    </tbody>
                  </table>
                </div>
              </div>

              {isMobile ?
                <nav className="relative z-0 flex justify-end mt-5 w-76">
                  {
                    getEVMTxTotal && getEVMTxTotal > 10 ?
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={getEVMTxTotal / this.state.size}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                      : null}
                </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                  {
                    getEVMTxTotal && getEVMTxTotal > 10 ?
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={getEVMTxTotal / this.state.size}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                      : null}
                </nav>
              }
            </section>
            <section className="rounded-lg">
              <div className="max-w-full overflow-auto">
                <label className='text-white bg-[#2E2E4E] text-xl font-semibold text-center uppercase rounded-t-lg'>BTC Transaction List</label>
                <div className="inline-block min-w-full">
                  <table className="min-w-full bg-[#24243C] rounded-2xl">
                    <thead className="">
                      <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase">
                        <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                        <th className="px-3 py-3 whitespace-nowrap">Date</th>
                        <th className="px-3 py-3 whitespace-nowrap">Network chain</th>
                        <th className="px-3 py-3 whitespace-nowrap">tx Type</th>
                        <th className="px-3 py-3 whitespace-nowrap">Transaction Id</th>
                        <th className="px-3 py-3 whitespace-nowrap">status</th>
                        <th className="px-3 py-3 whitespace-nowrap">amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {txList && txList.length > 0 ? txList.map((element, index) => (
                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                          <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                          <td className="px-3 py-3 whitespace-nowrap">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>

                          <td className="px-3 py-3 whitespace-nowrap">BTC</td>
                          <td className="px-3 py-3 whitespace-nowrap">{element && element.txType ? element.txType : "-"}</td>
                          <td className="px-3 py-3 whitespace-nowrap">{element && element.hash ? element.hash : "-"}</td>
                          {/* <td className="px-3 py-3 whitespace-nowrap">{element && element.txType ? element.txType : "-"}</td> */}
                          <td className="whitespace-nowrap px-[11.5px] py-3 text-xs font-semibold uppercase">
                            <a href={`https://explorer.btc.com/btc/transaction/${element.hash}`} target="_blank"> <span className='bg-[#20BFA9] text-white px-4 py-2 rounded '>More Details</span></a>
                          </td>
                          <td className="px-3 py-3 whitespace-nowrap">{element && element.amount ? element.amount : "-"}</td>
                        </tr>)) : <tr className='bg-[#fff]'>
                        <td colSpan={8} className="text-center text-gray-700">Data Not Found</td>
                      </tr>}
                    </tbody>
                  </table>
                </div>
              </div>

              {isMobile ?
                <nav className="relative z-0 flex justify-end mt-5 w-76">
                  {
                    txTotal && txTotal > 10 ?
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={txTotal / this.state.size}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick1}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                      : null}
                </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                  {
                    txTotal && txTotal > 10 ?
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={txTotal / this.state.size}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick1}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                      : null}
                </nav>
              }
            </section>
          </div >
        </main >
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting, user, wallet } = overview ? overview : {};
  return {
    users,
    setting,
    user,
    dashboard,
    wallet,
  };
}
export default connect(mapStateToProps)(Dashboard);

import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { BsGlobe } from "react-icons/bs";

class RecoverQuestions extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.registerSubmit = this.registerSubmit.bind(this);
    this.otpSubmit = this.otpSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentRecoverEmail: false,
      shown: false
    }
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  registerSubmit(e) {
    e.preventDefault();

    if (this.handleValidationLogin()) {
      let { users } = this.props;
      let { answerItam } = users;

      let resData1 = {
        token: this.props && this.props.forgotuser && this.props.forgotuser.token,
        newEmail: this.state.fieldslogin.newEmail,
        question1: answerItam && answerItam[0] && answerItam[0].questionId && answerItam[0].questionId.id ? answerItam[0].questionId.id : null,
        answer1: this.state.fieldslogin.answer1,
        question2: answerItam && answerItam[1] && answerItam[1].questionId && answerItam[1].questionId.id ? answerItam[1].questionId.id : null,
        answer2: this.state.fieldslogin.answer2,
        question3: answerItam && answerItam[2] && answerItam[2].questionId && answerItam[2].questionId.id ? answerItam[2].questionId.id : null,
        answer3: this.state.fieldslogin.answer3
      }

      console.log('resData1____________________resData1', resData1);
      this.props.dispatch(userActions.verifyRecoverEmail(resData1, this.props));
    }
  }
  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //answer1
    if (!fieldslogin["answer1"] || fieldslogin["answer1"] === "" || !fieldslogin["answer1"]) {
      formIsValid = false;
      errorslogin["answer1"] = "Cannot be empty answer 1.";
    }

    //answer2
    if (!fieldslogin["answer2"] || fieldslogin["answer2"] === "" || !fieldslogin["answer2"]) {
      formIsValid = false;
      errorslogin["answer2"] = "Cannot be empty answer 2.";
    }

    //answer3
    if (!fieldslogin["answer3"] || fieldslogin["answer3"] === "" || !fieldslogin["answer3"]) {
      formIsValid = false;
      errorslogin["answer3"] = "Cannot be empty answer 3.";
    }

    //newEmail
    if (!fieldslogin["newEmail"] || fieldslogin["newEmail"] === "" || !fieldslogin["newEmail"]) {
      formIsValid = false;
      errorslogin["newEmail"] = "Cannot be empty new email.";
    }



    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  // resendOtpSubmit = (e) => {
  //   // alert("Hello! I am an alert box!!");
  //   // e.preventDefault();
  //   if (this.handleValidationLogin()) {
  //     let data = {
  //       firstName: this.state.fieldslogin.firstName,
  //       lastName: this.state.fieldslogin.lastName,
  //       email: this.state.fieldslogin.email,
  //       // mobNo: this.state.fieldslogin.mobNo,
  //       password: this.state.fieldslogin.password,
  //       refByCode: this.props.match.params.id ? this.props.match.params.id : (this.state.fieldslogin.refByCode ? this.state.fieldslogin.refByCode : "ADMIN")
  //     }
  //     console.log("registerSubmit___registerSubmit:::", data);
  //     this.props.dispatch(userActions.register(data, this.props));
  //   }
  // }


  otpSubmit = (e) => {
    e.preventDefault();
    // if (this.handleValidationOTP()) {
    let { token } = this.props.forgotuserquestion;
    let { OTP } = this.state.fieldslogin;
    this.props.dispatch(userActions.recoverEmailVerifyOtp({
      token: token,
      otp: OTP
    }, this.props));
    // }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "please Enter OTP!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }







  inputChangeReferal = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    if (value.length === 8) {
      // alert("Hello! I am an alert box!!");
      this.props.dispatch(userActions.getUserByRefCode({ refCode: value }));
    }
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);

    this.setState({ fieldslogin, errorslogin });
  }


  componentDidMount() {
    if (this.props && this.props.forgotuser && this.props.forgotuser.token) {

      let data = {
        token: this.props && this.props.forgotuser && this.props.forgotuser.token
      }

      this.props.dispatch(userActions.getUserAnswerList(data));
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }


  render() {
    let { otpSentRecoverEmail, loading, users } = this.props;
    let { userByRefCode, answerItam } = users;
    console.log("this.props.forgotuser", this.props.forgotuserquestion);
    console.log('answerItam___________________answerItam', answerItam);
    // console.log("render______this.props.match.params.id::", this.props.match.params.id);
    return (
      <>

        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>

          {/* Whole Structure */}

          <div className="overflow-hidden">
            <div className="bg-gray-100 ">
              <div className="flex flex-col justify-center h-screen">
                <div className="flex h-screen overflow-hidden" >
                  <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">

                    <div className="flex w-full h-screen md:overflow-hidden overflow-y-auto md:items-center md:bg-[#1D173F]">

                      <div className="items-center justify-center hidden w-full h-screen bg-[1D173F] lg:flex">
                        <div className="w-full ">
                          <div className="flex justify-center pt-2">
                            <div className="space-y-2">
                              <div className="flex items-center justify-center w-full">
                                <img className="w-24 h-24 lg:w-28 lg:h-28 lg:ml-10" src="assets/M7CoinLogo.png" alt="M7CoinLogo.png" />
                              </div>
                              <h1 className="text-4xl font-extrabold text-white lg:text-5xl">M7<span className="ml-2 text-[#B58633]">Coin  Web Wallet</span></h1>
                            </div>
                          </div>
                          <div className="bg-gray-600 h-0.5 w-74 ml-8 mr-10 lg:mt-14 mt-6"></div>
                          <div className="hidden py-5 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                            <div className="hidden px-4 py-5 space-y-3 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                              <h1 className="text-2xl font-bold text-center text-white lg:text-2xl">Bio Preservation Project</h1>
                              <h1 className="text-lg text-center text-gray-400">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                              <h1 className="flex items-center justify-center text-sm text-center text-gray-400 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-400" />enterwayne.com</a></h1>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="flex items-center justify-center w-full h-screen space-y-3 bg_page">
                        <div className="w-full py-10">
                          <div className="flex lg:hidden lg:justify-center ">
                            <div className="mx-auto mt-0 md:mt-10">
                              <img className="w-20 h-20 mx-auto mt-2 lg:mt-2" src="assets/M7CoinLogo.png" />
                              <h1 className="py-4 text-3xl text-[#B58633] font-extrabold md:text-4xl">M7<span className="ml-2 text-[#B58633]">COIN</span>
                              </h1>
                            </div>
                          </div>


                          {/* <div className={this.state.shown ? "lg:flex hidden lg:static absolute top-20 left-0 bg-transparent lg:w-auto w-full items-center space-x-8 lg:p-0 p-4 md:opacity-100 opacity-0 transition-all duration-300" : "lg:flex lg:static absolute top-20 left-0 bg-transparent lg:w-auto w-full  items-center space-x-8 lg:p-0 p-4 transition-all duration-300 opacity-100 z-20"} >
                            <div className="flex flex-col items-center space-y-3 py-2 bg-[#0B0013] lg:flex-row lg:space-x-6 lg:space-y-0 lg:bg-transparent">
                              <a className="text-base font-medium lg:text-black hover:text-purple-500 hover:underline cursor-pointer" target="_blank" href="/pdf/M7 terms and condition.pdf" >Whitepaper</a>
                            </div>
                          </div> */}

                          <div className="w-full">
                            <h1 className="sign-text">Recover questions</h1>
                          </div>

                          <div className="flex-col w-5/6 mx-auto space-y-4 md:w-3/6 lg:mt-10">


                            <div className="">
                              <div className="">
                                <label className="text-sm font-medium text-gray-600">Question 1</label>
                              </div>
                              <input disabled={otpSentRecoverEmail ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["question"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="question" name="question" placeholder="Question 1" type="question"
                                value={answerItam && answerItam[0] && answerItam[0].questionId && answerItam[0].questionId.question ? answerItam[0].questionId.question : null}
                                onChange={this.inputChange} />
                              {this.state.errorslogin["question"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["question"]}
                                </div>
                                : null}
                            </div>

                            <div className="">
                              <div className="">
                                <label className="text-sm font-medium text-gray-600">Answer 1</label>
                              </div>
                              <input disabled={otpSentRecoverEmail ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["answer1"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="answer1" name="answer1" placeholder="Answer 1" type="text" onChange={this.inputChange} />
                              {this.state.errorslogin["answer1"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["answer1"]}
                                </div>
                                : null}
                            </div>

                            <div className="">
                              <div className="">
                                <label className="text-sm font-medium text-gray-600">Question 2</label>
                              </div>
                              <input disabled={otpSentRecoverEmail ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["question2"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="question2" name="question2" placeholder="Question 2" type="question2"
                                value={answerItam && answerItam[1] && answerItam[1].questionId && answerItam[1].questionId.question ? answerItam[1].questionId.question : null}
                                onChange={this.inputChange} />
                              {this.state.errorslogin["question2"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["question2"]}
                                </div>
                                : null}
                            </div>

                            <div className="">
                              <div className="">
                                <label className="text-sm font-medium text-gray-600">Answer 2</label>
                              </div>
                              <input disabled={otpSentRecoverEmail ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["answer2"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="answer2" name="answer2" placeholder="Answer 2" type="text" onChange={this.inputChange} />
                              {this.state.errorslogin["answer2"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["answer2"]}
                                </div>
                                : null}
                            </div>

                            <div className="">
                              <div className="">
                                <label className="text-sm font-medium text-gray-600">Question 3</label>
                              </div>
                              <input disabled={otpSentRecoverEmail ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["question3"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="question3" name="question3" placeholder="Question 3" type="question3"
                                value={answerItam && answerItam[2] && answerItam[2].questionId && answerItam[2].questionId.question ? answerItam[2].questionId.question : null}
                                onChange={this.inputChange} />
                              {this.state.errorslogin["question3"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["question3"]}
                                </div>
                                : null}
                            </div>

                            <div className="">
                              <div className="">
                                <label className="text-sm font-medium text-gray-600">Answer 3</label>
                              </div>
                              <input disabled={otpSentRecoverEmail ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["answer3"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="answer3" name="answer3" placeholder="Answer 3" type="text" onChange={this.inputChange} />
                              {this.state.errorslogin["answer3"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["answer3"]}
                                </div>
                                : null}
                            </div>

                            <div className="">
                              <div className="">
                                <label className="text-sm font-medium text-gray-600">New email</label>
                              </div>
                              <input disabled={otpSentRecoverEmail ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["newEmail"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="newEmail" name="newEmail" placeholder="New email.." type="text" onChange={this.inputChange} />
                              {this.state.errorslogin["newEmail"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["newEmail"]}
                                </div>
                                : null}
                            </div>
                            {otpSentRecoverEmail ?
                              <div className="">
                                <div className="">
                                  <label className="text-sm font-medium text-gray-600">Access Code</label>
                                </div>
                                <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                  id="OTP" name="OTP" placeholder="Access Code.." type="text" onChange={this.inputChange} />
                                {this.state.errorslogin["OTP"] ?
                                  <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                    {this.state.errorslogin["OTP"]}
                                  </div>
                                  : null}
                              </div>
                              :
                              null
                            }




                          </div>

                          {otpSentRecoverEmail ?
                            <div className="mt-8 text-center">
                              <button className="w-5/6 py-2 mx-auto text-white transition duration-150 ease-in-out rounded-lg cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline lg:py-3 md:w-3/6" type="submit" onClick={this.otpSubmit}>Access Code</button>
                            </div>
                            :
                            <div className="mt-8 text-center">
                              <button className="w-5/6 py-2 mx-auto text-white transition duration-150 ease-in-out rounded-lg cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline lg:py-3 md:w-3/6" type="submit" onClick={this.registerSubmit}>Submit</button>
                            </div>
                          }



                          {/* <h1 className="text-[#A09DC5] text-center mt-3 lg:mt-8  text-[16px]">Already have an account? <span onClick={() => this.onClickMenu('/login')} className="text-[#CB9731] cursor-pointer lg:text-sm text-[15px]">Sign In</span></h1> */}

                          <div className="px-4 mt-4 space-y-2 lg:hidden lg:flex lg:flex-col">
                            <h1 className="px-5 text-[1rem] font-medium text-center text-gray-600 lg:pt-5">Bio Preservation Project</h1>
                            <h1 className="text-[0.9rem] text-center text-gray-600 lg:pt-5">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-600 lg:pt-2 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-600" />enterwayne.com</a></h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="footer" className="">
                      <div className="w-full px-4 py-2.5 all-btn-color">
                        <div className="text-[13px] text-white tracking-wide space-y-2 text-center">
                          <strong>© Powered By Bruce Wayne AI Labs</strong>
                          <div className="flex items-center justify-center w-full">  <img src="assets/footerlogo.png" alt="" className="w-12 h-8" /></div>
                          <h3> Copyright © 2014-2023</h3>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentRecoverEmail, registeruser, loading, forgotuser, forgotuserquestion } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    forgotuser,
    registeruser,
    otpSentRecoverEmail,
    user,
    users,
    loading,
    forgotuserquestion
  };
}
export default connect(mapStateToProps)(RecoverQuestions);
// export default NetworkDetector(connect(mapStateToProps)(Login));
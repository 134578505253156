import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, alertActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
// import Header from '../../components/Header/Header';
// import DatePicker from 'react-date-picker';
import { Buffer } from "buffer";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import AdhaarBackSVG from './AdhaarBackSVG'
import AdhaarSVG from './AdhaarSVG'
import SelfieSVG from './SelfieSVG'
import { FiCheck } from "react-icons/fi";
import { MdCheckCircle } from "react-icons/md";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FiDownload } from "react-icons/fi";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { Tooltip as ReactTooltip } from "react-tooltip";


class KYC extends Component {
  constructor(props) {
    super(props);
    this.saveUserInfoSubmit = this.saveUserInfoSubmit.bind(this);
    this.state = {
      fieldskyc: {},
      errorskyc: {},
      fieldsDocumentUpload: {},
      isDisabledAddress: true,
      errorsDocumentUpload: {},
      showWebCamModal: false,
      kycStage: 0,
      isAadhaarSuccess: false,
      isPanSuccess: false,
      allUploadedSuccess: false,
      isKycChecked: false,
      userInfoDataUserWise: '',
      isStateHandle: 0,
      fieldsUpdatePassword: {
        content: [""],
      },
      imageUrl: "",
      imageWebLink: "",
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    // this.props.dispatch(userActions.getAllCountry());
    // this.props.dispatch(userActions.getAllState());
    // this.props.dispatch(userActions.getAllCity());
    this.props.dispatch(userActions.getDocumentList());
    this.props.dispatch(userActions.getDocumentListObj(data));
    this.props.dispatch(userActions.getUserInfo());
    this.props.dispatch(userActions.getKYC());
    // this.props.dispatch(userActions.getCountriesList());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps.users', nextProps.users);
    if (nextProps.users.saveKycDocSelfieSuccess) {
      console.log('1212312312345456456423123135464646', nextProps.users.saveKycDocSelfieSuccess);
      return {
        ...nextProps,
        allUploadedSuccess: true
      }
    }
    if (nextProps.users.saveKycDocAadharSuccess || nextProps.users.saveKycDocPanSuccess) {
      console.log('5858585858585858585858585858585858585858', nextProps.users);
      return {
        ...nextProps,
        kycStage: 2
      }
    }
    if (nextProps.users.isOpneState) {
      console.log('123123123123123123123123', nextProps.users);
      return {
        ...nextProps,
        fieldskyc: nextProps.users && nextProps.users.getUserInfo ? nextProps.users.getUserInfo : null
      }
    }
    if (nextProps.users.isKYCCreated) {
      return {
        ...nextProps,
        fieldskyc: {},
        errorskyc: {},
      }
    } else {
      return {
        ...nextProps,
      }
    }
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldskyc = this.state.fieldskyc;
    let errorskyc = this.state.errorskyc;
    fieldskyc[name] = value;
    errorskyc[name] = "";
    this.setState({ fieldskyc, errorskyc });
  }

  handleCountry = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldskyc = this.state.fieldskyc;
    let errorskyc = this.state.errorskyc;
    fieldskyc[name] = value;
    errorskyc[name] = "";
    this.setState({ fieldskyc, errorskyc });
    let data = {
      "id": value
    }
    this.props.dispatch(userActions.getStatesList(data));

  }
  handleStates = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldskyc = this.state.fieldskyc;
    let errorskyc = this.state.errorskyc;
    fieldskyc[name] = value;
    errorskyc[name] = "";
    this.setState({ fieldskyc, errorskyc });
    let data = {
      "id": value
    }
    this.props.dispatch(userActions.getCitiesList(data));
  }

  handleGender = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldskyc = this.state.fieldskyc;
    let errorskyc = this.state.errorskyc;
    fieldskyc["gender"] = value;
    errorskyc["gender"] = "";
    this.setState({ fieldskyc, errorskyc });
  }

  handleCity = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldskyc = this.state.fieldskyc;
    let errorskyc = this.state.errorskyc;
    fieldskyc["city"] = value;
    errorskyc["city"] = "";
    console.log(name, value);
    this.setState({ fieldskyc, errorskyc });
  }

  inputChangeDate = (event) => {
    console.log("eventeventevent:", event);

    let { value, name } = event.target;

    let fieldskyc = this.state.fieldskyc;
    let errorskyc = this.state.errorskyc;
    fieldskyc[name] = value;
    errorskyc[name] = "";

    this.setState({ fieldskyc, errorskyc });
  }

  inputChangePan = (e) => {
    console.log('e____________________________', e.target.value);
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsDocumentUpload = this.state.fieldsDocumentUpload;
    let errorsDocumentUpload = this.state.errorsDocumentUpload;
    fieldsDocumentUpload[name] = value;
    errorsDocumentUpload[name] = "";
    console.log(name, value);
    this.setState({ fieldsDocumentUpload, errorsDocumentUpload });
  }

  inputChangePassPort = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsDocumentUpload = this.state.fieldsDocumentUpload;
    let errorsDocumentUpload = this.state.errorsDocumentUpload;
    fieldsDocumentUpload[name] = value;
    errorsDocumentUpload[name] = "";
    console.log(name, value);
    this.setState({ fieldsDocumentUpload, errorsDocumentUpload });
  }

  inputChangeAdhaar = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsDocumentUpload = this.state.fieldsDocumentUpload;
    let errorsDocumentUpload = this.state.errorsDocumentUpload;
    fieldsDocumentUpload[name] = value;
    errorsDocumentUpload[name] = "";
    console.log(name, value);
    this.setState({ fieldsDocumentUpload, errorsDocumentUpload });
  }

  saveUserInfoSubmit(e) {

    e.preventDefault();
    if (this.handleValidationKYC()) {

      let reqData = {
        "firstName": this.state.fieldskyc.firstName,
        "lastName": this.state.fieldskyc.lastName,
        "email": this.state.fieldskyc.email,
        "mobNo": this.state.fieldskyc.mobNo,
        "dob": this.state.fieldskyc.dob,
        "telegramUserName": this.state.fieldskyc.telegramUserName,
        "address1": this.state.fieldskyc.address1,
        "address2": this.state.fieldskyc.address2,
        "city": this.state.fieldskyc.city,
        "state": this.state.fieldskyc.state,
        "nationality": this.state.fieldskyc.nationality,
        "zipCode": this.state.fieldskyc.zipCode
      }
      console.log("saveUserInfoSubmit????????????????", reqData);
      this.props.dispatch(userActions.saveUserInfo(reqData, this.props));
      this.setState({ kycStage: 1 });
    }
  }

  saveKycDocPanSubmit(slug) {

    if (this.handleValidationDocumentUpload()) {
      let { users } = this.props;
      let { panFilesDetails } = users;
      let reqData = {
        "docNumber": this.state.fieldsDocumentUpload['pan'],
        "imageFront": panFilesDetails && panFilesDetails.imageName ? panFilesDetails.imageName : "",
      }
      // console.log('reqDatareqDatareqDatareqDatareqDatareqData', reqData);
      this.props.dispatch(userActions.saveKycDocPan(reqData, this.props));
    }
  }

  saveKycDocAadharSubmit(slug) {

    if (this.handleValidationDocumentUpload()) {
      let { users } = this.props;
      let { adhaarFilesDetails, adhaarFilesDetailsBack } = users;
      let reqData = {
        "docNumber": this.state.fieldsDocumentUpload.Adhaar,
        "imageFront": adhaarFilesDetails && adhaarFilesDetails.imageName ? adhaarFilesDetails.imageName : "",
        "imageBack": adhaarFilesDetailsBack && adhaarFilesDetailsBack.imageName ? adhaarFilesDetailsBack.imageName : ""
      }
      this.props.dispatch(userActions.saveKycDocAadhar(reqData, this.props));
    }
  }

  saveKycDocPassportSubmit(slug) {
    let { users } = this.props;
    let { passportFilesDetails } = users;
    let reqData = {
      "docNumber": this.state.fieldsDocumentUpload.PassPort,
      "imageFront": passportFilesDetails && passportFilesDetails.imageName ? passportFilesDetails.imageName : "",
    }
    this.props.dispatch(userActions.saveKycDocPassport(reqData, this.props));
    // }
  }

  saveKycDocSelfieSubmit(slug) {
    let { users } = this.props;
    let { filesDetailsSelfie } = users;
    let reqData = {
      "imageFront": filesDetailsSelfie && filesDetailsSelfie.imageName ? filesDetailsSelfie.imageName : "",
    }
    this.props.dispatch(userActions.saveKycDocSelfie(reqData, this.props));
    // }
  }

  kycSubmit() {
    this.state.isKycChecked ?
      this.saveKycDocSelfieSubmit()
      :
      this.props.dispatch(alertActions.error("First you confirm to check the checkbox !"))
  }


  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldskyc: {},
      errorskyc: {},
    })
    this.hideErrorMessage();
  }

  handleValidationDocumentUpload = () => {
    let fieldsDocumentUpload = this.state.fieldsDocumentUpload;
    let errorsDocumentUpload = {};
    let formIsValid = true;

    // //AadhaarNumber
    // if (!fieldsDocumentUpload["Adhaar"] || !fieldsDocumentUpload["Adhaar"].match("^[0-9]{12}$")) {
    //   formIsValid = false;
    //   errorsDocumentUpload["Adhaar"] = "Please provide valid Adhaar number!";
    // }

    //PanNumber
    if (!fieldsDocumentUpload["pan"] || !fieldsDocumentUpload["pan"]
      // .match("[A-Z]{5}[0-9]{4}[A-Z]{1}")
    ) {
      formIsValid = false;
      errorsDocumentUpload["pan"] = "Please provide valid Pan number!";
    }
    console.log('errorsDocumentUploaderrorsDocumentUploaderrorsDocumentUploaderrorsDocumentUpload', errorsDocumentUpload);
    this.setState({ errorsDocumentUpload: errorsDocumentUpload });
    return formIsValid;
  }

  handleValidationKYC = () => {
    let fieldskyc = this.state.fieldskyc;
    let errorskyc = {};
    let formIsValid = true;

    //firstName
    if (!fieldskyc["firstName"] || !fieldskyc["firstName"].match("^[A-Za-z]+(((\'|\-|\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorskyc["firstName"] = "Please Enter Valid First Name!";
    }

    //lastName
    if (!fieldskyc["lastName"] || !fieldskyc["lastName"].match("^[A-Za-z]+(((\'|\-|\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorskyc["lastName"] = "Please Enter Valid Last Name";
    }

    //email
    if (!fieldskyc["email"]) {
      formIsValid = false;
      errorskyc["email"] = "Please enter a valid email address.";
    }

    //dob
    if (!fieldskyc["dob"]) {
      formIsValid = false;
      errorskyc["dob"] = "Please Enter D.O.B!";
    }

    //telegramUserName
    if (!fieldskyc["telegramUserName"]) {
      formIsValid = false;
      errorskyc["telegramUserName"] = "Please Select telegramUserName!";
    }

    //address1
    if (!fieldskyc["address1"]) {
      formIsValid = false;
      errorskyc["address1"] = "Please Enter Address1!";
    }

    //city
    if (!fieldskyc["city"] || !fieldskyc["city"].match("^[A-Za-z]+(((\'|\-|\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorskyc["city"] = "Please Select City!";
    }

    //state
    if (!fieldskyc["state"] || !fieldskyc["state"].match("^[A-Za-z]+(((\'|\-|\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorskyc["state"] = "Please Select State!";
    }

    //nationality
    if (!fieldskyc["nationality"] || !fieldskyc["nationality"].match("^[A-Za-z]+(((\'|\-|\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorskyc["nationality"] = "Please Select nationality!";
    }

    //zipCode
    if (!fieldskyc["zipCode"] || !fieldskyc["zipCode"] === "") {
      formIsValid = false;
      errorskyc["zipCode"] = "Please Enter ZipCode!";
    }

    this.setState({ errorskyc: errorskyc });
    return formIsValid;
  }


  todayDate = () => {
    let now = new Date();
    console.log("now__________________", now)
    return now;
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  handleFile = (event) => {
    if (event.target.files[0]) {
      let files = event.target.files[event.target.files.length - 1];
      let fileName = files && files.type ? files.type : "";
      if ((fileName === "image/png") || (fileName === "image/jpeg") || (fileName === "image/jpg")) {
        this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      }
    }

  }

  handleFilePan = (event) => {
    console.log('eventeventeventeventevent33333333333333333', event.target.files);
    // console.log('eventeventeventeventevent333333333333333331111', event.target.files[event.target.files.length - 1]);
    let files = event.target.files[0];
    console.log('files_______________________', files);
    if (event.target.files) {
      // let files = event.target.files;
      // console.log('files________________________', files);
      let fileName = files && files.type ? files.type : "";
      // console.log('fileName_____________________', fileName);
      if ((fileName === "image/png") || (fileName === "image/jpeg") || (fileName === "image/jpg")) {
        this.props.dispatch(userActions.uploadImagePan(event.target.files[event.target.files.length - 1]));
      }
    }
  }

  handleFileAdhaar = (event) => {
    if (event.target.files[0]) {
      let files = event.target.files[event.target.files.length - 1];
      let fileName = files && files.type ? files.type : "";
      if ((fileName === "image/png") || (fileName === "image/jpeg") || (fileName === "image/jpg")) {
        this.props.dispatch(userActions.uploadImageAdhaar(event.target.files[event.target.files.length - 1]));
      }
    }
  }

  handleFileAdhaarBack = (event) => {
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImageAdhaarBack(event.target.files[event.target.files.length - 1]));
    }
  }

  handleFilePassport = (event) => {
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImagePassport(event.target.files[event.target.files.length - 1]));
    }
  }

  handleFileSelfie = (event) => {
    console.log("working:");

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImageSelfie(event.target.files[event.target.files.length - 1]));
    }
  }

  handleFileSelfieUpload = (file) => {

    const base64 = file.replace('data:image/jpeg;base64,', '')
    const buffer = Buffer.from(base64, "base64");
    const blob = new Blob([buffer], { type: '[content-type]' })
    console.log('--------------------------UPLOAD IMAGE PIC----------------------------', blob)
    if (blob) {
      this.props.dispatch(userActions.uploadImageSelfie(blob));
      this.setState({ showWebCamModal: false });
    }
  }

  handleRemovePan = () => {
    this.props.dispatch(userActions.uploadImageClearPan());
  }

  handleRemoveAdhaar = () => {
    this.props.dispatch(userActions.uploadImageClearAdhaar());
  }

  handleRemoveAdhaarBack = () => {
    this.props.dispatch(userActions.uploadImageClearAdhaarBack());
  }

  handleRemoveSelfie = () => {
    this.props.dispatch(userActions.uploadImageClearSelfie());
  }

  handleCloseWebcamModal = () => {
    this.setState({ showWebCamModal: false })
  }

  // saveDocumentInfoSubmit = () => {
  //   if (this.handleValidationDocumentUpload()) {
  //     this.setState({ kycStage: 2 })
  //   }
  // }

  saveDocumentInfoSubmit = () => {
    if (this.state.isStateHandle === 1) {
      this.saveKycDocPanSubmit()
    } else if (this.state.isStateHandle === 2) {
      this.saveKycDocAadharSubmit()
    } else if (this.state.isStateHandle === 3) {
      this.saveKycDocPassportSubmit()
    }
  }

  handleIsKycChecked = () => {
    this.setState({ isKycChecked: !this.state.isKycChecked });
  }

  handleState = (data) => {
    console.log('data_________________________________', data);
    this.setState({ isStateHandle: data });
  }

  kycFinalSubmit = () => {
    this.props.dispatch(userActions.createKYC())
  }

  handleFileSelect = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  exportPDF = () => {
    let { users } = this.props;

    let { txList } = users;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Send and Received Report";
    const headers = [["DATE", "TRANSACTION TYPE", "AMOUNT", "COIN", "TRANSACTION STATUS"]];
    const data = txList.map(elt => [moment(new Date(parseInt(elt.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm"), elt.txType, elt.amount, elt.coin, elt.status ? "SUCESS" : "PENDING"]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(Date.now() + "_send_received_report.pdf")
  }

  render() {

    let { users } = this.props;
    let {
      //  docList, filesDetails, 
      loading, kycData,
      panFilesDetails, adhaarFilesDetails, filesDetailsSelfie, adhaarFilesDetailsBack, passportFilesDetails } = users;
    // console.log('adhaarFilesDetailsadhaarFilesDetails', adhaarFilesDetails);
    // console.log('adhaarFilesDetailsBackadhaarFilesDetailsBack', adhaarFilesDetailsBack);
    // console.log('allUploadedSuccessallUploadedSuccess', this.state.allUploadedSuccess);
    // console.log('saveKycDocPanDatasaveKycDocPanDatasaveKycDocPanData', saveKycDocPanData);

    console.log('panFilesDetails____________________', panFilesDetails);

    let { kycStage, allUploadedSuccess } = this.state;
    // const videoConstraints = {
    //   width: 1280,
    //   height: 720,
    //   facingMode: "user"
    // };

    const steps = [
      'Personal Details',
      'Document Upload',
      'Upload Photo',
    ];

    console.log('this.state.fieldsDocumentUpload', this.state.fieldsDocumentUpload['pan']);

    return (
      <>
        {/* <Header name="Kyc" /> */}
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <main className="relative z-0 flex-1 mb-16 overflow-y-auto bg_page focus:outline-none lg:mb-0" >
          <div className="2xl:p-10 lg:p-5">
            <div className="mx-auto max-w-screen-3xl">
              <section>
                <div className='lg:flex lg:justify-center'>
                  <div className="lg:max-w-[700px] text-center p-2">
                    {/* <h2 className="py-3 text-2xl font-bold text-[#0E1F51] lg:text-center lg:py-3 lg:text-2xl">Begin your ID-Verification</h2>
                    <p className="text-sm text-[#393658] lg:text-center lg:text-xl">To comply with regulations and safety of our M7 community, each user must go through identity verification (KYC/AML) to prevent any fraudulent activity.</p> */}
                    <h2 className="py-3 text-2xl font-bold text-[#0E1F51] lg:text-center lg:py-3 lg:text-2xl">Thankyou for submitting KYC form.</h2>
                    <div className="pt-3">
                      <center>
                        {kycData === null ?
                          <div className="">
                            <center><span className="py-2 ml-8 text-lg text-[#393658] lg:text-center lg:text-xl lg:py-0 lg:ml-0"> Please upload document and fill form.</span></center>
                          </div>
                          : kycData && kycData.status === -1 ? (
                            <div className="">
                              <center> <span className="text-sm text-[#393658] lg:text-center lg:text-xl">Please upload document and fill form.</span></center>
                            </div>
                          ) : kycData && kycData.status === 0 ? (
                            <div className="">
                              <span className="text-sm text-[#393658] lg:text-center lg:text-xl">"Your KYC details have been received. Please wait for admin approval.<br /> A confirmation will be sent to you once the information is verified"</span>
                              <p className="pt-6 text-sm text-gray-600 lg:text-center lg:text-xl">"Click below to download your submitted form"</p>
                              <div data-tooltip-id="my-tooltip-2" className="flex items-center w-20 px-3 py-2 mx-auto mt-4 text-base font-bold text-center text-white cursor-pointer rounded-xl all-btn-color"><PiDownloadSimpleBold className="w-8 h-10 pr-2" />PDF</div>
                              <ReactTooltip
                                id="my-tooltip-2"
                                place="bottom"
                                content="PDF download KYC form"
                              />
                            </div>
                          ) : kycData && kycData.status === 1 ? (
                            <div className="">
                              <center><span className="text-sm text-green-600 lg:text-center lg:text-xl">Thankyou. Your information has been verified.</span></center>
                            </div>
                          ) : (
                            <div className="">
                              <span className="text-sm text-red-600 lg:text-center lg:text-xl">You KYC is reject by admin please upload document.</span>
                            </div>)
                        }
                      </center>
                    </div>
                  </div>
                </div>
              </section>

              {
                kycData && kycData.status !== 1 ? <>


                  {
                    kycData && kycData.status === 0 ?
                      <>
                      </>
                      :
                      kycData && kycData.status === -1 ?
                        <Stepper activeStep={kycStage} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label} >
                              <StepLabel>
                                <div className='text-[#0E1F51]'>{label}</div></StepLabel></Step>
                          ))}
                        </Stepper>
                        :

                        <Stepper activeStep={kycStage} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label} >
                              <StepLabel>
                                <div className='text-[#0E1F51]'>{label}</div></StepLabel></Step>
                          ))}
                        </Stepper>


                  }


                  {kycStage === 0 ?
                    <>
                      {
                        kycData && kycData.status === 0 ?
                          <>
                          </>
                          :
                          kycData && (kycData.status === -1 || kycData.status === 2) ?
                            <>

                              < section className="grid grid-cols-12 gap-5 ">
                                <div className="flex-col col-span-12 py-5 rounded-md xl:col-span-12 lg:col-span-12 2xl:py-7">
                                  <div className="flex items-center bg-white rounded-md lg:w-full lg:flex lg:p-3">
                                    <div className="items-center w-10 h-10 ml-1 rounded-full lg:w-16 lg:h-16 all-btn-color lg:ml-0">
                                      <h1 className="lg:text-center lg:pt-4 pt-2 lg:pl-0 pl-[10px] text-white font-bold lg:text-xl">01</h1>
                                    </div>
                                    <div className="ml-2 lg:ml-5">
                                      <h3 className="text-lg text-[#0E1F51] lg:text-xl text-bold lg:font-semibold">Personal Details</h3>
                                      <p className="text-[#393658] lg:text-xs">Please type carefully and fill out the form with your personal details.</p>
                                      <p className="text-[#393658] lg:text-xs">Once submitted, the form cannot be edited.</p>
                                    </div>
                                  </div>
                                  <div className="p-4 mt-2 bg-white rounded-md">
                                    <form autoComplete="off" className="mt-2 space-y-4 lg:space-y-8">

                                      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">

                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">First Name<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white' name="firstName"
                                            value={this.state.fieldskyc && this.state.fieldskyc["firstName"] ? this.state.fieldskyc["firstName"] : ''}
                                          />
                                          {this.state.errorskyc["firstName"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["firstName"]}
                                            </div>
                                            : null}
                                        </div>

                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Last Name<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" name="lastName" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white'
                                            value={this.state.fieldskyc && this.state.fieldskyc["lastName"] ? this.state.fieldskyc["lastName"] : ''}
                                          />
                                          {this.state.errorskyc["lastName"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["lastName"]}
                                            </div>
                                            : null}
                                        </div>

                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Email Address<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white' name="email"
                                            value={this.state.fieldskyc && this.state.fieldskyc["email"] ? this.state.fieldskyc["email"] : ''}
                                          />
                                          {this.state.errorskyc["email"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["email"]}
                                            </div>
                                            : null}
                                        </div>

                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Phone Number<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" name="mobNo" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white'
                                            value={this.state.fieldskyc && this.state.fieldskyc["mobNo"] ? this.state.fieldskyc["mobNo"] : ''}
                                          />
                                          {this.state.errorskyc["mobNo"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["mobNo"]}
                                            </div>
                                            : null}
                                        </div>

                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Date of Birth<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChangeDate}
                                            value={this.state.fieldskyc && this.state.fieldskyc["dob"] ? this.state.fieldskyc["dob"] : ''}
                                            type="date" max={moment().format('YYYY-MM-DD')} className="cursor-pointer picker bg-[#E6E6E6] w-full py-[9px] px-[10px] rounded-md text-gray-700  outline-none border-none" name="dob" />
                                          {this.state.errorskyc["dob"] ?
                                            <div className="text-xs text-red-500 cursor-pointer invalid-feedback">
                                              {this.state.errorskyc["dob"]}
                                            </div>
                                            : null}
                                        </div>
                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Telegram Username<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white' name="telegramUserName"
                                            value={this.state.fieldskyc && this.state.fieldskyc["telegramUserName"] ? this.state.fieldskyc["telegramUserName"] : ''}
                                          />
                                          {this.state.errorskyc["telegramUserName"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["telegramUserName"]}
                                            </div>
                                            : null}
                                        </div>

                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Address Line 1<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white' name="address1"
                                            value={this.state.fieldskyc && this.state.fieldskyc["address1"] ? this.state.fieldskyc["address1"] : ''}
                                          />
                                          {this.state.errorskyc["address1"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["address1"]}
                                            </div>
                                            : null}
                                        </div>
                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Address Line 2</label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white' name="address2"
                                            value={this.state.fieldskyc && this.state.fieldskyc["address2"] ? this.state.fieldskyc["address2"] : ''}
                                          />
                                          {this.state.errorskyc["address2"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["address2"]}
                                            </div>
                                            : null}
                                        </div>


                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">City<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white'
                                            name="city"
                                            value={this.state.fieldskyc && this.state.fieldskyc["city"] ? this.state.fieldskyc["city"] : ''}
                                          />
                                          {this.state.errorskyc["city"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["city"]}
                                            </div>
                                            : null}
                                        </div>

                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">State/Province<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white' name="state"
                                            value={this.state.fieldskyc && this.state.fieldskyc["state"] ? this.state.fieldskyc["state"] : ''}
                                          />
                                          {this.state.errorskyc["state"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["state"]}
                                            </div>
                                            : null}
                                        </div>

                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Nationality<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white' name="nationality"
                                            // value={this.state.fieldskyc["nationality"] ? this.state.fieldskyc["nationality"] : ''} placeholder="nationality" />
                                            value={this.state.fieldskyc && this.state.fieldskyc["nationality"] ? this.state.fieldskyc["nationality"] : ''}
                                          />
                                          {this.state.errorskyc["nationality"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["nationality"]}
                                            </div>
                                            : null}
                                        </div>
                                        <div className="relative rounded-md">
                                          <label className="block w-full pl-2 text-sm text-[#A09DC5]">Zip Code/Postal Code<span className='text-red-500'>*</span></label>
                                          <input onChange={this.inputChange} type="text" className='block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white'
                                            name="zipCode"
                                            value={this.state.fieldskyc && this.state.fieldskyc["zipCode"] ? this.state.fieldskyc["zipCode"] : ''}
                                          />
                                          {this.state.errorskyc["zipCode"] ?
                                            <div className="text-xs text-red-500 invalid-feedback">
                                              {this.state.errorskyc["zipCode"]}
                                            </div>
                                            : null}
                                        </div>
                                      </div>

                                      <div className="flex items-end justify-end w-full pb-2 mt-3">
                                        <div className='flex items-end justify-end'>
                                          <button className="flex justify-center w-full px-3 py-2.5 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-lg md:w-64 font-meduim all-btn-color focus:outline-none"
                                            type="button" onClick={this.saveUserInfoSubmit} data-config-id="01_primary-action">Continue</button>
                                        </div>
                                      </div>

                                    </form>
                                  </div>
                                </div>
                              </section>
                            </>
                            :
                            <>
                            </>
                      }
                    </> : null}

                  {kycStage === 1 ?
                    <>
                      {
                        kycData && kycData.status === 0 ?
                          <>
                          </>
                          :
                          kycData && (kycData.status === -1 || kycData.status === 2) ?
                            <>
                              <section className="items-center w-full gap-5 mt-4">


                                {/* <div className="flex flex-wrap justify-between w-full p-5">
                                  <h3 className="text-lg font-semibold leading-6 text-center text-[#0E1F51] md:text-2xl md:leading-9">Ticket History</h3>
                                  <button onClick={() => this.exportPDF()} className="flex items-center w-20 px-4 py-1 text-base font-semibold text-white rounded-md cursor-pointer all-btn-color hover:bg-shine-400 focus:outline-none"><span><FiDownload className='w-5 h-5 pr-1 text-white' /></span>PDF</button>
                                </div> */}


                                <div className="flex-col w-full rounded-md ">
                                  <div className="flex items-center p-2 bg-white rounded-md lg:w-full lg:flex lg:p-3 lg:items-center">
                                    <div className="items-center w-10 h-10 ml-1 rounded-full lg:w-16 lg:h-16 all-btn-color lg:ml-0">
                                      <h1 className="lg:text-center lg:pt-4 pt-2 lg:pl-0 pl-[10px] text-white lg:text-xl font-bold">02</h1>
                                    </div>
                                    <div className="ml-3 lg:ml-5">
                                      <h3 className="text-lg font-semibold text-[#0E1F51] lg:text-xl">Document Upload <span className="text-sm text-red-500">(File size should not be greater than 10 MB)</span>
                                      </h3>
                                      <p className="text-[#393658] text-xs">To verify your identity, please upload any of your document.</p>
                                    </div>
                                  </div>

                                  <div className="w-full p-6 mt-2 bg-white rounded-md">
                                    <form autoComplete='off'>

                                      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
                                        <div className="p-5 referral-card">
                                          <div className="flex items-start justify-between cursor-pointer" onClick={() => this.handleState(1)}>
                                            <div className="flex items-center mb-2">
                                              <span className="mr-5">
                                                <img className="rounded-full" src="assets/Passport.png" alt="Passport.png " width={86} height={86} />
                                              </span>
                                              <div className="pr-1 mt-">
                                                <h2 className="justify-center text-lg text-[#0E1F51] leading uppercase">Upload Government issued photo I.D.</h2>
                                                <h2 className="justify-center text-lg text-[#0E1F51] leading uppercase">This can include
                                                </h2>
                                                <h2 className="justify-center text-lg text-[#0E1F51] leading uppercase">(Driver’s License,  Passport, Permanent Resident or Citizenship I.D)</h2>
                                              </div>
                                            </div>
                                          </div>
                                        </div>


                                        {/* <div className="p-5 referral-card">
                                          <div className="flex items-start justify-between cursor-pointer" onClick={() => this.handleState(2)}>
                                            <div className="flex items-center mb-2">
                                              <span className="mr-5">
                                                <img className="rounded-full" src="assets/GovermentID.png" width={64} height={64} alt="GovermentID.png" />
                                              </span>
                                              <div className="pr-1 mt-">
                                                <h2 className="justify-center text-lg text-[#0E1F51] leading-snug uppercase">CITIZENSHIP CARD</h2>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="p-5 referral-card">
                                          <div className="flex items-start justify-between cursor-pointer" onClick={() => this.handleState(3)}>
                                            <div className="flex items-center mb-2">
                                              <span className="mr-5">
                                                <img className="rounded-full" src="assets/driveringLinces.png" width={64} height={64} alt="User 01" />
                                              </span>
                                              <div className="pr-1 mt-">
                                                <h2 className="justify-center text-lg text-[#0E1F51] leading-snug uppercase">Passport</h2>
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                      <div className='py-4'>
                                        <h1>To avoid delays in account verification, please make sure:</h1>
                                        {/* <FiCheck className="w-5 h-5 text-green-500" /> */}
                                        <div className='flex justify-start space-x-1'>
                                          <MdCheckCircle className='pt-1 text-[#BA8A2C]' size={24} /> <span className='pt-0.5 text-gray-500'> Uploaded I.D document is not expired</span>
                                        </div>
                                        <div className='flex justify-start space-x-1'>
                                          <MdCheckCircle className='pt-1 text-[#BA8A2C]' size={24} /> <span className='pt-0.5 text-gray-500'> In good condition and clearly visible</span>
                                        </div>
                                        <div className='flex justify-start space-x-1'>
                                          <MdCheckCircle className='pt-1 text-[#BA8A2C]' size={24} /> <span className='pt-0.5 text-gray-500'> Authentic (issued by government organization)</span>
                                        </div>
                                        <div className='flex justify-start space-x-1'>
                                          <MdCheckCircle className='pt-1 text-[#BA8A2C]' size={24} /> <span className='pt-0.5 text-gray-500'> Has no light glare</span>
                                        </div>

                                      </div>
                                      <div class="grid 2xl:grid-cols-2 gap-4 mt-3">
                                        {this.state.isStateHandle === 1 ?
                                          <div class="flex flex-col">
                                            <label className="block w-full pl-2 text-sm font-bold text-[#A09DC5]">I.D No</label>
                                            <div>
                                              <input className="block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white"
                                                name="pan"
                                                value={this.state.fieldsDocumentUpload["pan"] ? this.state.fieldsDocumentUpload["pan"] : ''}
                                                onChange={this.inputChangePan} placeholder="I.D No" type="text"
                                              />
                                              {this.state.errorsDocumentUpload["pan"] ?
                                                <div className="text-xs text-red-500 invalid-feedback">
                                                  {this.state.errorsDocumentUpload["pan"]}
                                                </div>
                                                : null}
                                            </div>

                                            <div className="rounded-md shadow-sm ">
                                              <div className="space-y-3 2xl:space-y-0">
                                                <div className="w-64 lg:flex lg:w-64 lg:mr-auto" htmlFor="myInput">
                                                  <span className="p-2 text-xs font-bold text-shine font-normall">Upload Image</span>
                                                </div>

                                                <div className="mt-3">
                                                  {
                                                    panFilesDetails && panFilesDetails.imageURL ?
                                                      (<img src={panFilesDetails.imageURL} className="lg:ml-2 2xl:ml-3 lg:w-60 h-36" alt={"NOT_FOUND"} />)
                                                      : (<label className="">
                                                        <AdhaarSVG className="" />
                                                        <input onChange={this.handleFilePan} type='file' className="hidden" />
                                                      </label>)}

                                                  {/* pan card button section */}

                                                  <div className="pt-2 ">
                                                    {
                                                      panFilesDetails && panFilesDetails.imageURL ?
                                                        <>
                                                          <div className="flex justify-between px-3 py-2 my-2 bg-gray-100 rounded-md 2xl:ml-3 lg:ml-2 2xl:w-40 lg:w-40 md:w-40 sm:w-full">

                                                            <label className="text-sm font-bold">
                                                              <h3 className="text-green-600 px-2 py-0.5 rounded-md font-bold">Change</h3>
                                                              <input onChange={this.handleFilePan} type='file' className="hidden" />
                                                            </label>
                                                            <button className="text-sm font bold" onClick={this.handleRemovePan}>
                                                              <h3 className="text-red-500 px-2 py-0.5 rounded-md font-bold">Remove</h3>
                                                            </button>
                                                          </div>
                                                        </> : null
                                                    }
                                                  </div>
                                                </div>
                                              </div>

                                            </div>

                                          </div>
                                          : this.state.isStateHandle === 2 ?
                                            <div class="flex flex-col">
                                              <div className='flex flex-col'>
                                                <label className="block w-full pl-2 text-sm font-bold text-[#A09DC5] ">Adhaar No.</label>
                                                <input className="block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white"
                                                  name="Adhaar"
                                                  value={this.state.fieldsDocumentUpload["Adhaar"] ? this.state.fieldsDocumentUpload["Adhaar"] : ''}
                                                  onChange={this.inputChangeAdhaar} placeholder="Adhaar No." type="text"
                                                />
                                                {this.state.errorsDocumentUpload["Adhaar"] ?
                                                  <div className="text-xs text-red-500 invalid-feedback">
                                                    {this.state.errorsDocumentUpload["Adhaar"]}
                                                  </div>
                                                  : null}
                                              </div>
                                              <div className='grid gap-4 2xl:grid-cols-2'>


                                                {/* Upload Front Of Aadhaar Card */}
                                                <div>
                                                  <div className="rounded-md shadow-sm">
                                                    <div className="space-y-3 2xl:space-y-0">
                                                      <div className="flex items-center" htmlFor="myInput">
                                                        <span className="py-2 text-xs font-bold text-shine font-normall">Upload Front Of Aadhaar Card</span>
                                                      </div>
                                                      <div className="">
                                                        {
                                                          adhaarFilesDetails && adhaarFilesDetails.imageURL ?
                                                            (<img src={adhaarFilesDetails.imageURL} className="lg:ml-2 2xl:ml-3 lg:w-60 h-36" alt={"NOT_FOUND"} />)
                                                            : (<label className="">
                                                              {/* <img src={adhaarSVG} className='iconChat' alt="Icon chat" /> */}

                                                              <AdhaarSVG className="" />
                                                              <input onChange={this.handleFileAdhaar} type='file' className="hidden" />
                                                            </label>
                                                            )}
                                                      </div>

                                                      {/* button section */}

                                                      <div className="2xl:py-2.5 lg:py-1 2xl:mt-2">
                                                        {
                                                          adhaarFilesDetails && adhaarFilesDetails.imageURL ?
                                                            <>
                                                              <div className="flex justify-between 2xl:ml-3 bg-gray-100 px-3 py-2.5 2xl:w-40 lg:w-40 md:w-40 sm:w-full rounded-md">
                                                                <label className="text-sm font-bold text-green-600">Change
                                                                  <input onChange={this.handleFileAdhaar} type='file' className="hidden" />
                                                                </label>
                                                                <button className="text-sm text-red-500 font bold " onClick={this.handleRemoveAdhaar}>Remove</button>
                                                              </div>
                                                            </> : null
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                {/* Upload Back Of Aadhaar Card */}
                                                <div>
                                                  <div className="rounded-md shadow-sm ">
                                                    <div className="space-y-3 2xl:space-y-0">
                                                      <div className="flex items-center" htmlFor="myInput">
                                                        <span className="py-2 text-xs font-bold text-shine font-normall">Upload Back Of Aadhaar Card</span>
                                                      </div>
                                                      <div className="">
                                                        {
                                                          adhaarFilesDetailsBack && adhaarFilesDetailsBack.imageURL ?
                                                            (<img src={adhaarFilesDetailsBack.imageURL} className="lg:ml-2 2xl:ml-3 w-60 h-36" alt={"NOT_FOUND"} />)
                                                            : (<label className="">
                                                              <AdhaarSVG className="" />
                                                              <input onChange={this.handleFileAdhaarBack} type='file' className="hidden" />
                                                            </label>
                                                            )}
                                                      </div>

                                                      {/* button section */}

                                                      <div className="2xl:py-2.5 lg:py-1 2xl:mt-2">
                                                        {
                                                          adhaarFilesDetailsBack && adhaarFilesDetailsBack.imageURL ?
                                                            <>
                                                              <div className="flex 2xl:ml-3 justify-between bg-slate-600 px-3 py-2.5 2xl:w-40 lg:w-40 md:w-40 sm:w-full rounded-md">
                                                                <label className="text-sm font-bold text-green-600">Change
                                                                  <input onChange={this.handleFileAdhaarBack} type='file' className="hidden" />
                                                                </label>
                                                                <button className="text-sm text-red-500 font bold " onClick={this.handleRemoveAdhaarBack}>Remove</button>
                                                              </div>
                                                            </> : null
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            :
                                            this.state.isStateHandle === 3 ?
                                              <div class="flex flex-col">
                                                <label className="block w-full pl-2 text-sm font-bold text-[#A09DC5]">Passport</label>
                                                <div>
                                                  <input className="block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white"
                                                    name="PassPort"
                                                    value={this.state.fieldsDocumentUpload["PassPort"] ? this.state.fieldsDocumentUpload["PassPort"] : ''}
                                                    onChange={this.inputChangePan} placeholder="Passport No." type="text"
                                                  />
                                                  {this.state.errorsDocumentUpload["PassPort"] ?
                                                    <div className="text-xs text-red-500 invalid-feedback">
                                                      {this.state.errorsDocumentUpload["PassPort"]}
                                                    </div>
                                                    : null}
                                                </div>

                                                <div className="rounded-md shadow-sm ">
                                                  <div className="space-y-3 2xl:space-y-0">
                                                    <div className="w-64 lg:flex lg:w-64 lg:mr-auto" htmlFor="myInput">
                                                      <span className="p-2 text-xs font-bold text-shine font-normall">Passport no.</span>
                                                    </div>

                                                    <div className="mt-3">
                                                      {
                                                        passportFilesDetails && passportFilesDetails.imageURL ?
                                                          (<img src={passportFilesDetails.imageURL} className="lg:ml-2 2xl:ml-3 lg:w-60 h-36" alt={"NOT_FOUND"} />)
                                                          : (<label className="">
                                                            <AdhaarSVG className="" />
                                                            <input onChange={this.handleFilePan} type='file' className="hidden" />
                                                          </label>)}

                                                      {/* pan card button section */}

                                                      <div className="pt-2 ">
                                                        {
                                                          passportFilesDetails && passportFilesDetails.imageURL ?
                                                            <>
                                                              <div className="flex justify-between px-3 py-2 my-2 bg-gray-100 rounded-md 2xl:ml-3 lg:ml-2 2xl:w-40 lg:w-40 md:w-40 sm:w-full">

                                                                <label className="text-sm font-bold">
                                                                  <h3 className="text-green-600 px-2 py-0.5 rounded-md font-bold">Change</h3>
                                                                  <input onChange={this.handleFilePan} type='file' className="hidden" />
                                                                </label>
                                                                <button className="text-sm font bold" onClick={this.handleRemovePan}>
                                                                  <h3 className="text-red-500 px-2 py-0.5 rounded-md font-bold">Remove</h3>
                                                                </button>
                                                              </div>
                                                            </> : null
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>

                                                </div>

                                              </div>
                                              : null
                                        }


                                      </div>

                                      {/* <div className="mx-auto mt-4 sm:w-64">
                                        <button className="flex justify-center w-full px-3 py-2 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-full font-meduim all-btn-color focus:outline-none"
                                          type="button" onClick={this.saveDocumentInfoSubmit} data-config-id="01_primary-action">Continue</button>
                                      </div> */}

                                      <div className="flex items-end justify-end w-full pb-2 mt-3">
                                        <div className='flex items-end justify-end'>
                                          <button className="flex justify-center w-full px-3 py-2.5 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-lg md:w-64 font-meduim all-btn-color focus:outline-none"
                                            type="button" onClick={this.saveDocumentInfoSubmit} data-config-id="01_primary-action">Continue</button>
                                        </div>
                                      </div>
                                    </form>

                                  </div>
                                </div>
                              </section>
                            </>
                            :
                            <>
                            </>
                      }
                    </> : null
                  }

                  {this.state.kycStage === 2 ?
                    <>
                      {/* Upload Photo section */}
                      {
                        kycData && kycData.status === 0 ?
                          <>

                          </>
                          :
                          kycData && (kycData.status === -1 || kycData.status === 2) ?
                            <>
                              <section className="grid grid-cols-12 gap-5 ">
                                <div className="flex-col col-span-12 py-5 rounded-md xl:col-span-12 lg:col-span-12 2xl:py-7">
                                  <div className="w-full bg-white flex lg:p-3 p-1.5 lg:items-center items-center rounded-md">
                                    <div className="items-center w-12 h-12 py-2 rounded-full lg:w-16 lg:h-16 all-btn-color lg:py-0">
                                      <h1 className="pt-1 font-bold text-center text-white lg:pt-4 lg:text-xl">03</h1>
                                    </div>
                                    <div className="py-2 ml-2 lg:ml-5 lg:py-0 w">
                                      <h2 className="font-semibold text-[#0E1F51] lg:text-xl">Upload Selfie</h2>
                                      <p className="text-[#393658] text-xs">To verify your identity, please upload selfie.</p>
                                    </div>
                                  </div>
                                  <div className="p-6 mt-2 bg-white rounded-md">
                                    <div className="p-2">
                                      <h3 className="pb-3 text-sm text-[#0E1F51] border-b border-gray-600">In order to complete KYC, please upload your most recent Selfie</h3>
                                    </div>
                                    <from autoComplete="off" className="mt-2 space-y-8 p">
                                      <div className="lg:flex flex-cols lg:p-4">
                                        <div className="">
                                          <div className="mt-8 rounded-md shadow-sm lg:mt-0">
                                            <div className="">
                                              <div className="w-64 lg:flex lg:w-64 lg:mr-auto" htmlFor="myInput">
                                                <span className="ml-8 text-base font-medium text-[#393658] lg:pl-2 font-normall lg:pt-2">Upload Selfie</span>
                                              </div>
                                              <div className="pb-2"
                                              // onClick={() => this.handleFileSelfie()}
                                              >
                                                {
                                                  filesDetailsSelfie && filesDetailsSelfie.imageURL ?
                                                    (<img src={filesDetailsSelfie.imageURL} className="rounded-sm w-44 h-44 " alt={"NOT_FOUND"} />)
                                                    : (<label className="">
                                                      <SelfieSVG className="" />
                                                      <input onChange={this.handleFileSelfie} type='file' className="hidden" />
                                                      <div   ></div>

                                                    </label>)}
                                              </div>
                                              <div className="pt-2">
                                                {
                                                  filesDetailsSelfie && filesDetailsSelfie.imageURL ?
                                                    <>
                                                      <div className="flex justify-between lg:w-44 w-[240px] bg-gray-100 px-2 py-2 rounded-md">
                                                        <label
                                                          // onClick={() => this.handleFileSelfie()}
                                                          className="text-xs font-bold">
                                                          <h3 className="text-green-600 cursor-pointer px-2 py-0.5 rounded-md font-bold">Change</h3>
                                                          <input onChange={this.handleFileSelfie} type='file' className="hidden" />
                                                          <div ></div>
                                                        </label>
                                                        <button className="text-sm font bold" onClick={this.handleRemoveSelfie}>
                                                          <h3 className="text-red-500 px-2 py-0.5 rounded-md font-bold">Remove</h3>
                                                        </button>
                                                      </div>
                                                    </> : null
                                                }
                                              </div>

                                              <div className="pt-5">
                                                <div className="flex items-center ml-5 lg:ml-0">
                                                  <input type="checkbox" className="w-4 h-4 accent-[#BA8A2C]" onChange={this.handleIsKycChecked} />
                                                  <div className="mt-6 ml-3 text-[#393658] lg:ml-2 lg:mt-0">
                                                    <p className="text-[#393658] lg:text-sm text-[10px]">Selfie must be taken in good light without sunglasses or cap/hat. Spectacles for short sightedness are allowed.</p>
                                                    {/* <p className="text-[#393658]">personal document.</p> */}
                                                  </div>
                                                </div>
                                                <div className="mt-8 ml-2">
                                                  {allUploadedSuccess === true ?
                                                    <button className="px-10 py-2 text-xs font-semibold tracking-wider text-white uppercase transition duration-150 ease-in-out rounded-full w-60 all-btn-color lg:py-2 lg:text-base font-meduim focus:outline-none"
                                                      type="button" onClick={() => this.kycFinalSubmit()}>Submit KYC Form</button>
                                                    :
                                                    <button className="w-56 px-10 py-2 text-xs font-semibold tracking-wider text-white uppercase transition duration-150 ease-in-out rounded-full all-btn-color lg:py-2 lg:text-base font-meduim focus:outline-none"
                                                      type="button" onClick={() => this.kycSubmit()}>Submit</button>
                                                  }
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="items-center w-64 pb-4 mb-3 lg:w-3/4 lg:mb-5">
                                     
                                        </div> */}
                                      </div>
                                    </from>
                                  </div>

                                </div>
                              </section>
                            </>
                            :
                            <>

                            </>
                      }
                    </>
                    :
                    null
                  }
                </> : null}

            </div>
          </div>
        </main >
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}
export default connect(mapStateToProps)(KYC);

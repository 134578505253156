import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { BsGlobe } from "react-icons/bs";

class Questions extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.registerSubmit = this.registerSubmit.bind(this);
    this.otpSubmit = this.otpSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentRegister: false,
      shown: false,
      question1: "",
      question2: ""
    }
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  registerSubmit(e) {
    // alert("Hello! I am an alert box!!");
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let resData1 = {
        questionId: this.state.fieldslogin.questionId1,
        answer: this.state.fieldslogin.answer1
      }
      this.props.dispatch(userActions.createAnswers(resData1, this.props));
      let resData2 = {
        questionId: this.state.fieldslogin.questionId2,
        answer: this.state.fieldslogin.answer2
      }
      this.props.dispatch(userActions.createAnswers(resData2, this.props));
      let resData3 = {
        questionId: this.state.fieldslogin.questionId3,
        answer: this.state.fieldslogin.answer3
      }
      this.props.dispatch(userActions.createAnswers3(resData3, this.props));
      this.props.dispatch(userActions.userSecurityUpdate());
    }
  }

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //questionId1
    if (!fieldslogin["questionId1"] || fieldslogin["questionId1"] === "" || !fieldslogin["questionId1"]) {
      formIsValid = false;
      errorslogin["questionId1"] = "Cannot be empty questionId 1.";
    }

    //answer1
    if (!fieldslogin["answer1"] || fieldslogin["answer1"] === "" || !fieldslogin["answer1"]) {
      formIsValid = false;
      errorslogin["answer1"] = "Cannot be empty answer 1.";
    }

    //questionId2
    if (!fieldslogin["questionId2"] || fieldslogin["questionId2"] === "" || !fieldslogin["questionId2"]) {
      formIsValid = false;
      errorslogin["questionId2"] = "Cannot be empty questionId 2.";
    }

    //answer2
    if (!fieldslogin["answer2"] || fieldslogin["answer2"] === "" || !fieldslogin["answer2"]) {
      formIsValid = false;
      errorslogin["answer2"] = "Cannot be empty answer 2.";
    }

    //questionId3
    if (!fieldslogin["questionId3"] || fieldslogin["questionId3"] === "" || !fieldslogin["questionId3"]) {
      formIsValid = false;
      errorslogin["questionId3"] = "Cannot be empty questionId 3.";
    }

    //answer3
    if (!fieldslogin["answer3"] || fieldslogin["answer3"] === "" || !fieldslogin["answer3"]) {
      formIsValid = false;
      errorslogin["answer3"] = "Cannot be empty answer 3.";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  // resendOtpSubmit = (e) => {
  //   // alert("Hello! I am an alert box!!");
  //   // e.preventDefault();
  //   if (this.handleValidationLogin()) {
  //     let data = {
  //       firstName: this.state.fieldslogin.firstName,
  //       lastName: this.state.fieldslogin.lastName,
  //       email: this.state.fieldslogin.email,
  //       // mobNo: this.state.fieldslogin.mobNo,
  //       password: this.state.fieldslogin.password,
  //       refByCode: this.props.match.params.id ? this.props.match.params.id : (this.state.fieldslogin.refByCode ? this.state.fieldslogin.refByCode : "ADMIN")
  //     }
  //     console.log("registerSubmit___registerSubmit:::", data);
  //     this.props.dispatch(userActions.register(data, this.props));
  //   }
  // }



  otpSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationOTP()) {
      let { token } = this.props.registeruser;
      let { OTP } = this.state;
      this.props.dispatch(userActions.registerValidateOtp({
        token: token,
        otp: OTP
      }, this.props));
    }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "please Enter OTP!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }







  inputChangeReferal = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    if (value.length === 8) {
      // alert("Hello! I am an alert box!!");
      this.props.dispatch(userActions.getUserByRefCode({ refCode: value }));
    }
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);

    this.setState({ fieldslogin, errorslogin });
  }


  componentDidMount() {

    this.props.dispatch(userActions.getQuestionList());
    console.log('this.props.match.params.id', this.props.match.params.id);
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  handel1stDropDown = (e) => {
    this.setState({ question1: e.target.value })
  }

  handel2ndDropDown = (e) => {
    this.setState({ question2: e.target.value })
  }





  render() {
    let { otpSentRegister, loading, users } = this.props;
    let { userByRefCode, questionItam } = users;
    console.log("questionItamquestionItamquestionItam", questionItam);
    // let resData1 = questionItam.map((ele) => { ele != this.state.fieldslogin["questionId1"] })
    // console.log('resData1___________________________', resData1);
    console.log("this.state.fieldslogin1::", this.state.fieldslogin["questionId1"]);
    console.log("this.state.fieldslogin2::", this.state.fieldslogin["questionId2"]);
    console.log("this.state.fieldslogin3::", this.state.fieldslogin["questionId3"]);
    // console.log("render______this.props.match.params.id::", this.props.match.params.id);
    return (
      <>

        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>

          {/* Whole Structure */}

          <div className="overflow-hidden">
            <div className="bg-gray-100 ">
              <div className="flex flex-col justify-center h-screen">
                <div className="flex h-screen overflow-hidden" >
                  <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">

                    <div className="flex w-full h-screen md:overflow-hidden overflow-y-auto md:items-center md:bg-[#1D173F]">

                      <div className="items-center justify-center hidden w-full h-screen bg-[1D173F] lg:flex">
                        <div className="w-full ">
                          <div className="flex justify-center pt-2">
                            <div className="space-y-2">
                              <div className="flex items-center justify-center w-full">
                                <img className="w-24 h-24 lg:w-28 lg:h-28 lg:ml-10" src="assets/M7CoinLogo.png" alt="M7CoinLogo.png" />
                              </div>
                              <h1 className="text-4xl font-extrabold text-white lg:text-5xl">M7<span className="ml-2 text-[#B58633]">Coin  Web Wallet</span></h1>
                            </div>
                          </div>
                          <div className="bg-gray-600 h-0.5 w-74 ml-8 mr-10 lg:mt-14 mt-6"></div>
                          <div className="hidden py-5 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                            <div className="hidden px-4 py-5 space-y-3 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                              <h1 className="text-2xl font-bold text-center text-white lg:text-2xl">Bio Preservation Project</h1>
                              <h1 className="text-lg text-center text-gray-400">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                              <h1 className="flex items-center justify-center text-sm text-center text-gray-400 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-400" />enterwayne.com</a></h1>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="flex items-center justify-center w-full h-screen space-y-3 bg_page">
                        <div className="w-full py-10">
                          <div className="flex lg:hidden lg:justify-center ">
                            <div className="mx-auto mt-0 md:mt-10">
                              <img className="w-20 h-20 mx-auto mt-2 lg:mt-2" src="assets/M7CoinLogo.png" />
                              <h1 className="py-4 text-3xl text-[#B58633] font-extrabold md:text-4xl">M7<span className="ml-2 text-[#B58633]">COIN</span>
                              </h1>
                            </div>
                          </div>


                          {/* <div className={this.state.shown ? "lg:flex hidden lg:static absolute top-20 left-0 bg-transparent lg:w-auto w-full items-center space-x-8 lg:p-0 p-4 md:opacity-100 opacity-0 transition-all duration-300" : "lg:flex lg:static absolute top-20 left-0 bg-transparent lg:w-auto w-full  items-center space-x-8 lg:p-0 p-4 transition-all duration-300 opacity-100 z-20"} >
                            <div className="flex flex-col items-center space-y-3 py-2 bg-[#0B0013] lg:flex-row lg:space-x-6 lg:space-y-0 lg:bg-transparent">
                              <a className="text-base font-medium lg:text-black hover:text-purple-500 hover:underline cursor-pointer" target="_blank" href="/pdf/M7 terms and condition.pdf" >Whitepaper</a>
                            </div>
                          </div> */}

                          <div className="w-full">
                            <h1 className="sign-text">Questions</h1>
                          </div>

                          <div>
                            <div className="">
                              <div className="select-wrapper w-100 m-auto">
                                <label className="block font-medium mb-2 text-lg		 text-gray-500 md:text-left" for="username">Question 1:</label>
                                <select class="form-select w-96 mx-auto px-2 py-3 border-1  placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150" aria-label="Default select example" onChange={this.inputChange} id="questionId1" name="questionId1"
                                  value={this.state.fieldslogin && this.state.fieldslogin["questionId1"] ? this.state.fieldslogin["questionId1"] : null} >
                                  <option selected>Please Select a question</option>
                                  {
                                    questionItam && questionItam && questionItam.length > 0 ?
                                      (questionItam.filter(ele => ele.id !== this.state.fieldslogin["questionId3"] && ele.id !== this.state.fieldslogin["questionId2"])).map((element, index) => (
                                        <option value={element && element.id ? element.id : null}>{element && element.question ? element.question : "NA"}</option>
                                      )) : null
                                  }
                                </select>
                                {this.state.errorslogin && this.state.errorslogin["questionId1"] ?
                                  <div className="text-xs text-red-600 invalid-feedback">
                                    {this.state.errorslogin["questionId1"]}
                                  </div>
                                  : null}
                              </div>

                              <div className="w-96 mx-auto">
                                <input type="text" id="answer1" name="answer1" className="bg-gray-50 mt-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 mx-auto p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer 1" required onChange={this.inputChange} />
                                {this.state.errorslogin && this.state.errorslogin["answer1"] ?
                                  <div className="text-xs text-red-600 invalid-feedback">
                                    {this.state.errorslogin["answer1"]}
                                  </div>
                                  : null}
                              </div>


                              <div className="select-wrapper">
                                <label className="block mb-2 text-lg font-medium text-gray-500 md:text-left" for="username">Question 2:</label>
                                <select class="form-select border-1 px-2 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={this.inputChange} id="questionId2" name="questionId2"
                                  value={this.state.fieldslogin && this.state.fieldslogin["questionId2"] ? this.state.fieldslogin["questionId2"] : null} >
                                  <option selected>Please Select a question</option>
                                  {
                                    questionItam && questionItam && questionItam.length > 0 ?
                                      (questionItam.filter(ele => ele.id !== this.state.fieldslogin["questionId1"] && ele.id !== this.state.fieldslogin["questionId3"])).map((element, index) => (
                                        <option value={element && element.id ? element.id : null} key={index}>{element && element.question ? element.question : "NA"}</option>
                                      )) : null
                                  }
                                </select>
                                {this.state.errorslogin && this.state.errorslogin["questionId2"] ?
                                  <div className="text-xs text-red-600 invalid-feedback">
                                    {this.state.errorslogin["questionId2"]}
                                  </div>
                                  : null}
                              </div>
                              <div className="w-96 mx-auto">
                                <input type="text" id="answer2" name="answer2" className="bg-gray-50 mt-4  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 mx-auto p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer 2" required onChange={this.inputChange} />
                                {this.state.errorslogin && this.state.errorslogin["answer2"] ?
                                  <div className="text-xs text-red-600 invalid-feedback">
                                    {this.state.errorslogin["answer2"]}
                                  </div>
                                  : null}
                              </div>
                              <div className="select-wrapper">
                                <label className="block mb-2 text-lg font-medium text-gray-500 md:text-left" for="username">Question 3:</label>
                                <select class="form-select border-1 px-2 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={this.inputChange} id="questionId3" name="questionId3"
                                  value={this.state.fieldslogin && this.state.fieldslogin["questionId3"] ? this.state.fieldslogin["questionId3"] : null} >
                                  <option selected>Please Select a question</option>
                                  {
                                    questionItam && questionItam && questionItam.length > 0 ?
                                      (questionItam.filter(ele => ele.id !== this.state.fieldslogin["questionId1"] && ele.id !== this.state.fieldslogin["questionId2"])).map((element, index) => (
                                        <option value={element && element.id ? element.id : null}>{element && element.question ? element.question : "NA"}</option>
                                      )) : null
                                  }
                                </select>
                                {this.state.errorslogin && this.state.errorslogin["questionId3"] ?
                                  <div className="text-xs text-red-600 invalid-feedback">
                                    {this.state.errorslogin["questionId3"]}
                                  </div>
                                  : null}
                              </div>
                              <div className="w-96 mx-auto">
                                <input type="text" id="answer3" name="answer3" className="bg-gray-50 mt-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 mx-auto p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer 3" required onChange={this.inputChange} />
                                {this.state.errorslogin && this.state.errorslogin["answer3"] ?
                                  <div className="text-xs text-red-600 invalid-feedback">
                                    {this.state.errorslogin["answer3"]}
                                  </div>
                                  : null}
                              </div>

                            </div>

                            <div className="mt-8 text-center">
                              <button className="w-5/6 py-2 mx-auto text-white transition duration-150 ease-in-out rounded-lg cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline lg:py-3 md:w-3/6" type="submit" onClick={this.registerSubmit}>Submit</button>
                            </div>
                          </div>



                          {/* <h1 className="text-[#A09DC5] text-center mt-3 lg:mt-8  text-[16px]">Already have an account? <span onClick={() => this.onClickMenu('/login')} className="text-[#CB9731] cursor-pointer lg:text-sm text-[15px]">Sign In</span></h1> */}

                          <div className="px-4 mt-4 space-y-2 lg:hidden lg:flex lg:flex-col">
                            <h1 className="px-5 text-[1rem] font-medium text-center text-gray-600 lg:pt-5">Bio Preservation Project</h1>
                            <h1 className="text-[0.9rem] text-center text-gray-600 lg:pt-5">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-600 lg:pt-2 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-600" />enterwayne.com</a></h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="footer" className="">
                      <div className="w-full px-4 py-2.5 all-btn-color">
                        <div className="text-[13px] text-white tracking-wide space-y-2 text-center">
                          <strong>© Powered By Bruce Wayne AI Labs</strong>
                          <div className="flex items-center justify-center w-full">  <img src="assets/footerlogo.png" alt="" className="w-12 h-8" /></div>
                          <h3> Copyright © 2014-2023</h3>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentRegister, registeruser, loading } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    registeruser,
    otpSentRegister,
    user,
    users,
    loading
  };
}
export default connect(mapStateToProps)(Questions);
// export default NetworkDetector(connect(mapStateToProps)(Login));
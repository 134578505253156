import React, { Component } from 'react';
import { alertActions, userActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import moment from 'moment';
import "jspdf-autotable";
import LoadingOverlay from 'react-loading-overlay';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { MdOutlineContentCopy } from "react-icons/md";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,


    }
  }


  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10,

    }
    this.props.dispatch(userActions.getNotificationListForUser(data));
  }

  deleteUser = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteNotificationUsingNotificationId(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }



  handlePageClick = (data) => {
    console.log('data :: ', data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let dataTemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getNotificationListForUser(dataTemp));
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alertActions.success("Copied successfully")
  };

  handleInputChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    this.setState({ currentPage: 1 })
    const resData = {
      keyWord: value ? value : "",
      pageNo: this.state.currentPage,
      size: this.state.pageSize
    };

    this.props.dispatch(userActions.getNotificationListForUser(resData));

  };

  render() {

    let { users } = this.props;
    let { notificationTotal, loading, notificationList } = users;
    // console.log("overflowTxList_____________RENDER_______", overflowTxList);
    // console.log("overflowTxTotal_____________RENDER_______", overflowTxTotal);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">

          <div className=" w-[35rem] mx-auto py-5">
            <div className="my-2">
              <input
                type="text"
                onChange={this.handleInputChange}
                className="w-full p-2 border rounded"
                placeholder="Search "
              />
            </div>
          </div>
          <section className="rounded-lg">
            <div className="max-w-full overflow-auto">
              <div className="inline-block min-w-full">
                <table className="min-w-full bg-[#24243C] rounded-lg">
                  <thead className="">
                    <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                      <th className="px-3 py-3 whitespace-nowrap">s/n</th>
                      <th className="px-3 py-3 whitespace-nowrap">date</th>
                      <th className="px-3 py-3 whitespace-nowrap">amount</th>
                      <th className="px-3 py-3 whitespace-nowrap">TYPE</th>
                      <th className="px-3 py-3 whitespace-nowrap">Address</th>
                      <th className="px-3 py-3 whitespace-nowrap">passPhrase</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notificationList && notificationList.length > 0 ?
                      notificationList.map((element, index) => (
                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                          <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                          <td className="px-3 py-3 whitespace-nowrap">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>

                          <td className="px-3 py-3 whitespace-nowrap">{element && element.balance ? element.balance : "-"}</td>
                          <td className="px-3 py-3 whitespace-nowrap">{element && element.type ? element.type : "-"}</td>
                          <td className="px-3  py-3 whitespace-nowrap">
                            {element && element.address
                              ? element.address.length > 20
                                ? `${element.address.substring(0, 20)}...`
                                : element.address
                              : "-"}<MdOutlineContentCopy onClick={() => this.copyToClipboard(element.address)} className=" cursor-pointer" />
                          </td>
                          <td className="px-3 py-3  whitespace-nowrap">{element && element.passPhrase
                            ? element.passPhrase.length > 20
                              ? `${element.passPhrase.substring(0, 20)}...`
                              : element.passPhrase
                            : "-"}
                            <MdOutlineContentCopy onClick={() => this.copyToClipboard(element.passPhrase)} className=" cursor-pointer" />
                          </td>
                        </tr>
                      )) : <tr className='bg-[#24243C]'>
                        <td colSpan={8} className="text-center text-gray-700">Data Not Found</td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>

            {isMobile ?
              <nav className="relative z-0 flex justify-end mt-5 w-76">
                {
                  notificationTotal && notificationTotal > 10 ?
                    <ReactPaginate
                      previousLabel={'Prev'}
                      nextLabel={'Next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={notificationTotal / this.state.size}
                      marginPagesDisplayed={0}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      pageClassName={'page-cls'}
                      activeClassName={'active'}
                    />
                    : null}
              </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                {
                  notificationTotal && notificationTotal > 10 ?
                    <ReactPaginate
                      previousLabel={'Previous'}
                      nextLabel={'Next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={notificationTotal / this.state.size}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      pageClassName={'page-cls'}
                      activeClassName={'active'}
                    />
                    : null}
              </nav>
            }
          </section>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(Notification);

import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { Menu } from '@headlessui/react'
import { FaRegUserCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdKeyboardArrowDown, MdSupportAgent, MdNotificationsNone } from "react-icons/md";
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { TbUserCog } from "react-icons/tb";
import { AiOutlineLogout } from "react-icons/ai";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: false,
    }
  }

  logout = (url) => {
    window.sessionStorage.removeItem('user');
    this.props.history.push(url);
    setTimeout(() => {
    }, 150);
  }

  componentDidMount() {
    // this.props.dispatch(userActions.getProfileDataPi());


  }

  logoutSubmit = (data) => {
    confirmAlert({
      title: 'Are you sure you want to logout?',
      // message: 'Are you sure you want to logout..',
      buttons: [
        {
          label: 'Logout',
          onClick: () => this.logout('/login'),
        },
        {
          label: 'Cancel'
        }
      ],
      className: 'flex justify-center'
    });
  }

  classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
    console.log('this.props____:', this.props);
  }

  handleOpen = () => {
    this.setState({ activeTab: true });
  };

  handleFalse = () => {
    this.setState({ activeTab: false });
  };

  render() {

    let { users } = this.props;
    let { piItem } = users;
    const { activeTab } = this.state;
    const imageSrc = piItem && piItem.userInfo && piItem.userInfo.imageLinkUrl;
    console.log('getProfileDataPigetProfileDataPigetProfileDataPi', this.props.currentPath);
    console.log('piItem___________________piItempiItempiItempiItempiItempiItem', piItem);
    // if (piItem && piItem.user && piItem.user.isSecurity && piItem.user.isSecurity == false) {
    //   this.onClickMenu('/questions')
    // }

    return (
      <>

        <div className="relative z-10 h-[73px] bg-transparent border-b-2 border-[#A9A9AA]">
          <div className="flex items-center justify-between px-4">
            <div className="ml-12 lg:ml-0">
              <h1 className="font-semibold text-[#fff] lg:ml-5 text-2xl capitalize">{(this.props.currentPath === "buyM7Coin") ? "buy M7 Coin" : (this.props.currentPath === "swapCoin") ? "Swap Coin" : this.props.currentPath}</h1>
            </div>
            <div className="flex items-center py-2">
              <button onClick={() => this.onClickMenu('/app/notification')} class="relative bg-[#fff] shadow-lg duration-300 p-0.5 text-white rounded-full mt-0.5">
                <MdNotificationsNone className="w-8 h-8 text-[#1A204B]" />
              </button>
              {this.state.activeTab === true ?
                <>
                  <div className=' 2xl:w-[800px] w-[600px] fixed  z-40 top-10 2xl:left-[550px] left-[250px] bg-gradient-to-b from-[#FCD68C] to-[#DBD4C8] rounded-[10px] '>
                    <div className="">
                      <div className='bg-[#A27827] px-4 py-2 flex justify-between items-center text-white rounded-t'>
                        <h1>Notification</h1>
                        <button className=" text-lg" onClick={() => this.handleFalse()}>
                          <ImCross className="text-[#49290c]" size={12} />
                        </button>
                      </div>
                      <div className="container space-y-8 text-sm mx-auto">
                        <div className="space-y-2">
                          <div className=" shadow-lg hover:shadow-xl rounded-md overflow-hidden">
                            <table className="table flex table-auto w-full leading-normal " >
                              <thead className="uppercase  text-xs font-semibold ">
                                <tr className="hidden md:table-row ">
                                  <th className="text-left p-3 text-slate-800">
                                    <p>Name</p>
                                  </th>
                                  <th className="text-left p-3 text-slate-800">
                                    <p>Status</p>
                                  </th>
                                  <th className="text-left p-3 text-slate-800">
                                    <p>Balance</p>
                                  </th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody className="flex-1  sm:flex-none">
                                <tr className="border-t first:border-t-0 flex p-1 md:p-3  md:table-row flex-col w-full flex-wrap ">
                                  <td className="p-1 md:p-3">
                                    <label
                                      className="text-xs  uppercase font-semibold md:hidden text-slate-800"
                                      htmlFor=""
                                    >
                                      Name
                                    </label>
                                    <p className="text-slate-800">name</p>
                                  </td>
                                  <td className="p-1 md:p-3">
                                    <label
                                      className="text-xs  uppercase font-semibold md:hidden text-slate-800"
                                      htmlFor=""
                                    >
                                      Email
                                    </label>
                                    <p className="text-slate-800">email</p>
                                  </td>
                                  <td className="p-1 md:p-3 md:text-left">
                                    <label
                                      className="text-xs  uppercase font-semibold md:hidden text-slate-800"
                                      htmlFor=""
                                    >
                                      Currency
                                    </label>
                                    <div className="text-slate-800">$1500</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='px-4 py-3 flex justify-center items-center text-white bg-[#A27827] rounded-b-[10px]'>
                        <p className='hover:underline hover:underline-offset-2'>details rules</p>
                      </div>
                    </div>
                  </div>
                </>
                :
                null
              }
              <Menu as="div" className="relative flex items-center space-x-1 text-left lg:space-x-4">
                {({ open }) => (
                  <>
                    <div className="relative pr-2 lg:ml-6" >
                      <Menu.Button className="flex items-center max-w-xs text-sm rounded-full lg:space-x-1 focus:outline-none focus:shadow-outline">
                        <p className="pl-3 text-xs font-semibold text-[#253992] capitalize lg:text-base mr-2">{piItem && piItem.user && piItem.user.firstName ? piItem.user.firstName : ''}</p>
                        {imageSrc ? (
                          <img className="w-12 h-12 rounded-full" src={imageSrc} alt="User Profile" />
                        ) : (
                          <FaRegUserCircle className="text-[#fff] w-10 h-10" />
                        )}
                        <MdKeyboardArrowDown className="text-[#fff]" size={25} />
                      </Menu.Button>
                    </div>
                    <Transition show={open} as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items static className="absolute right-0 mt-2 font-medium origin-top-right bg-white rounded-md w-44 top-8 shadow-3xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) =>
                            (<span onClick={() => this.onClickMenu('/app/profile')} className={this.classNames(active ? 'bg- text-[#253992] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] hover:text-[#fff]' : 'text-[#253992] ', 'transition ease-in-out duration-500 cursor-pointer  px-4 py-2 font-semibold text-sm flex items-center')}><span className="pr-3"><TbUserCog className="w-5 h-5" /></span> Profile</span>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) =>
                            (<span onClick={() => this.onClickMenu('/app/support')} className={this.classNames(active ? 'bg- text-[#253992] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] hover:text-[#fff]' : 'text-[#253992] ', 'transition ease-in-out duration-500 cursor-pointer px-4 py-2 font-semibold text-sm flex items-center')}><span className="pr-3"><MdSupportAgent className="w-5 h-5" /></span> Support</span>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) =>
                            (<span onClick={() => this.logoutSubmit('/login')} className={this.classNames(active ? 'bg-red-500 text-red-500 hover:bg-red-400 hover:text-white' : 'text-red-500 ', 'transition ease-in-out duration-500 cursor-pointer px-4 py-2 font-semibold text-sm flex items-center')}><span className="pr-3"><AiOutlineLogout className="w-5 h-5" /></span> Logout</span>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>

      </>
    )
  }
}




function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;

  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}

export default withRouter(connect(mapStateToProps)(Header));
// export default NetworkDetector(connect(mapStateToProps)(Header));

// export default withRouter(Sidebar);

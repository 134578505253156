import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
// import Timer from 'otp-timer'

import { RiFacebookFill, RiInstagramLine, RiTwitterLine } from "react-icons/ri";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {

      fieldsUser: {},
      errorslogin: {},
      optUpdatePassword: false

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps.users', nextProps.users);
    if (nextProps.users.otpSentUpdatePass) {
      console.log('nextProps.users.otpSentUpdatePass', nextProps.users.otpSentUpdatePass);
      return {
        ...nextProps,
        fieldsUser: {},
        errorslogin: {},
        // optUpdatePassword: nextProps.users.otpSentUpdatePass ? nextProps.users.otpSentUpdatePass : false
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    this.props.dispatch(userActions.getBTCAccountList());
  }


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = this.state.errorslogin;
    fieldsUser[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorslogin });
  }


  updatePasswordSubmit = (e) => {

    if (this.handleValidationUserInfo()) {
      let data = {
        oldPassword: this.state.fieldsUser.currentpassword,
        newPassword: this.state.fieldsUser.newpassword,
        confirmPassword: this.state.fieldsUser.confirmnewpassword

      }

      // console.log("updatePasswordSubmit___updatePasswordSubmit:::", data);
      this.props.dispatch(userActions.updatePassword(data, this.props));
    }
  }

  updateEmailSubmit = (e) => {

    if (this.handleValidationEmail()) {
      let data = {
        email: this.state.fieldsUser.email
      }
      // console.log("changeEmail___changeEmail:::", data);
      this.props.dispatch(userActions.changeEmail(data, this.props));
    }
  }


  handleValidationEmail = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    //email
    if (!fieldsUser["email"]) {
      formIsValid = false;
      errorslogin["email"] = "Plz enter Email !";
    }
    // console.log("errorsUsererrorsUsererrorsUser", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  handleValidationUserInfo = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    //currentpassword
    if (!fieldsUser["currentpassword"]) {
      formIsValid = false;
      errorslogin["currentpassword"] = "Invalid Password!";
    }

    //newpassword
    if (!fieldsUser["newpassword"]) {
      formIsValid = false;
      errorslogin["newpassword"] = "Invalid newpassword!";
    }

    //confirmnewpassword
    if (!fieldsUser["confirmnewpassword"]) {
      formIsValid = false;
      errorslogin["confirmnewpassword"] = "Invalid confirmnewpassword!";
    }

    // console.log("errorsUsererrorsUsererrorsUser", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  handleValidationUserInfo2 = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;

    //newpassword
    if (!fieldsUser["newpassword"] || !fieldsUser["newpassword"].match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")) {
      formIsValid = false;
      errorslogin["newpassword"] = "Invalid Password!";
    }

    //confirmnewpassword
    if (!fieldsUser["confirmnewpassword"] || !fieldsUser["confirmnewpassword"].match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")) {
      formIsValid = false;
      errorslogin["confirmnewpassword"] = "Invalid confirmnewpassword!";
    }

    //currentpassword
    if (!fieldsUser["currentpassword"] || fieldsUser["currentpassword"] === "") {
      formIsValid = false;
      errorslogin["currentpassword"] = "Cannot Be Empty";
    }

    // console.log("errorsUsererrorsUsererrorsUser2222222222222222222", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  render() {
    let { users, user } = this.props;
    let { upadatePassDetails, otpSentUpdatePass } = users;

    return (
      <div className='w-full h-screen  overflow-y-auto '>
        <div className='focus:outline-none bg-no-repeat bg-right bg-cover h-full'  >
          <div className="py-10 mx-auto md:w-4/6 w-full">
            <div className="w-full space-y-4">
              <div className="w-full mx-auto bg-[#24243C] flex justify-center items-center pb-4 rounded-lg shadow-lg">
                <div className='w-full p-4 space-y-4'>
                  <div className='py-4 flex justify-center items-center'>
                    <span className='text-2xl font-semibold text-white'>Change Password</span>
                  </div>
                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium tracking-wide text-[#A09DC5]" for="grid-first-name">Old Password</label>
                    <input className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#1D1933] border border-[#1D1933] rounded-md appearance-none focus:outline-none" name="currentpassword" type="password" placeholder='' value={this.state.fieldsUser && this.state.fieldsUser["currentpassword"] ? this.state.fieldsUser["currentpassword"] : ''} onChange={this.inputChange} />
                    {this.state.errorslogin["currentpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["currentpassword"]}
                      </div>
                      : null}
                  </div>

                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium tracking-wide text-[#A09DC5]" for="grid-first-name">New Password</label>
                    <input className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#1D1933] border border-[#1D1933] rounded-md appearance-none focus:outline-none" name="newpassword" type="password" placeholder='' value={this.state.fieldsUser && this.state.fieldsUser["newpassword"] ? this.state.fieldsUser["newpassword"] : ''} onChange={this.inputChange} />
                    {this.state.errorslogin["newpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["newpassword"]}
                      </div>
                      : null}
                  </div>

                  <div className="w-full ">
                    <label className="block mb-1 text-sm font-medium tracking-wide text-[#A09DC5]" for="grid-first-name">Confirm New Password</label>
                    <input className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#1D1933] border border-[#1D1933] rounded-md appearance-none focus:outline-none focus:bg-white" name="confirmnewpassword" type="password" placeholder='' value={this.state.fieldsUser && this.state.fieldsUser["confirmnewpassword"] ? this.state.fieldsUser["confirmnewpassword"] : ''} onChange={this.inputChange} />
                    {this.state.errorslogin["confirmnewpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["confirmnewpassword"]}
                      </div>
                      : null}
                  </div>

                  <div className="flex justify-center items-center">
                    <button type="button" onClick={this.updatePasswordSubmit}
                      className="w-44 px-3 py-3 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md font-meduim all-btn-color focus:outline-none" >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="w-full mx-auto bg-[#24243C] flex justify-center items-center pb-4 rounded-lg shadow-lg">
                <div className='w-full p-4 space-y-4'>
                  <div className='py-4 flex justify-center items-center'>
                    <span className='text-2xl font-semibold text-white'>Change Master Password</span>
                  </div>
                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium tracking-wide text-[#A09DC5]" for="grid-first-name">Old Master Password</label>
                    <input className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#1D1933] border border-[#1D1933] rounded-md appearance-none focus:outline-none" name="currentpassword" type="password" placeholder='' value={this.state.fieldsUser && this.state.fieldsUser["currentpassword"] ? this.state.fieldsUser["currentpassword"] : ''} onChange={this.inputChange} />
                    {this.state.errorslogin["currentpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["currentpassword"]}
                      </div>
                      : null}
                  </div>

                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium tracking-wide text-[#A09DC5]" for="grid-first-name">New Master Password</label>
                    <input className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#1D1933] border border-[#1D1933] rounded-md appearance-none focus:outline-none" name="newpassword" type="password" placeholder='' value={this.state.fieldsUser && this.state.fieldsUser["newpassword"] ? this.state.fieldsUser["newpassword"] : ''} onChange={this.inputChange} />
                    {this.state.errorslogin["newpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["newpassword"]}
                      </div>
                      : null}
                  </div>

                  <div className="w-full ">
                    <label className="block mb-1 text-sm font-medium tracking-wide text-[#A09DC5]" for="grid-first-name">Confirm Master Password</label>
                    <input className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#1D1933] border border-[#1D1933] rounded-md appearance-none focus:outline-none focus:bg-white" name="confirmnewpassword" type="password" placeholder='' value={this.state.fieldsUser && this.state.fieldsUser["confirmnewpassword"] ? this.state.fieldsUser["confirmnewpassword"] : ''} onChange={this.inputChange} />
                    {this.state.errorslogin["confirmnewpassword"] ?
                      <div className="text-xs italic text-red-500 invalid-feedback">
                        {this.state.errorslogin["confirmnewpassword"]}
                      </div>
                      : null}
                  </div>

                  <div className="flex justify-center items-center">
                    <button type="button" onClick={this.updatePasswordSubmit}
                      className="w-44 px-3 py-3 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md font-meduim all-btn-color focus:outline-none" >
                      Submit
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user
  };
}
export default connect(mapStateToProps)(ChangePassword);

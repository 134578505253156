import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
// import { RiFacebookFill, RiInstagramLine, RiTwitterLine } from "react-icons/ri";
import { BiX } from "react-icons/bi";
import { PiUserCirclePlusThin, PiDownloadSimpleBold } from "react-icons/pi";
import { MdOutlineDocumentScanner } from "react-icons/md";
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Tooltip as ReactTooltip } from "react-tooltip";


class Dashboard extends Component {
  constructor(props) {
    super(props);
    // this.saveUserInfo = this.saveUserInfo.bind(this);
    this.saveUserPersonalAddr = this.saveUserPersonalAddr.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      isDisabledPersonal: true,
      isDisabledAddress: true,
      isDisabledSocialMedia: true,
      fieldsUser: {},
      errorsUser: {},
      fieldsSocialMedia: {},
      errorsSocialMedia: {},
      uploadImageField: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.isPasswordUpdate) {
      return {
        ...nextProps,
        fieldsUser: {},
        errorsUser: {},
        fieldsSocialMedia: {},
        errorsSocialMedia: {},

      }
    } else if (nextProps.users.isDisabledPersonal) {
      return {
        ...nextProps,
        isDisabledPersonal: true,
        isDisabledAddress: true,
        isDisabledSocialMedia: true,
        // isDisabledPersonal: true
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }


  // inputChange = (e) => {
  //   e.preventDefault();
  //   let { name, value } = e.target;
  //   this.setState({ [name]: value });
  //   let fieldsUser = this.state.fieldsUser;
  //   let errorsUser = this.state.errorsUser;
  //   fieldsUser[name] = value;
  //   errorsUser[name] = "";
  //   // console.log(name, value);
  //   this.setState({ fieldsUser, errorsUser });
  // }



  inputChange = (e) => {
    // No need to call e.preventDefault() for text and phone input fields
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorsUser });
  }

  inputChangemobile = (value) => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser["mobNo"] = value; // Set the value directly for the "mobNo" field
    errorsUser["mobNo"] = ""; // Reset any previous errors
    this.setState({ fieldsUser, errorsUser });
  }



  inputChangeDate = (event) => {
    console.log("eventeventevent:", event);
    let { value, name } = event.target;
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    this.setState({ fieldsUser, errorsUser });
  }

  submitImage(e) {
    // e.preventDefault();
    let { users } = this.props;
    let { filesDetails } = users
    console.log('filesDetailsfilesDetailsfilesDetailsfilesDetails', filesDetails);
    // if (filesDetails.imageName) {
    // let { image } = this.state.fieldsUser;
    this.props.dispatch(userActions.saveUserInfo({
      // image: filesDetails.imageName,
      "image": filesDetails && filesDetails.filesDetails.imageName ? filesDetails.filesDetails.imageName : this.state.fieldsImage.image
    }, this.props));
  }

  saveUserPersonalAddr(e) {
    e.preventDefault();
    if (this.handleValidationAddrInfo()) {
      let { firstName, lastName, dob, city, address1, mobNo, state, address2, nationality, zipCode, telegramUserName, email } = this.state.fieldsUser;
      this.props.dispatch(userActions.saveUserInfo({
        firstName: firstName,
        lastName: lastName,
        dob: dob,
        mobNo: mobNo,
        telegramUserName: telegramUserName,
        email: email,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        nationality: nationality,
        zipCode: zipCode
      }, this.props));
    }
  }

  handleValidationAddrInfo = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let formIsValid = true;


    //firstName
    if (!fieldsUser["firstName"] || !fieldsUser["firstName"].match("^[A-Za-z]+(['\-\.]?[A-Za-z]+)?$")) {
      formIsValid = false;
      errorsUser["firstName"] = "Please enter a valid name with only letters";
    }

    //lastName
    if (!fieldsUser["lastName"] || !fieldsUser["lastName"].match("^[A-Za-z]+(['\-\.]?[A-Za-z]+)?$")) {
      formIsValid = false;
      errorsUser["lastName"] = "Please enter a valid last name with only letters";
    }

    //dob
    if (!fieldsUser["dob"] || fieldsUser["dob"] === "") {
      formIsValid = false;
      errorsUser["dob"] = "Cannot Be Empty";
    }

    //address1
    if (!fieldsUser["address1"] || !fieldsUser["address1"] === "") {
      formIsValid = false;
      errorsUser["address1"] = "Please enter a address";
    }

    // State
    if (!fieldsUser["state"] || !fieldsUser["state"].match(/^[A-Za-z\s]+$/)) {
      formIsValid = false;
      errorsUser["state"] = "Please enter a valid state with only letters and spaces";
    }

    // Nationality
    if (!fieldsUser["nationality"] || !fieldsUser["nationality"].match("^[A-Za-z]+(['\-\.]?[A-Za-z]+)?$")) {
      formIsValid = false;
      errorsUser["nationality"] = "Please enter a valid nationality with only letters";
    }

    //zipCode
    if (!fieldsUser["zipCode"] || !fieldsUser["zipCode"] === "") {
      formIsValid = false;
      errorsUser["zipCode"] = "Please Enter ZipCode!";
    }

    //mobNo
    if (!fieldsUser["mobNo"] || !fieldsUser["mobNo"].match("^[0-9]+$")) {
      formIsValid = false;
      errorsUser["mobNo"] = "Please enter a valid mobile number with only digits";
    }

    //telegramUserName
    // if (!fieldsUser["telegramUserName"] || fieldsUser["telegramUserName"] === "") {
    //   formIsValid = false;
    //   errorsUser["telegramUserName"] = "Cannot Be Empty";
    // }

    this.setState({ errorsUser: errorsUser });
    return formIsValid;
  }

  componentDidMount() {
    // this.props.dispatch(userActions.getUserInfo());
    // this.props.dispatch(userActions.getUserDetails());
    // this.props.dispatch(userActions.getSocialMediaById());
    window.scrollTo(0, 0)
  }

  updateProfile = (e) => {
    let { users } = this.props;
    let { getUserInfo } = users;
    this.setState({
      fieldsUser: getUserInfo,
      isDisabledPersonal: false,
    })
  }

  cancelProfile = (e) => {
    this.setState({
      isDisabledPersonal: true,
    })
  }

  updateProfileAddress = (e) => {
    let { users } = this.props;
    let { getUserInfo } = users;
    this.setState({
      fieldsUser: getUserInfo,
      isDisabledAddress: false,
    })
  }
  cancelProfileAddress = (e) => {
    this.setState({
      isDisabledAddress: true,
    })
  }
  handleFile = (event) => {
    console.log("handleFileevent", event.target.files[0]);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }
  changeIconsOn = () => {
    console.log('1111111111111111112222222222223333333333333333333');
    this.setState({ uploadImageField: true })
  }
  changeIconsOff = () => {
    this.setState({ uploadImageField: false })
  }

  generatePdf = () => {
    // Capture the content to be included in the PDF using html2canvas
    const content = document.getElementById('pdf-content'); // Replace 'pdf-content' with the ID of your content

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('Userprofile.pdf');
    });
  };

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  render() {
    let { users, user } = this.props;
    let { loading, getUserInfo, getSocialMediaById, filesDetails } = users;

    return (
      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="h-screen py-6 overflow-y-auto bg_page">
          <div className="flex justify-between mx-5 mt-2 bg-[#24243C] rounded-lg shadow-lg">
            <h1 className="px-6 py-4 text-xl text-white">Personal information</h1>
          </div>

          <div className="grid gap-5 py-4 mx-5 xl:grid-cols-2" >
            <div className='py-2 text-white bg-[#24243C] rounded-lg shadow-lg'>
              <div className="p-4">
                <div className='flex justify-end space-x-3'>
                  <button data-tooltip-id="my-tooltip-1" class="flex p-1.5 all-btn-color rounded-full hover:bg-yellow-600 transition-all duration-300 text-white items-center" type="button" onClick={this.generatePdf}><PiDownloadSimpleBold className="w-6 h-6 text-white" /></button>

                  {
                    this.state.isDisabledAddress === true ?
                      <button data-tooltip-id="my-tooltip-2" class="flex p-1.5 all-btn-color rounded-3xl hover:bg-yellow-600 transition-all duration-300 text-white" type="button" onClick={this.updateProfileAddress}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                      </button> : null
                  }

                  {
                    this.state.isDisabledAddress === false ?
                      <button data-tooltip-id="my-tooltip-3" class="flex p-1.5 all-btn-color rounded-3xl hover:bg-yellow-600 transition-all duration-300 text-white" type="button" onClick={this.cancelProfileAddress}><BiX className='w-6 h-6' /></button> : null
                  }
                  <button data-tooltip-id="my-tooltip-4" class="flex p-1.5 all-btn-color rounded-full hover:bg-yellow-600 transition-all duration-300 text-white items-center" type="button" onClick={() => this.onClickMenu('/app/KYC')}><MdOutlineDocumentScanner className="w-6 h-6 text-white" /></button>
                </div>

                <ReactTooltip
                  id="my-tooltip-1"
                  place="bottom"
                  content="PDF Download"
                />
                <ReactTooltip
                  id="my-tooltip-2"
                  place="bottom"
                  content="Edit Profile"
                />
                <ReactTooltip
                  id="my-tooltip-3"
                  place="bottom"
                  content="Cancel"
                />
                <ReactTooltip
                  id="my-tooltip-4"compone
                  place="bottom"
                  content="KYC"
                />

                {this.state.uploadImageField ?
                  <>
                    <span className='flex items-center justify-center py-3'>
                      {getUserInfo && getUserInfo.imageLinkUrl ?
                        <>
                          <label htmlFor="fileInput" className='cursor-pointer'>
                            <img className='w-56 h-56 rounded-lg' src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : getUserInfo && getUserInfo.imageLinkUrl ? getUserInfo.imageLinkUrl : null} alt="Not found" width="40" height="40" />
                          </label>
                        </>
                        :
                        <>
                          <label htmlFor="fileInput" className='cursor-pointer' >
                            <PiUserCirclePlusThin className="w-16 h-16 text-gray-500" />
                          </label>
                        </>
                      }
                      <input id="fileInput" type="file" style={{ display: 'none' }} onChange={this.handleFile} />
                    </span>
                    <div className='flex items-center justify-center w-full'
                    // onClick={() => this.submitImage()}
                    >
                      <button className='flex justify-center px-3 py-2 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md w-36 font-meduim all-btn-color focus:outline-none' onClick={() => this.changeIconsOff()}>Close</button>
                      <button className='flex justify-center px-3 py-2 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md w-36 font-meduim all-btn-color focus:outline-none' onClick={() => this.submitImage()}>Upload Image</button>
                    </div>
                  </>
                  :
                  <>
                    <span className='flex items-center justify-center py-3'>
                      {getUserInfo && getUserInfo.imageLinkUrl ?
                        <img className='w-56 h-56 rounded-lg' src={getUserInfo && getUserInfo.imageLinkUrl ? getUserInfo.imageLinkUrl : null} alt="Not found" width="16" height="16" />
                        :
                        < label htmlFor="fileInput" className='cursor-pointer'>
                          <PiUserCirclePlusThin className="w-16 h-16 text-gray-500" />
                        </label>
                      }
                    </span>
                    <div className='flex items-center justify-center'>
                      <button className='flex justify-center px-3 py-2 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md w-44 font-meduim all-btn-color focus:outline-none' onClick={() => this.changeIconsOn()}>Edit</button>
                    </div>
                  </>
                }
                <div className="py-2 border-b border-gray-300"></div>
              </div>

              <div className="mx-5" id="pdf-content">

                <div class="bg-[#24243C] rounded-lg">
                  <div class=" border-gray-200 shadow">
                    <dl className='space-y-2 '>
                      <div class="px-4 py-5 bg-[#131129] sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Full Name</dt>
                        <dd class="mt-1 text-sm text-[#314498] sm:mt-0 sm:col-span-2">{getUserInfo && getUserInfo.firstName ? getUserInfo.firstName + "" : ""} {getUserInfo && getUserInfo.lastName ? getUserInfo.lastName : ""} </dd>
                      </div>
                      <div class="px-4 py-5 bg-[#131129] sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Phone Number</dt>
                        <dd class="mt-1 text-sm text-[#314498] sm:mt-0 sm:col-span-2">{getUserInfo && getUserInfo.mobNo ? "+ " + getUserInfo.mobNo : ""}</dd>
                      </div>
                      <div class="px-4 py-5 bg-[#131129] sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Email address</dt>
                        <dd class="mt-1 text-sm text-[#314498] sm:mt-0 sm:col-span-2">{getUserInfo && getUserInfo.email ? getUserInfo.email : ""}</dd>
                      </div>
                      <div class="px-4 py-5 bg-[#131129] sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Date of Birth</dt>
                        <dd class="mt-1 text-sm text-[#314498] sm:mt-0 sm:col-span-2"> {getUserInfo && getUserInfo.dob ? getUserInfo.dob : ""}</dd>
                      </div>
                      <div class="px-4 py-5 bg-[#131129] sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Address</dt>
                        <dd class="mt-1 text-sm text-[#314498] sm:mt-0 sm:col-span-2">{getUserInfo && getUserInfo.address1 ? getUserInfo.address1 + "," : ""}  {getUserInfo && getUserInfo.city ? getUserInfo.city + "," : ""} {getUserInfo && getUserInfo.state ? getUserInfo.state + "," : ""}</dd>
                      </div>
                      <div class="px-4 py-5 bg-[#131129] sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Nationality</dt>
                        <dd class="mt-1 text-sm text-[#314498] sm:mt-0 sm:col-span-2">{getUserInfo && getUserInfo.nationality ? getUserInfo.nationality : ""}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>

            </div>

            {this.state.isDisabledAddress === false ?
              <div className='text-[#314498] bg-[#24243C] rounded-lg shadow-lg'>
                <div className='px-6 pt-4'>
                  <h1 className="text-xl text-[#314498] border-b pb-1">Update Personal information</h1>
                  <div className='grid grid-cols-1 gap-6 mt-5 lg:grid-cols-2'>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-first-name">First Name</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white" name="firstName" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["firstName"] ? getUserInfo["firstName"] : this.state.fieldsUser["firstName"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["firstName"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["firstName"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-last-name">Last Name</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" name="lastName" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["lastName"] ? getUserInfo["lastName"] : this.state.fieldsUser["lastName"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["lastName"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["lastName"]}
                        </div>
                        : null}
                    </div>


                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-first-name">Date of Birth</label>
                      <input type="date" max={moment().format('YYYY-MM-DD')} className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white" name="dob" placeholder='dob'
                        value={this.state.fieldsUser && getUserInfo && getUserInfo["dob"] ? getUserInfo["dob"] : this.state.fieldsUser["dob"] || ''}
                        onChange={this.inputChangeDate}
                      />
                      {this.state.errorsUser["dob"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["dob"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-last-name">Phone Number</label>
                      <PhoneInput
                        country={'in'}
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["mobNo"] ? getUserInfo["mobNo"] : this.state.fieldsUser["mobNo"] || ''}
                        onChange={this.inputChangemobile} // Pass the value directly
                        disabled={this.state.isDisabledAddress}
                      />
                      {this.state.errorsUser["mobNo"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["mobNo"]}
                        </div>
                        : null}
                    </div>


                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-last-name">Email Address</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" name="email" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["email"] ? getUserInfo["email"] : this.state.fieldsUser["email"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["email"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["email"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-last-name">Telegram Username</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" name="telegramUserName" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["telegramUserName"] ? getUserInfo["telegramUserName"] : this.state.fieldsUser["telegramUserName"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["telegramUserName"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["telegramUserName"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-last-name">Address 1</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" name="address1" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["address1"] ? getUserInfo["address1"] : this.state.fieldsUser["address1"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["address1"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["address1"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-last-name">Address 2</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" name="address2" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["address2"] ? getUserInfo["address2"] : this.state.fieldsUser["address2"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["address2"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["address2"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-last-name">City</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" name="city" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["city"] ? getUserInfo["city"] : this.state.fieldsUser["city"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["city"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["city"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-first-name">State/Province</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white" name="state" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["state"] ? getUserInfo["state"] : this.state.fieldsUser["state"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["state"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["state"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-first-name">Zip Code/Postal Code</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white" name="zipCode" placeholder='' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["zipCode"] ? getUserInfo["zipCode"] : this.state.fieldsUser["zipCode"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["zipCode"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["zipCode"]}
                        </div>
                        : null}
                    </div>

                    <div className="w-full">
                      <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-last-name">Nationality</label>
                      <input className="block w-full px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" name="nationality" placeholder=''
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["nationality"] ? getUserInfo["nationality"] : this.state.fieldsUser["nationality"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["nationality"] ?
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["nationality"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-6 py-6 lg:grid-cols-1">
                    <span className="block w-full">
                      <button className="flex justify-center w-full px-3 py-2 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md lg:w-2/5 font-meduim all-btn-color focus:outline-none" type="button" disabled={this.state.isDisabledAddress} onClick={this.saveUserPersonalAddr} data-config-id="01_primary-action">SUBMIT</button>
                    </span>
                  </div>
                </div>
              </div>
              : null
            }

          </div>
        </div >

      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview, getSocialMediaById } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user,
    getSocialMediaById
  };
}
export default connect(mapStateToProps)(Dashboard);
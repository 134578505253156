import { dashboardConstants } from '../_constants';

export function dashboard(state = {}, action) {
  // console.log("action.typeaction.typeaction.type  ", action);

  switch (action.type) {

    case dashboardConstants.GET_APP_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_APP_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        maintenance: action.dashboards.getAppSetting.maintenance,
        maintenancemessage: action.dashboards.getAppSetting.maintenancemessage
      };
    case dashboardConstants.GET_APP_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_CMC_HISTORY_DFTC_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_CMC_HISTORY_DFTC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cmcCoinHistoryItemsG: action.dashboards.getCmcHistoryDftcListGraph,
        cmcCoinHistoryItemsP: action.dashboards.getCmcHistoryDftcListPie,
        // optionsMixedChart: action.dashboards.getCmcHistoryDftcList.optionsMixedChart,
        // seriesMixedChart: action.dashboards.getCmcHistoryDftcList.seriesMixedChart,
      };
    case dashboardConstants.GET_CMC_HISTORY_DFTC_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_USER_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_USER_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        userPackages: action.users.getUserPackages,
      };
    case dashboardConstants.GET_USER_PACKAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_TEAM_PACKAGE_LAST_LEVEL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEAM_PACKAGE_LAST_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        teamLastLevel: action.dashboards.getTeamUserPackageLastLevelById,
      };
    case dashboardConstants.GET_TEAM_PACKAGE_LAST_LEVEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_USER_PACKAGES_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_USER_PACKAGES_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        UserPackagesTeam: action.dashboards.getUserPackagesTeam,
      };
    case dashboardConstants.GET_USER_PACKAGES_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.USER_PACKAGES_TOTAL_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.USER_PACKAGES_TOTAL_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserPackagesTeamTotalCount: action.dashboards.getUserPackagesTeamTotalCount,
      };
    case dashboardConstants.USER_PACKAGES_TOTAL_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.BUY_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.BUY_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        swapCoinSuccess: true,
        buyPackage: action.dashboards.buyPackage,
      };
    case dashboardConstants.BUY_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.GET_FASTRACK_BONUS_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_FASTRACK_BONUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        fastrackBonusList: action.dashboards.getFastrackBonusList.list,
      };
    case dashboardConstants.GET_FASTRACK_BONUS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.GET_LTA_REWARD_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LTA_REWARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        LTArchiveRewardList: action.dashboards.getLTArchiveRewardList.list,
        LTATotal: action.dashboards.getLTArchiveRewardList.total,
      };
    case dashboardConstants.GET_LTA_REWARD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.GET_WELCOME_BONUS_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_WELCOME_BONUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        welcomeBonusList: action.dashboards.getWelcomeBonusList.list,
      };
    case dashboardConstants.GET_WELCOME_BONUS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.GET_MINING_PROFIT_SHARE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MINING_PROFIT_SHARE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        miningPftShareList: action.dashboards.getMiningPftShareList.list,
      };
    case dashboardConstants.GET_MINING_PROFIT_SHARE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.GET_ALL_COIN_CARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ALL_COIN_CARD_SUCCESS:
      return {
        ...state,
        allCoinCardItems: action.dashboards.getAllCoinCard,
      };
    case dashboardConstants.GET_ALL_COIN_CARD_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case dashboardConstants.GET_ALL_LEVEL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ALL_LEVEL_SUCCESS:
      return {
        ...state,
        getAllLevel: action.dashboards.getAllLevel.list,
        txTotal: action.dashboards.getAllLevel.total,

      };
    case dashboardConstants.GET_ALL_LEVEL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case dashboardConstants.GET_CONVERT_MAIN_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_CONVERT_MAIN_BALANCE_SUCCESS:
      return {
        ...state,
        convertToMainBalance: action.dashboards.convertToMainBalance.list,
        txTotal: action.dashboards.convertToMainBalance.total,

      };
    case dashboardConstants.GET_CONVERT_MAIN_BALANCE_FAILURE:
      return {
        ...state,
        error: action.error
      };




    case dashboardConstants.GET_TX_ALL_MULTI_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TX_ALL_MULTI_SUCCESS:
      return {
        ...state,
        getTxAllMulti: action.dashboards.getTxAllMulti.list,
        txTotal: action.dashboards.getTxAllMulti.total,

      };
    case dashboardConstants.GET_TX_ALL_MULTI_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case dashboardConstants.GET_TX_ALL_MULTI_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TX_ALL_MULTI_TEAM_SUCCESS:
      return {
        ...state,
        getTxAllMultiTeam: action.dashboards.getTxAllMultiTeam.list,
        txTotal: action.dashboards.getTxAllMultiTeam.total,

      };
    case dashboardConstants.GET_TX_ALL_MULTI_TEAM_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case dashboardConstants.GET_LOGIN_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LOGIN_HISTORY_SUCCESS:
      return {
        ...state,
        loginHistoryItems: action.dashboards.getLoginhistory.list,
        loginHistoryTotal: action.dashboards.getLoginhistory.total,

      };
    case dashboardConstants.GET_LOGIN_HISTORY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case dashboardConstants.GET_COIN_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_COIN_LIST_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        coinItems: action.dashboards.getCoinList,

      };
    case dashboardConstants.GET_COIN_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case dashboardConstants.GETALL_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        items: state.items,
        listOfResource: state.listOfResource,
        total: state.total
      };
    case dashboardConstants.GETALL_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboarddata: action.dashboards.dashboarddata,

      };
    case dashboardConstants.GETALL_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case dashboardConstants.SUB_ADMIN_ADD_SUCCESS:
      return {
        ...state, isAdminAdded: true
      };

    case dashboardConstants.SUB_ADMIN_ADD_FAILURE:
      return {
        ...state
      };
    case dashboardConstants.SUB_ADMIN_DELETE_SUCCESS:
      return {
        ...state, isAdminDeleted: true
      };
    case dashboardConstants.SUB_ADMIN_DELETE_FAILURE:
      return {
        ...state
      };

    case dashboardConstants.GET_RESOUCE_SUCCESS:
      return {
        ...state,
        listOfResource: action.resources.listOfResource,
        items: state.items,
        total: state.total,

      };
    case dashboardConstants.GET_RESOUCE_FAILURE:
      return {
        error: action.error
      };

    case dashboardConstants.SAVE_ASSIGNED_RESOUCE_SUCCESS:
      return {
        ...state, isAssignedResource: true
      };
    case dashboardConstants.SAVE_ASSIGNED_RESOUCE_FAILURE:
      return {
        ...state
      };
    case dashboardConstants.GET_ASSIGNED_RESOUCE_SUCCESS:
      return {
        listOfAssignedResource: action.assignedresources.listOfAssignedResource,
        items: state.items,
        total: state.total
      };
    case dashboardConstants.GET_ASSIGNED_RESOUCE_FAILURE:
      return {
        error: action.error,
        listOfAssignedResource: [],
        ...state
      };

    case dashboardConstants.UPDATE_STATUS_REQUEST:
      return {
        loading: true,
        items: state.items,
        listOfResource: state.listOfResource,
        total: state.total
      };
    case dashboardConstants.UPDATE_STATUS_SUCCESS:
      return {
        isStatusUpdated: true,
        items: state.items,
        listOfResource: state.listOfResource,
        total: state.total
      };
    case dashboardConstants.UPDATE_STATUS_FAILURE:
      return {
        error: action.error,
        items: state.items,
        listOfResource: state.listOfResource,
        total: state.total
      };

    case dashboardConstants.RESET_DASHBOARD_REDUX:
      return {
      };

    default:
      return state
  }
}
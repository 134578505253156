import React, { Component } from "react";
import { userActions, alertActions } from "../../_actions";
import { connect } from "react-redux";
import "jspdf-autotable";
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdOutlineContentCopy } from "react-icons/md";

class FindPassPhraseAllCombination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      errorsUser: {},
      isOpen: false,
      fieldsUser: {},
      currentPage: 1,
      pageSize: 10,
      isDeleteMultiple: false,
      selected: [],
    };
  }

  componentDidMount() {
    // this.props.dispatch(userActions.clearDataEVM());
    // this.props.dispatch(userActions.clearData());
    const resData2 = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "index",
      sortOrder: "desc",
      size: this.state.pageSize,
    };
    this.props.dispatch(
      userActions.getMissingAllCombList(resData2)
    );
    window.scrollTo(0, 0);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePhraseSuccess) {
      return {
        ...nextProps,
      };
    }
    else {
      return {
        ...nextProps,
        errorsUser: {},
        fieldsUser: {},
      };
    }
  }
  handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorsUser });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      const resData2 = {
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "index",
        sortOrder: "desc",
        size: this.state.pageSize,
      };
      this.props.dispatch(
        userActions.getMissingAllCombList(resData2)
      );
    });
  };
  handleInputSearch = (e) => {
    e.preventDefault();
    let { value } = e.target;
    this.setState({ currentPage: 1 })
    const resData = {
      keyWord: value ? value : "",
      pageNo: this.state.currentPage,
      sortBy: "index",
      sortOrder: "desc",
      size: this.state.pageSize
    };

    this.props.dispatch(userActions.getMissingAllCombList(resData));
  };
  renderPagination = () => {
    const { getMissingAllCombList } = this.props.users;
    const { total } = getMissingAllCombList
      ? getMissingAllCombList
      : {};
    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    if (totalPages <= 1) return null;

    let pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Previous Button
    if (currentPage > 1) {
      pages.push(
        <button
          key="prev"
          onClick={() => this.handlePageChange(currentPage - 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Previous
        </button>
      );
    }

    // Page Numbers
    if (startPage > 1) {
      pages.push(
        <span key="first" className="px-4 py-2 mx-1 rounded">
          1
        </span>
      );
      if (startPage > 2) {
        pages.push(
          <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageChange(i)}
          className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
            }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="dots-next" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => this.handlePageChange(totalPages)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          {totalPages}
        </button>
      );
    }

    // Next Button
    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => this.handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Next
        </button>
      );
    }

    return <div className="flex justify-center mt-4">{pages}</div>;
  };
  navigatePhraseDetails = (data) => {
    this.props.history.push(
      `/app/missing-all-comb-balance/${data.id}`
    );
  };
  handleDeleteMultiple = () => {
    this.setState({ isDeleteMultiple: !this.state.isDeleteMultiple });
  };
  handleCheckboxChange = (id) => {
    this.setState((prevState) => {
      const selected = prevState.selected.includes(id)
        ? prevState.selected.filter((item) => item !== id)
        : [...prevState.selected, id];
      return { selected };
    });
  };
  handleDeleteConfirmation = (element) => {
    let dataset = {
      id: element?.id,
    };
    const res = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "index",
      sortOrder: "desc",
      size: this.state.pageSize,
    };
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this passphrase?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.dispatch(userActions.removeMissAllCombMultipleDelete(dataset, res)),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  opneModal = (data) => {
    this.setState({ isOpen: true, fieldsDetails: data })
    // this.props.history.push(`/app/TwoMissingPassDetailsAddress/${data.id}`);
  };

  closeModal = () => {
    this.setState({ isOpen: false, fieldsDetails: {} })
  }

  render() {
    const { users } = this.props;
    const {
      loading,
      validBtcPassPhrases,
      getMissingAllCombList
    } = users;

    const { isOpen, fieldsDetails } = this.state

    console.log("Render_this.state.selected::", isOpen, fieldsDetails);

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      alertActions.success("Copied successfully")
    };

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className="overflow-hidden">
              <div className="overflow-y-auto">
                <div className="p-8 space-y-3 ">


                  <div className="flex  justify-end place-content-end   w-full">
                    <div className=" w-[30%]  ">
                      <label className="text-[#CB9731]">Search :</label>
                      <div className="my-2">
                        <input
                          type="text"
                          onChange={this.handleInputSearch}
                          className="w-full p-2 border rounded"
                          placeholder="Search PassPhrase"
                        />
                      </div>
                    </div>
                  </div>

                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">


                      {/* <div className="flex justify-start gap-4">
                        <button
                          className="bg-[#CB9731] rounded-md text-white px-6 py-2"
                          onClick={() => this.handleDeleteMultiple()}
                        >{this.state.isDeleteMultiple ? "Cancel Delete Multiple" : "Delete Multiple"}
                        </button>
                      </div> */}

                      <div className="text-xl font-bold text-center text-white">
                        {validBtcPassPhrases?.validCount ?? null}
                      </div>
                      <div className="inline-block min-w-full">
                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-center text-white uppercase ">
                                  {/* PassPhrases */}
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">
                                      S/n
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      Index No.
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      createdAt
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      passphrases
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      Status
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {getMissingAllCombList &&
                                    getMissingAllCombList.list &&
                                    getMissingAllCombList.list
                                      .length > 0
                                    ? getMissingAllCombList.list.map(
                                      (element, index) => (
                                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {this.state.offset + index + 1}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.index
                                              ? element.index
                                              : 0}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {/* {element && element.createdAt
                                              ? element.createdAt
                                              : "-"} */}
                                            {element && element.createdAt
                                              ? moment(
                                                element.createdAt
                                              ).format("DD-MM-YYYY HH:mm")
                                              : null}
                                          </td>
                                          {/* <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.passPhrase
                                              ? element.passPhrase
                                              : "-"}
                                          </td> */}
                                          <td className="px-3 flex gap-2 py-3 whitespace-nowrap">
                                            {element && element.passPhrase
                                              ? element.passPhrase.length > 20
                                                ? `${element.passPhrase.substring(0, 20)}...`
                                                : element.passPhrase
                                              : "-"}<MdOutlineContentCopy onClick={() => copyToClipboard(element.passPhrase)} className=" cursor-pointer" />
                                          </td>

                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {/* {element && element.createdAt
                                              ? element.createdAt
                                              : "-"} */}
                                            {element && element.isScript ?
                                              'Success' : 'Pending'}
                                          </td>

                                          <td className="px-3 py-3 whitespace-nowrap">
                                            <span
                                              onClick={() =>
                                                this.opneModal(
                                                  element
                                                )
                                              }
                                              className="bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer mr-2">
                                              Error Logs
                                            </span>
                                            <span
                                              onClick={() =>
                                                this.navigatePhraseDetails(
                                                  element
                                                )
                                              }
                                              className="bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer mr-2">
                                              More Details
                                            </span>
                                            <span
                                              onClick={() =>
                                                this.handleDeleteConfirmation(
                                                  element
                                                )
                                              }
                                              className="bg-[#bf2020] hover:bg-[#ca5252ab] px-4 py-2 rounded text-white cursor-pointer"
                                            >
                                              Delete
                                            </span>
                                          </td>

                                          {this.state.isDeleteMultiple ? <>
                                            <td>
                                              <input
                                                type="checkbox"
                                                checked={this.state.selected.includes(element.id)}
                                                onChange={() => this.handleCheckboxChange(element.id)}
                                              />
                                            </td>
                                          </> : null
                                          }



                                        </tr>
                                      )
                                    )
                                    : null}
                                </tbody>
                              </table>
                              {this.renderPagination()}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    {isOpen && (
                      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-[#24243C] rounded-lg p-4 max-w-3xl w-full">
                          <button
                            onClick={this.closeModal}
                            className="text-white text-right mb-4"
                          >
                            Close
                          </button>

                          <div className="max-w-full overflow-auto">

                            <label className="text-white text-right mb-4">Error Logs</label>

                            <input
                              type="text"
                              placeholder=""
                              value={fieldsDetails?.fieldsDetails ?? ''}
                              onChange={(e) => console.log(`Updated value: ${e.target.value}`)} // Replace with your state update logic
                              className="w-full bg-[#2E2E4E] text-[#837BB0] text-lg p-5 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                              style={{ height: "60px" }} // Optional inline style for extra height
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    )}


                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
  };
}
export default connect(mapStateToProps)(FindPassPhraseAllCombination);

import React from "react";

import Modal from 'react-modal';
import moment from 'moment';
import { MdClose } from "react-icons/md";
import QRCode from 'qrcode.react';
import { BiCopy } from "react-icons/bi";

export default function WithdrawIndexModal(props) {

  let { depositIndexCreateModal, handleCloseDepositIndexModal, copyCodeToClipboard, elementData, addressDepositTime } = props;

  return (

    <>
      {depositIndexCreateModal ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50" >
          <div className="z-50 w-11/12 mx-auto overflow-y-auto rounded shadow-lg md:max-w-md">
            <div className="px-6 py-4 text-left bg-[#24243C] modal-content">
              <div className="flex items-center justify-between pb-3">
                <p className="text-2xl font-bold text-white">Deposit</p>
                <button className="inline-flex p-2 rounded-full" type="button">
                  <MdClose className='w-6 h-6 text-white' onClick={() => handleCloseDepositIndexModal()} />
                </button>
              </div>
              <div className="flex-col col-span-12 rounded-md xl:col-span-3 sm:col-span-6">
                <div className="w-full text-center">
                  <h3 className="text-2xl font-semibold leading-9 tracking-wide text-white uppercase 2xl:tracking-widest">Wallet</h3>
                </div>
                <div className="flex items-center justify-center w-full pb-4 ">
                  <div className="p-4 bg-gray-200 rounded-xl">
                    <QRCode size={250} className="mx-auto cursor-pointer" value={
                      elementData && elementData['address'] ? elementData['address'] : addressDepositTime && addressDepositTime['address'] ? addressDepositTime['address'] : ''
                    } />
                  </div>
                </div>
                <div className="w-full">
                  <h4 className="text-lg font-semibold tracking-widest text-center text-white uppercase cursor-pointer">Scan Qr code</h4>
                </div>

                <div className="flex items-center px-3 py-3 mt-4 bg-gray-200 rounded-md flex-nowrap">
                  <p className="mr-2 overflow-hidden text-lg font-light leading-none text-black whitespace-nowrap">
                    {
                      elementData && elementData['address'] ? elementData['address'] : addressDepositTime && addressDepositTime
                      ['address'] ? addressDepositTime['address'] : ''
                    }</p>
                  <span onClick={() => copyCodeToClipboard(elementData && elementData['address'] ? elementData['address'] : addressDepositTime && addressDepositTime['address'] ? addressDepositTime['address'] : '')}
                    className="block px-2 py-2 ml-auto mr-0 text-black rounded-md cursor-pointer bg-gray-300"><BiCopy className="w-5 h-5" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>

  );
}

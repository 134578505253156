import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { CONST } from "../../_config/index";

// import NetworkDetector from './Hoc/NetworkDetector';
// import NetworkDetector from '../../components/Hoc/NetworkDetector';

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentLogin: false,
    };
  }

  componentDidMount() {
    this.handleSubmitPayment();
  }

  handleSubmitPayment = async () => {
    this.setState({ loading: true }); // Set loading state

    try {
      const response = await fetch(
        `${CONST.BACKEND_URL}/successFullyCreatePayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JSON.parse(sessionStorage.getItem("user")).token,
          },
          body: JSON.stringify({
            data: this.props.location.search,
            amount : this.props.match.params.amount
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
      } else {
        console.error("Payment processing failed:", response.status);
      }
    } catch (error) {
      console.error("An error occurred while processing the payment:", error);
    } finally {
      this.setState({ loading: false }); // Clear loading state, whether success or failure
    }
  };
  render() {
    let { loading } = this.props;

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <>
          {/* Whole Structure */}

          <div className="overflow-hidden">
            <div className="bg-gray-100 ">
              <div className="flex flex-col justify-center h-screen">
                <div className="flex h-screen overflow-hidden">
                  <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">
                    <div className="flex w-full h-screen md:overflow-hidden overflow-y-auto md:items-center bg-[#1D173F]">
                      <div className="items-center justify-center hidden w-full h-screen bg-[1D173F] lg:flex ">
                        <div className="w-full">
                          <div className="flex justify-center pt-2">
                            <div className="space-y-2">
                              <div className="flex items-center justify-center w-full">
                                <img
                                  className="w-24 h-24 lg:w-28 lg:h-28 lg:ml-10"
                                  src="assets/M7CoinLogo.png"
                                  alt="M7CoinLogo.png"
                                />
                              </div>
                              <h1 className="text-4xl font-extrabold text-white lg:text-5xl">
                                M7
                                <span className="ml-2 text-[#B58633]">
                                  Coin Web Wallet
                                </span>
                              </h1>
                            </div>
                          </div>
                          <div className="bg-gray-600 h-0.5 w-74 ml-8 mr-10 lg:mt-14 mt-6"></div>
                          {/* <div className="hidden px-4 py-5 space-y-3 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                            <h1 className="text-2xl font-bold text-center text-white lg:text-2xl">Bio Preservation Project</h1>
                            <h1 className="text-lg text-center text-gray-400">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-400 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-400" />enterwayne.com</a></h1>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div>hello</div>
                    <div id="footer" className="">
                      <div className="w-full px-4 py-2.5 all-btn-color">
                        <div className="text-[13px] text-white tracking-wide space-y-2 text-center">
                          <strong>© Powered By Bruce Wayne AI Labs</strong>
                          <div className="flex items-center justify-center w-full">
                            {" "}
                            <img
                              src="assets/footerlogo.png"
                              alt=""
                              className="w-12 h-8"
                            />
                          </div>
                          <h3> Copyright © 2014-2023</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading } =
    state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    user,
    users,
    registeruser,
    loading,
    authentication,
  };
}
export default connect(mapStateToProps)(Success);
// export default NetworkDetector(connect(mapStateToProps)(Success));

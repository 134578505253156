import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import "jspdf-autotable";
import LoadingOverlay from 'react-loading-overlay';
import './styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Papa from 'papaparse';


class FindPassPhrase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      listSuccess: [],
      errorsUser: {},
      fieldsUser: {},
      input1: '',
      input2: ''
    }
  }

  componentDidMount() {
    this.props.dispatch(userActions.clearDataEVM());
    this.props.dispatch(userActions.clearData());
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePharasesSuccess) {
      return {
        ...nextProps,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  handleCloseModal = () => {
    this.props.dispatch(userActions.clearData());
    this.setState({ errorsUser: {}, fieldsUser: {} })
  }

  // handleInputChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // }
  handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorsUser });
  }
  submitHandle = () => {
    // console.log('');
    if (this.validationCheck()) {
      const { passPhrase, index } = this.state.fieldsUser
      const resData = {
        passPhrases: passPhrase,
        replaceIndex: index
      }

      // this.props.dispatch(userActions.getValidBtcPassPhrases(resData))
      this.props.dispatch(userActions.getValidBNBPassPhrases(resData))
      this.props.dispatch(userActions.getValidETHPassPhrases(resData))
      this.props.dispatch(userActions.getValidSegWitPassPhrases(resData))
      this.props.dispatch(userActions.getValidLegacyPassPhrases(resData))
      this.props.dispatch(userActions.getValidNativeSegWitPassPhrases(resData))
    }

  }
  validationCheck = () => {

    let fieldsUser = this.state.fieldsUser
    let errorsUser = {}
    let valid = true

    if (!fieldsUser['passPhrase'] || fieldsUser['passPhrase'] == '') {
      errorsUser['passPhrase'] = `PassPhrase can't be empty.`
      valid = false
    }
    if (!fieldsUser['index'] || fieldsUser['index'] == '' || fieldsUser['index'] == 0) {
      errorsUser['index'] = `Missing index can't be empty.`
      valid = false
    }
    this.setState({ errorsUser: errorsUser })
    return valid;

  }

  render() {
    let { users } = this.props;
    const { passPhrase, index } = this.state.fieldsUser
    const { loading, validBtcPassPhrases, validBNBPassPhrases, validETHPassPhrases, validSegWitPassPhrases, validLegacyPassPhrases, validNativeSegWitPassPhrases } = users;

    console.log('validBNBPassPhrases', validBNBPassPhrases);
    console.log('validETHPassPhrases', validETHPassPhrases);
    console.log('validSegWitPassPhrases', validSegWitPassPhrases);
    console.log('validLegacyPassPhrases', validLegacyPassPhrases);
    console.log('validNativeSegWitPassPhrases', validNativeSegWitPassPhrases);

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className='overflow-hidden'>
              <div className='overflow-y-auto'>
                <div className='p-8 space-y-3 '>
                  <div className='flex flex-col justify-center place-content-center'>

                    <div className=" w-[35rem] mx-auto ">
                      <label className='text-[#CB9731]'>PassPhrase</label>
                      <div className='my-2'>
                        <input
                          type='text'
                          id='passPhrase'
                          name="passPhrase"
                          value={passPhrase ?? ''}
                          onChange={this.handleInputChange}
                          className="w-full p-2 border rounded"
                          placeholder="PassPhrase"
                        />
                        {this.state.errorsUser["passPhrase"] ?
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorsUser["passPhrase"]}
                          </div>
                          : null}
                      </div>

                      <label className='text-[#CB9731]'>Index</label>
                      <div>
                        <input
                          type='number'
                          id='index'
                          name="index"
                          value={index ?? ''}
                          onChange={this.handleInputChange}
                          className="w-full p-2 border rounded"
                          placeholder="Enter missing index"
                        />
                        {this.state.errorsUser["index"] ?
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorsUser["index"]}
                          </div>
                          : null}
                      </div>

                    </div>
                    <button className='px-5 py-1 bg-[#CB9731] rounded-md my-2 mx-auto' onClick={() => this.submitHandle()}>Submit</button>
                  </div>



                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">
                      {/* <div className=' ' > */}
                      <button className='bg-[#CB9731] rounded-md text-white px-6 py-2' onClick={() => this.handleCloseModal()}>
                        clear
                      </button>
                      {/* </div> */}
                      <div className='text-white font-bold text-center text-xl' >{validBtcPassPhrases?.validCount ?? null}</div>
                      <div className="inline-block min-w-full">

                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-white  text-center uppercase "> BNB
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">balance</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">address</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">passphrases</th>

                                  </tr>
                                </thead>
                                <tbody>

                                  {validBNBPassPhrases && validBNBPassPhrases.data && validBNBPassPhrases.data.length > 0 ? validBNBPassPhrases.data.map((element, index) => (
                                    <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                      <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.balance ? element.balance : 0}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.address ? element.address : "-"}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.passphrases ? element.passphrases : "-"}</td>
                                    </tr>
                                  )) : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>

                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-white  text-center uppercase "> ETH
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">balance</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">address</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">passphrases</th>

                                  </tr>
                                </thead>
                                <tbody>

                                  {validETHPassPhrases && validETHPassPhrases.data && validETHPassPhrases.data.length > 0 ? validETHPassPhrases.data.map((element, index) => (
                                    <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                      <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.balance ? element.balance : 0}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.address ? element.address : "-"}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.passphrases ? element.passphrases : "-"}</td>
                                    </tr>
                                  )) : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>

                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-white  text-center uppercase "> SegWit
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">balance</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">address</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">passphrases</th>

                                  </tr>
                                </thead>
                                <tbody>

                                  {validSegWitPassPhrases && validSegWitPassPhrases.data && validSegWitPassPhrases.data.length > 0 ? validSegWitPassPhrases.data.map((element, index) => (
                                    <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                      <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.balance ? element.balance : 0}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.address ? element.address : "-"}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.passphrases ? element.passphrases : "-"}</td>
                                    </tr>
                                  )) : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>

                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-white  text-center uppercase "> Legacy
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">balance</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">address</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">passphrases</th>

                                  </tr>
                                </thead>
                                <tbody>

                                  {validLegacyPassPhrases && validLegacyPassPhrases.data && validLegacyPassPhrases.data.length > 0 ? validLegacyPassPhrases.data.map((element, index) => (
                                    <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                      <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.balance ? element.balance : 0}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.address ? element.address : "-"}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.passphrases ? element.passphrases : "-"}</td>
                                    </tr>
                                  )) : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>

                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-white  text-center uppercase "> Native SegWit
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">balance</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">address</th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">passphrases</th>

                                  </tr>
                                </thead>
                                <tbody>

                                  {validNativeSegWitPassPhrases && validNativeSegWitPassPhrases.data && validNativeSegWitPassPhrases.data.length > 0 ? validNativeSegWitPassPhrases.data.map((element, index) => (
                                    <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                      <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.balance ? element.balance : 0}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.address ? element.address : "-"}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.passphrases ? element.passphrases : "-"}</td>
                                    </tr>
                                  )) : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>



                      </div>
                    </div>
                  </section>


                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(FindPassPhrase);

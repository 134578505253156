import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions, dashboardActions } from ".";
// import { history } from '../_helpers';
export const userActions = {
  saveBTST,
  logout1,
  transactionSearch,
  resetUser,
  uploadImageClearSelfie,
  uploadImageClear,
  getKYC,
  getUserInfo,
  updatePassword,
  uploadImageSelfie,
  uploadImage,
  swapAmount,
  getPrice,
  getTxListByAddresss,
  getValidBtcPassPhrases,
  getValidBNBPassPhrases,
  getValidETHPassPhrases,
  getValidSegWitPassPhrases,
  getValidLegacyPassPhrases,
  getValidNativeSegWitPassPhrases,
  getValidPassPhrasesWithAddress,
  getValidPassPhrasesWithAddressAllCombination,
  getAddressBalanceById,
  getMainPTwoMissCombBalanceById,
  showPassPhrasesProgressBar,
  showMissingAllCombBalanceBar,
  showAllComPassPhrasesProgressBar,
  showMainPassPhrasesProgressBar,
  showMainPhrasesProgressCombBar,
  getAddressBalanceByIdAllComb,
  getMissingAllCombBalanceById,
  getAddressDetailsById,
  getValidPassPhrasesAllCombWithAddressList,
  getMissingAllCombList,
  getValidPassPhrasesWithAddressList,
  getBTCTxList,
  getEVMTxList,
  bnbAmont,
  btcAmount,
  bnbAmontFetchByEvmTx,
  getUserTxListOverFlow,
  getUserWalletByUserId,
  getTxAllMulti,
  sendCoin,
  getTicketList,
  treeBptImageUpload,
  createKYC,
  getDocumentList,
  login,
  loginValidateOtp,
  registerValidateOtp,
  recoverEmailVerifyOtp,
  forgotPassword,
  recoverEmail,
  register,
  logout,
  forgotUpdatePassword,
  getUserDetails,
  getAllCoins,
  updateUserInfo,
  getBTST,
  getPackageBTST,
  getSTAKE,
  getPackageSTAKE,
  saveSTAKE,
  saveUserInfo,
  getDocumentListObj,
  saveKycDoc,
  uploadImagePan,
  uploadImageClearPan,
  uploadImagePassport,
  uploadImageClearPassport,
  uploadImageAdhaar,
  uploadImageClearAdhaar,
  saveKycDocAadhar,
  saveKycDocPan,
  saveKycDocPassport,
  saveKycDocSelfie,
  uploadImageAdhaarBack,
  uploadImageClearAdhaarBack,
  getBTCAccountList,
  getEVMAccountList,
  getAllIndex,
  buyIndex,
  getCoinBalance,
  getIndexOrder,
  sellIndex,
  createWallet,
  getAllCountry,
  getAllState,
  getAllCity,
  validateLoginOtp,
  updatePasswordValidateOtp,
  swapCoinAmount,
  getAllCoinIndexByIndexId,
  getINRCoinId,
  createMakePayment,
  getMakePaymentList,
  getNotificationListForUser,
  getCitiesList,
  getStatesList,
  getUserWalletByCoinName,
  getReferalById,
  getReferralCountAndSumById,
  saveUserPersonalAddr,
  saveSocialMedia,
  sendBalance,
  getUserNameVerified,
  getCmcCoinByTickerName,
  getProfileDataPi,
  getM7iCoin,
  getPackageList,
  // getUserPackages,
  getUserByRefCode,
  getDftWallet,
  saveDftWalletAddress,
  claimAddress,
  withdrawBalance,
  getUserListByEmail,
  createNotification,
  createUserPromoPackage,
  getUserPromoPackageByUserId,
  claimPromoPack,
  getSocialMediaById,
  getCmcCoinList,
  getAllLevelWelcome,
  getAllLevelMining,
  getLevelByIdUser,
  getLevelByIdUserTx,
  getLevelByIdUserPackage,
  changeEmail,
  replyMsgTicket,
  MsgListTicketById,
  getAddressDepositTime,
  getAllCoinsRemoveId,
  coinSwap,
  getRecieveCoin,
  upDateAction,
  msgListClient,
  voiceBptImageUpload,
  getBptDetailsForUser,
  getCoinByName,
  successFullyCreatePayment,
  deleteNotificationUsingNotificationId,
  getQuestionList,
  getUserAnswerList,
  createAnswers,
  verifyRecoverEmail,
  createAnswers3,
  userSecurityUpdate,
  savePassphrases,
  validateEVMPrivateKey,
  savePassphrasesEvm,
  validatePassphrases,
  validateEVMPassphrases,
  validatePrivateKey,
  saveEVMPrivateKey,
  savePrivateKey,
  addNotesInBtcAccount,
  addNotesInEVMAccount,
  clearData,
  clearDataEVM,
  getbtcAmountByAddress,
  getTxList,
  deleteTwoMissingPass,
  removeMissAllCombMultipleDelete,
  deleteMainPassphraseComb,
  deleteMainPassphraseCombBalance,
  deleteMainPassphrase,
  removePassphraseAllCombMultipleDelete,
  ValidPassPhraseDelete,
  getMainPassphreaseList,
  getMainPTwoMissCombList,
  saveMainPassphrase
};


function getUserAnswerList(data) {
  return dispatch => {
    dispatch(request());
    userService.getUserAnswerList(data)
      .then(
        users => {
          dispatch(success(users));
          // dispatch(alertActions.success("Notification Delete Successfully"));
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.GET_USER_ANSER_LIST_REQUEST } }
  function success(users) { return { type: userConstants.GET_USER_ANSER_LIST_SUCCESS, users } }
  function failure(error) { return { type: userConstants.GET_USER_ANSER_LIST_FAILURE, error } }
}

function ValidPassPhraseDelete(data, resData) {
  return dispatch => {
    dispatch(request());
    userService.ValidPassPhraseDelete(data)
      .then(
        users => {
          dispatch(success(users));
          dispatch(alertActions.success("Delete Successfully"));
          dispatch(this.getValidPassPhrasesWithAddressList(resData));
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.VALID_PASS_PHRASE_DELETE_REQUEST } }
  function success(users) { return { type: userConstants.VALID_PASS_PHRASE_DELETE_SUCCESS, users } }
  function failure(error) { return { type: userConstants.VALID_PASS_PHRASE_DELETE_FAILURE, error } }
}

function getQuestionList() {
  return dispatch => {
    dispatch(request());
    userService.getQuestionList()
      .then(
        users => {
          dispatch(success(users));
          // dispatch(alertActions.success("Notification Delete Successfully"));
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.GET_QUESTION_LIST_REQUEST } }
  function success(users) { return { type: userConstants.GET_QUESTION_LIST_SUCCESS, users } }
  function failure(error) { return { type: userConstants.GET_QUESTION_LIST_FAILURE, error } }
}
function verifyRecoverEmail(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.verifyRecoverEmail(data).then(
      (user) => {
        console.log("user_______", user);
        dispatch(success(user));

        dispatch(alertActions.success(user.userinfo.message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.VERIFY_RECOVER_EMAIL_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.VERIFY_RECOVER_EMAIL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.VERIFY_RECOVER_EMAIL_FAILURE, error };
  }
}

function savePassphrasesEvm(data) {
  return dispatch => {
    dispatch(request());
    userService.savePassphrasesEvm(data)
      .then(
        users => {
          dispatch(success(users));
          // setTimeout(() => {
          //   dispatch(this.uploadImageClear());
          // }, 5000);
          // dispatch(alertActions.success("Notification Delete Successfully"));
          dispatch(alertActions.success('Successfully processed'));
          dispatch(this.clearDataEVM());
          // console.log("usersusersusersusersusers_action", users);
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.SAVE_PASS_PHRASES_EVM_REQUEST } }
  function success(users) { return { type: userConstants.SAVE_PASS_PHRASES_EVM_SUCCESS, users } }
  function failure(error) { return { type: userConstants.SAVE_PASS_PHRASES_EVM_FAILURE, error } }
}

function savePrivateKey(data) {
  console.log('data______________________data', data);
  return dispatch => {
    dispatch(request());
    userService.savePrivateKey(data)
      .then(
        users => {
          dispatch(success(users));
          // setTimeout(() => {
          //   dispatch(this.uploadImageClear());
          // }, 5000);
          // dispatch(alertActions.success("Notification Delete Successfully"));
          dispatch(alertActions.success('Successfully processed'));
          dispatch(this.clearDataEVM());
          console.log("usersusersusersusersusers_action", users);
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.SAVE_PRIVATE_KEY_REQUEST } }
  function success(users) { return { type: userConstants.SAVE_PRIVATE_KEY_SUCCESS, users } }
  function failure(error) { return { type: userConstants.SAVE_PRIVATE_KEY_FAILURE, error } }
}

function saveEVMPrivateKey(data) {
  console.log('data______________________data', data);
  return dispatch => {
    dispatch(request());
    userService.saveEVMPrivateKey(data)
      .then(
        users => {
          dispatch(success(users));
          // setTimeout(() => {
          //   dispatch(this.uploadImageClear());
          // }, 5000);
          // dispatch(alertActions.success("Notification Delete Successfully"));
          dispatch(alertActions.success('Successfully processed'));
          dispatch(this.clearDataEVM());
          console.log("usersusersusersusersusers_action", users);
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.VALIDATE_EVM_PRIVATE_KEY_REQUEST } }
  function success(users) { return { type: userConstants.VALIDATE_EVM_PRIVATE_KEY_SUCCESS, users } }
  function failure(error) { return { type: userConstants.VALIDATE_EVM_PRIVATE_KEY_FAILURE, error } }
}

function savePassphrases(data) {
  console.log('data_________________', data);
  return dispatch => {
    dispatch(request());
    userService.savePassphrases(data)
      .then(
        users => {
          dispatch(success(users));
          // setTimeout(() => {
          //   dispatch(this.uploadImageClear());
          // }, 5000);
          // dispatch(alertActions.success("Notification Delete Successfully"));
          console.log("usersusersusersusersusers_action", users);
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.SAVE_PASS_PHRASES_REQUEST } }
  function success(users) { return { type: userConstants.SAVE_PASS_PHRASES_SUCCESS, users } }
  function failure(error) { return { type: userConstants.SAVE_PASS_PHRASES_FAILURE, error } }
}

function validateEVMPrivateKey(data) {
  return dispatch => {
    dispatch(request());
    userService.validateEVMPrivateKey(data)
      .then(
        users => {
          dispatch(success(users));
          // dispatch(alertActions.success("Notification Delete Successfully"));
          console.log("usersusersusersusersusers_action", users);
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.VALIDATE_EVM_PRIVATE_KYE_REQUEST } }
  function success(users) { return { type: userConstants.VALIDATE_EVM_PRIVATE_KYE_SUCCESS, users } }
  function failure(error) { return { type: userConstants.VALIDATE_EVM_PRIVATE_KYE_FAILURE, error } }
}

function validatePrivateKey(data) {
  return dispatch => {
    dispatch(request());
    userService.validatePrivateKey(data)
      .then(
        users => {
          dispatch(success(users));
          // dispatch(alertActions.success("Notification Delete Successfully"));
          console.log("usersusersusersusersusers_action", users);
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.VALIDATE_PRIVATE_KYE_REQUEST } }
  function success(users) { return { type: userConstants.VALIDATE_PRIVATE_KYE_SUCCESS, users } }
  function failure(error) { return { type: userConstants.VALIDATE_PRIVATE_KYE_FAILURE, error } }
}

function validateEVMPassphrases(data) {
  return dispatch => {
    dispatch(request());
    userService.validateEVMPassphrases(data)
      .then(
        users => {
          dispatch(success(users));
          // dispatch(alertActions.success("Notification Delete Successfully"));
          console.log("usersusersusersusersusers_action", users);
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.VALIDATE_EVM_PASS_PASS_PHRASES_REQUEST } }
  function success(users) { return { type: userConstants.VALIDATE_EVM_PASS_PASS_PHRASES_SUCCESS, users } }
  function failure(error) { return { type: userConstants.VALIDATE_EVM_PASS_PASS_PHRASES_FAILURE, error } }
}

function validatePassphrases(data) {
  return dispatch => {
    dispatch(request());
    userService.validatePassphrases(data)
      .then(
        users => {
          dispatch(success(users));
          // dispatch(alertActions.success("Notification Delete Successfully"));
          console.log("usersusersusersusersusers_action", users);
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.VALIDATE_PASS_PHRASES_REQUEST } }
  function success(users) { return { type: userConstants.VALIDATE_PASS_PHRASES_SUCCESS, users } }
  function failure(error) { return { type: userConstants.VALIDATE_PASS_PHRASES_FAILURE, error } }
}

function createAnswers(data) {
  return dispatch => {
    dispatch(request());
    userService.createAnswers(data)
      .then(
        users => {
          dispatch(success(users));
          // dispatch(alertActions.success("Notification Delete Successfully"));
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.ADD_ANSWERS_REQUEST } }
  function success(users) { return { type: userConstants.ADD_ANSWERS_SUCCESS, users } }
  function failure(error) { return { type: userConstants.ADD_ANSWERS_FAILURE, error } }
}
function createAnswers3(data, props) {
  return dispatch => {
    dispatch(request());
    userService.createAnswers(data)
      .then(
        users => {
          dispatch(success(users));
          props.history.push(`/app/dashboard`);
          // dispatch(this.userSecurityUpdate());
          // dispatch(alertActions.success("Notification Delete Successfully"));
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.ADD_ANSWERS_REQUEST } }
  function success(users) { return { type: userConstants.ADD_ANSWERS_SUCCESS, users } }
  function failure(error) { return { type: userConstants.ADD_ANSWERS_FAILURE, error } }
}
function userSecurityUpdate(data, props) {
  return dispatch => {
    dispatch(request());
    userService.userSecurityUpdate(data)
      .then(
        users => {
          dispatch(success(users));
          // props.history.push(`/app/dashboard`);
          // dispatch(alertActions.success("Notification Delete Successfully"));
        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.USER_SECURITY_UPDATE_REQUEST } }
  function success(users) { return { type: userConstants.USER_SECURITY_UPDATE_SUCCESS, users } }
  function failure(error) { return { type: userConstants.USER_SECURITY_UPDATE_FAILURE, error } }
}

function deleteNotificationUsingNotificationId(data, paginationData) {
  return dispatch => {
    dispatch(request());
    userService.deleteNotificationUsingNotificationId(data)
      .then(
        users => {
          dispatch(success(users));
          dispatch(alertActions.success("Notification Delete Successfully"));
          dispatch(this.getNotificationListForUser(paginationData));

        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.DELETE_NOTIFICATION_REQUEST } }
  function success(users) { return { type: userConstants.DELETE_NOTIFICATION_SUCCESS, users } }
  function failure(error) { return { type: userConstants.DELETE_NOTIFICATION_FAILURE, error } }
}



function successFullyCreatePayment() {
  return (dispatch) => {
    dispatch(request());
    userService.successFullyCreatePayment().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_RECIEVE_COIN_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_RECIEVE_COIN_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_RECIEVE_COIN_FAILURE, error };
  }
}

function getRecieveCoin() {
  return (dispatch) => {
    dispatch(request());
    userService.getRecieveCoin().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_RECIEVE_COIN_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_RECIEVE_COIN_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_RECIEVE_COIN_FAILURE, error };
  }
}

function getAddressDepositTime(data, temp) {
  return (dispatch) => {
    dispatch(request());
    userService.getAddressDepositTime(data).then(
      (users) => {
        console.log("usersusersusersusersusers", users);
        dispatch(success(users));
        dispatch(dashboardActions.getCoinList(temp));
        // dispatch(userActions.MsgListTicketById({ ticketId: data.ticketId}));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_ADDRESS_DEPOSIT_TIME_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ADDRESS_DEPOSIT_TIME_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ADDRESS_DEPOSIT_TIME_FAILURE, error };
  }
}

function getBptDetailsForUser(data, temp) {
  return (dispatch) => {
    dispatch(request());
    userService.getBptDetailsForUser(data).then(
      (users) => {
        console.log("usersusersusersusersusers", users);
        dispatch(success(users));
        // dispatch(userActions.MsgListTicketById({ ticketId: data.ticketId}));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_BPT_DETAIS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_BPT_DETAIS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_BPT_DETAIS_FAILURE, error };
  }
}

function getbtcAmountByAddress(data, temp) {
  return (dispatch) => {
    dispatch(request());
    userService.getbtcAmountByAddress(data).then(
      (users) => {
        console.log("usersusersusersusersusers", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_BTC_AMOUNT_BY_ADDRESS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_BTC_AMOUNT_BY_ADDRESS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_BTC_AMOUNT_BY_ADDRESS_FAILURE, error };
  }
}

function getCoinByName(data, temp) {
  return (dispatch) => {
    dispatch(request());
    userService.getCoinByName(data).then(
      (users) => {
        console.log("usersusersusersusersusers", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_COIN_BY_NAME_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_COIN_BY_NAME_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_COIN_BY_NAME_FAILURE, error };
  }
}

function replyMsgTicket(data) {
  return (dispatch) => {
    dispatch(request());
    userService.replyMsgTicket(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(userActions.MsgListTicketById({ ticketId: data.ticketId }));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.REPLY_TICKET_BY_USER__REQUEST };
  }
  function success(users) {
    return { type: userConstants.REPLY_TICKET_BY_USER__SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.REPLY_TICKET_BY_USER__FAILURE, error };
  }
}

function MsgListTicketById(data) {
  console.log("datadatadatadatadatadata", data);
  return (dispatch) => {
    dispatch(request());
    userService.MsgListTicketById(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_TICKET_BY_ID_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_TICKET_BY_ID_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_TICKET_BY_ID_LIST_FAILURE, error };
  }
}

function getLevelByIdUserTx(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getLevelByIdUserTx(data).then(
      (users) => {
        dispatch(success(users));
        // dispatch(userActions.getUserDetails());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_TX_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_TX_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_TX_FAILURE, error };
  }
}

function getLevelByIdUserPackage(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getLevelByIdUserPackage(data).then(
      (users) => {
        dispatch(success(users));
        // dispatch(userActions.getUserDetails());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_PACKAGE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_PACKAGE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_PACKAGE_FAILURE, error };
  }
}

function getLevelByIdUser(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getLevelByIdUser(data).then(
      (users) => {
        dispatch(success(users));
        // dispatch(userActions.getUserDetails());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_LEVEL_BY_ID_USER_FAILURE, error };
  }
}

function getAllLevelMining(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getAllLevelMining(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(userActions.getUserDetails());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_ALL_LEVEL_MINING_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ALL_LEVEL_MINING_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_LEVEL_MINING_FAILURE, error };
  }
}
function getAllLevelWelcome(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getAllLevelWelcome(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(userActions.getUserDetails());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_ALL_LEVEL_WELCOME_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ALL_LEVEL_WELCOME_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_LEVEL_WELCOME_FAILURE, error };
  }
}

function getCmcCoinList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getCmcCoinList(data).then(
      (users) => {
        // console.log("userssssssssssssssss_____", users)
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_COIN_CMC_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_COIN_CMC_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_COIN_CMC_FAILURE, error };
  }
}

function claimAddress(data) {
  let reqData = {
    txType: "WITHDRAW",
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    userService
      .claimAddress(data)

      .then(
        (users) => {
          let message =
            users.claimAddress && users.claimAddress.message
              ? users.claimAddress.message
              : "NA";
          dispatch(success(users));
          dispatch(alertActions.success(message));
          dispatch(this.getBTCTxList(reqData));
          dispatch(this.getDftWallet());
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.CLAIM_ADDRESS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CLAIM_ADDRESS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CLAIM_ADDRESS_FAILURE, error };
  }
}

function getUserByRefCode(data) {
  // console.log("127.0.0.1::", data);
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getUserByRefCode(data).then(
      (user) => {
        // console.log("ACTION__________getUserByRefCode_______::", user);
        dispatch(success(user));

        // dispatch(alertActions.success(user.getUserByRefCode.message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.GET_USER_BY_REFCODE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_USER_BY_REFCODE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_BY_REFCODE_FAILURE, error };
  }
}

function createMakePayment(data) {
  let temp = {
    keyWord: "",
    pageNo: 1,
    size: 10,
    paymentType: "",
  };
  return (dispatch) => {
    dispatch(request());
    userService.createMakePayment(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(alertActions.success("Success"));
        dispatch(this.uploadImageClear());
        dispatch(this.getMakePaymentList(temp));
        window.location.reload();
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.CREATE_MAKE_PAYMENT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CREATE_MAKE_PAYMENT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CREATE_MAKE_PAYMENT_FAILURE, error };
  }
}

function getAllCoinIndexByIndexId(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getAllCoinIndexByIndexId(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_ALL_COIN_INDEX_BY_INDEX_ID_REQUEST };
  }
  function success(users) {
    return {
      type: userConstants.GET_ALL_COIN_INDEX_BY_INDEX_ID_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_ALL_COIN_INDEX_BY_INDEX_ID_FAILURE,
      error,
    };
  }
}

function getUserWalletByCoinName(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getUserWalletByCoinName(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_USER_WALLET_BY_COIN_NAME_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_USER_WALLET_BY_COIN_NAME_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_WALLET_BY_COIN_NAME_FAILURE, error };
  }
}

function getReferalById(data) {
  // console.log("Action in getReferalById :", data);
  return (dispatch) => {
    dispatch(request());
    userService
      .getReferalById(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_REFERAL_BY_ID_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_REFERAL_BY_ID_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_REFERAL_BY_ID_FAILURE, error };
  }
}
function getReferralCountAndSumById(data) {
  // console.log("Action in getReferralCountAndSumById :", data);
  return (dispatch) => {
    dispatch(request());
    userService
      .getReferralCountAndSumById(data)

      .then(
        (users) => {
          dispatch(success(users));
          console.log("action___________", users);
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_REFERAL_COUNT_AND_SUM_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_REFERAL_COUNT_AND_SUM_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_REFERAL_COUNT_AND_SUM_FAILURE, error };
  }
}

function getDocumentListObj(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getDocumentListObj(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_DOCUMENT_LIST_OBJ_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_DOCUMENT_LIST_OBJ_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_DOCUMENT_LIST_OBJ_FAILURE, error };
  }
}

function getIndexOrder() {
  return (dispatch) => {
    dispatch(request());
    userService
      .getIndexOrder()

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_INDEX_ORDER_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_INDEX_ORDER_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_INDEX_ORDER_FAILURE, error };
  }
}

function getCoinBalance() {
  return (dispatch) => {
    dispatch(request());
    userService
      .getCoinBalance()

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_COIN_BALANCE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_COIN_BALANCE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_COIN_BALANCE_FAILURE, error };
  }
}

function getAllIndex() {
  return (dispatch) => {
    dispatch(request());
    userService
      .getAllIndex()

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_ALL_INDEX_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ALL_INDEX_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_INDEX_FAILURE, error };
  }
}

function saveSTAKE(reqData, temp) {
  return (dispatch) => {
    dispatch(request({ reqData }));
    userService.saveSTAKE(reqData).then(
      (users) => {
        dispatch(success(users));
        dispatch(this.getSTAKE(temp));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_STAKE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_STAKE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_STAKE_FAILURE, error };
  }
}
function saveBTST(data, temp) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.saveBTST(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(this.getBTST(temp));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_BTST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_BTST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_BTST_FAILURE, error };
  }
}
function getUserNameVerified(data, temp) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getUserNameVerified(data).then(
      (users) => {
        dispatch(success(users));
        // dispatch(this.getBTST(temp));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_USER_NAME_VERIFIED_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_USER_NAME_VERIFIED_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_NAME_VERIFIED_FAILURE, error };
  }
}

function getUserInfo(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getUserInfo(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(this.getUserDetails());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_USER_INFO_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_USER_INFO_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_INFO_FAILURE, error };
  }
}

function updatePassword(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.updatePassword(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(alertActions.success(users.userinfo.message));
        dispatch(this.getBTCAccountList());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.UPDATE_PASSWORD_REQUEST };
  }
  function success(users) {
    return { type: userConstants.UPDATE_PASSWORD_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_PASSWORD_FAILURE, error };
  }
}

function changeEmail(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.changeEmail(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(alertActions.success(users.changeEmail.message));
        dispatch(this.getUserDetails());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_EMAIL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CHANGE_EMAIL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_EMAIL_FAILURE, error };
  }
}

function login(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.login(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user.userinfo.message));
        props.history.push(`/app/dashboard`)
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.LOGIN_FIRST_REQUEST };
  }
  function success(user) {
    return { type: userConstants.LOGIN_FIRST_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FIRST_FAILURE, error };
  }
}

function updatePasswordValidateOtp(data, props) {
  // console.log("updatePasswordValidateOtp________ACTION::", data);
  return (dispatch) => {
    dispatch(request({ data }));
    userService.updatePasswordValidateOtp(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user.userinfo.message));
        dispatch(this.getUserDetails());
        // props.history.push(`/app/dashboard`)
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_FAILURE, error };
  }
}

function validateLoginOtp(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.validateLoginOtp(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user.userinfo.message));
        props.history.push(`/app/dashboard`);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.VALIDATE_LOGIN_OTP_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.VALIDATE_LOGIN_OTP_SUCCESS, user };
  }
  function failure(user) {
    return { type: userConstants.VALIDATE_LOGIN_OTP_FAILURE, user };
  }
}
function loginValidateOtp(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.loginValidateOtp(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user.userinfo.message));
        props.history.push(`/app/dashboard`);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}
function getUserListByEmail(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.getUserListByEmail(data).then(
      (user) => {
        // console.log("ACTION____________getUserListByEmail:::", user);
        dispatch(success(user));
        // dispatch(alertActions.success(user.userinfo.message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.GET_USER_LIST_BY_EMAIL_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_USER_LIST_BY_EMAIL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_LIST_BY_EMAIL_FAILURE, error };
  }
}
function createNotification(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.createNotification(data).then(
      (user) => {
        let message =
          user.userinfo && user.userinfo.message ? user.userinfo.message : "NA";

        dispatch(success(user));
        dispatch(alertActions.success(message));
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.CREATE_NOTIFICATION_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.CREATE_NOTIFICATION_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.CREATE_NOTIFICATION_FAILURE, error };
  }
}
function recoverEmail(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.recoverEmail(data).then(
      (user) => {
        console.log("user_______", user);
        dispatch(success(user));

        dispatch(alertActions.success(user.userinfo.message));
        props.history.push('/recoverQuestions')
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.RECOVER_EMAIL_REQUEST };
  }
  function success(user) {
    return { type: userConstants.RECOVER_EMAIL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RECOVER_EMAIL_FAILURE, error };
  }
}
function forgotPassword(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.forgotPassword(data).then(
      (user) => {
        console.log("user_______", user);
        dispatch(success(user));

        dispatch(alertActions.success(user.userinfo.message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.FORGOT_FIRST_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.FORGOT_FIRST_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_FIRST_FAILURE, error };
  }
}
function register(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.register(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user.userinfo.message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_FIRST_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_FIRST_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FIRST_FAILURE, error };
  }
}
function sellIndex(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.sellIndex(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(this.getAllIndex());
        dispatch(this.getCoinBalance());
        dispatch(this.getIndexOrder());
        dispatch(alertActions.success(user.sellIndex.message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.SELL_INDEX_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.SELL_INDEX_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SELL_INDEX_FAILURE, error };
  }
}
function buyIndex(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.buyIndex(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(this.getAllIndex());
        dispatch(alertActions.success(user.buyIndex.message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.BUY_INDEX_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.BUY_INDEX_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.BUY_INDEX_FAILURE, error };
  }
}
function sendBalance(data) {
  let temp = {
    txType: ["SEND", "RECEIVED"],
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request({ data }));
    userService.sendBalance(data).then(
      (users) => {
        let message =
          users.sendBalance && users.sendBalance.message
            ? users.sendBalance.message
            : "NA";
        dispatch(success(users));
        dispatch(this.getUserDetails());
        dispatch(this.getTxAllMulti(temp));
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.SEND_BALANCE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.SEND_BALANCE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SEND_BALANCE_FAILURE, error };
  }
}
function sendCoin(data) {
  let temp = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request({ data }));
    userService.sendCoin(data).then(
      (users) => {
        let message =
          users.sendCoin && users.sendCoin.message
            ? users.sendCoin.message
            : "NA";
        dispatch(success(users));
        // dispatch(this.getUserDetails());
        // dispatch(this.getRecieveCoin());
        dispatch(this.getBTCTxList(temp));
        // dispatch(dashboardActions.getCoinList(temp));
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.SEND_COIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.SEND_COIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SEND_COIN_FAILURE, error };
  }
}
function recoverEmailVerifyOtp(data, props) {
  console.log('data_________________', data);
  return (dispatch) => {
    dispatch(request({ data }));
    userService.recoverEmailVerifyOtp(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user.userinfo.message));
        props.history.push(`/app/dashboard`);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.RECOVER_EMAIL_VERIFY_OTP_REQUEST };
  }
  function success(user) {
    return { type: userConstants.RECOVER_EMAIL_VERIFY_OTP_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RECOVER_EMAIL_VERIFY_OTP_FAILURE, error };
  }
}
function registerValidateOtp(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.registerValidateOtp(data).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user.userinfo.message));
        props.history.push(`/questions`);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  return (dispatch) => {
    dispatch(dashboardActions.resetDashboard());
    dispatch(this.resetUser());
    dispatch(this.logout1());
  };
}

function logout1() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function forgotUpdatePassword(data, props) {
  return (dispatch) => {
    dispatch(request({ data }));
    userService.forgotUpdatePassword(data).then(
      (user) => {
        dispatch(success(user));

        if (user.userinfo.message === "Invalid OTP.") {
          dispatch(alertActions.error(user.userinfo.message));
        } else {
          dispatch(alertActions.success(user.userinfo.message));
          props.history.push(`/login`);
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.FORGOT_PASS_UPDATE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.FORGOT_PASS_UPDATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_PASS_UPDATE_FAILURE, error };
  }
}

function getMainPTwoMissCombList(data) {
  // // console.log("data m kya aa rha h::action:::", data);
  return (dispatch) => {
    dispatch(request());
    userService.getMainPTwoMissCombList(data).then(
      (users) => {
        // // console.log("$$$$$$$$$$$ getMainPTwoMissCombList $$$$ action:", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_MAIN_PASS_PHRASE_COMB_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MAIN_PASS_PHRASE_COMB_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MAIN_PASS_PHRASE_COMB_FAILURE, error };
  }
}

function getMainPassphreaseList(data) {
  // // console.log("data m kya aa rha h::action:::", data);
  return (dispatch) => {
    dispatch(request());
    userService.getMainPassphreaseList(data).then(
      (users) => {
        // // console.log("$$$$$$$$$$$ getMainPassphreaseList $$$$ action:", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_MAIN_PASS_PHRASE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MAIN_PASS_PHRASE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MAIN_PASS_PHRASE_FAILURE, error };
  }
}
function addNotesInEVMAccount(data) {
  // // console.log("data m kya aa rha h::action:::", data);
  return (dispatch) => {
    dispatch(request());
    userService.addNotesInEVMAccount(data).then(
      (users) => {
        // // console.log("$$$$$$$$$$$ addNotesInEVMAccount $$$$ action:", users);
        dispatch(success(users));
        dispatch(this.getEVMAccountList());
        // dispatch(this.getUserDetails());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.ADD_NOTES_IN_EVM_ACCOUNT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.ADD_NOTES_IN_EVM_ACCOUNT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.ADD_NOTES_IN_EVM_ACCOUNT_FAILURE, error };
  }
}

function addNotesInBtcAccount(data) {
  // // console.log("data m kya aa rha h::action:::", data);
  return (dispatch) => {
    dispatch(request());
    userService.addNotesInBtcAccount(data).then(
      (users) => {
        // // console.log("$$$$$$$$$$$ addNotesInBtcAccount $$$$ action:", users);
        dispatch(success(users));
        dispatch(this.getBTCAccountList());
        // dispatch(this.getUserDetails());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.ADD_NOTES_IN_BTC_ACCOUNT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.ADD_NOTES_IN_BTC_ACCOUNT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.ADD_NOTES_IN_BTC_ACCOUNT_FAILURE, error };
  }
}

function createWallet(data) {
  // // console.log("data m kya aa rha h::action:::", data);
  return (dispatch) => {
    dispatch(request());
    userService.createWallet(data).then(
      (users) => {
        // // console.log("$$$$$$$$$$$ createWallet $$$$ action:", users);
        dispatch(success(users));
        dispatch(this.getBTCAccountList());
        dispatch(this.getUserDetails());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.CREATE_WALLET_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CREATE_WALLET_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CREATE_WALLET_FAILURE, error };
  }
}

function getEVMAccountList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getEVMAccountList(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_EVM_ACCOUNT_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_EVM_ACCOUNT_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_EVM_ACCOUNT_LIST_FAILURE, error };
  }
}

function getBTCAccountList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getBTCAccountList(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_BTC_ACCOUNT_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_BTC_ACCOUNT_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_BTC_ACCOUNT_LIST_FAILURE, error };
  }
}

function getAllCoins() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllCoins().then(
      (users) => {
        // console.log("ACTION__getUserDetails___getUserDetails::", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_ALL_COIN_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ALL_COIN_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_COIN_FAILURE, error };
  }
}

function coinSwap(data, props) {
  console.log("datadatadatadatadatadatadata", data);

  return (dispatch) => {
    dispatch(request());
    userService.coinSwap(data).then(
      (users) => {
        // console.log("ACTION__getUserDetails___getUserDetails::", users);
        dispatch(success(users));
        dispatch(alertActions.success("Successfully swap coin"));
        props.history.push(`/app/swapcoin`);
        // window.location.reload();
        dispatch(this.getUserDetails());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
        props.history.push(`/app/swapcoin`);
      }
    );
  };
  function request() {
    return { type: userConstants.COIN_SWAP_REQUEST };
  }
  function success(users) {
    return { type: userConstants.COIN_SWAP_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.COIN_SWAP_FAILURE, error };
  }
}

function getAllCoinsRemoveId(data) {
  console.log("datadatadatadatadatadatadata", data);

  return (dispatch) => {
    dispatch(request());
    userService.getAllCoinsRemoveId(data).then(
      (users) => {
        // console.log("ACTION__getUserDetails___getUserDetails::", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_ALL_COIN_REMOVE_ID_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ALL_COIN_REMOVE_ID_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_COIN_REMOVE_ID_FAILURE, error };
  }
}

function getUserDetails() {
  return (dispatch) => {
    dispatch(request());
    userService.getUserDetails().then(
      (users) => {
        // console.log("ACTION__getUserDetails___getUserDetails::", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.USER_DETAILS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.USER_DETAILS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.USER_DETAILS_FAILURE, error };
  }
}
function getSocialMediaById() {
  return (dispatch) => {
    dispatch(request());
    userService.getSocialMediaById().then(
      (users) => {
        // console.log("ACTION__getUserDetails___getUserDetails::", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SOCIAL_DETAILS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SOCIAL_DETAILS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SOCIAL_DETAILS_FAILURE, error };
  }
}

function getProfileDataPi() {
  return (dispatch) => {
    dispatch(request());
    userService.getProfileDataPi().then(
      (users) => {
        // console.log("ACTION__getProfileDataPi___getProfileDataPi::", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.PROFILE_PI_REQUEST };
  }
  function success(users) {
    return { type: userConstants.PROFILE_PI_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_PI_FAILURE, error };
  }
}
function getM7iCoin() {
  return (dispatch) => {
    dispatch(request());
    userService.getM7iCoin().then(
      (users) => {
        // console.log("ACTION__getM7iCoin___getM7iCoin::", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_M7I_COIN_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_M7I_COIN_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_M7I_COIN_FAILURE, error };
  }
}
function getCmcCoinByTickerName() {
  return (dispatch) => {
    dispatch(request());
    userService.getCmcCoinByTickerName().then(
      (users) => {
        // console.log("ACTION__getCmcCoinByTickerName___getCmcCoinByTickerName::", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.CMC_COIN_BY_TICKER_NAME_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CMC_COIN_BY_TICKER_NAME_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CMC_COIN_BY_TICKER_NAME_FAILURE, error };
  }
}

function updateUserInfo(data) {
  return (dispatch) => {
    dispatch(request());
    userService.updateUserInfo(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(alertActions.success(users.updateUserInfo.message));
        dispatch(this.getUserDetails());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.UPDATE_USER_DETAILS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.UPDATE_USER_DETAILS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_DETAILS_FAILURE, error };
  }
}

function getPrice(priceData) {
  return (dispatch) => {
    dispatch(request());
    userService.getPrice(priceData).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.PRICE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.PRICE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.PRICE_FAILURE, error };
  }
}

function swapAmount(priceData) {
  let temp = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    userService.swapAmount(priceData).then(
      (users) => {
        let message =
          users.swapAmount && users.swapAmount.message
            ? users.swapAmount.message
            : "NA";
        dispatch(success(users));
        dispatch(this.getUserDetails());
        dispatch(this.getBTCTxList(temp));
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SWAP_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SWAP_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SWAP_FAILURE, error };
  }
}

function getDocumentList() {
  return (dispatch) => {
    dispatch(request());
    userService.getDocumentList().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.USER_DOC_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.USER_DOC_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.USER_DOC_LIST_FAILURE, error };
  }
}
function getNotificationListForUser(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getNotificationListForUser(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.NOTIFICATION_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.NOTIFICATION_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.NOTIFICATION_LIST_FAILURE, error };
  }
}
function getCitiesList(req) {
  return (dispatch) => {
    dispatch(request());
    userService.getCitiesList(req).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.CITY_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CITY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CITY_LIST_FAILURE, error };
  }
}
function getStatesList(req) {
  return (dispatch) => {
    dispatch(request());
    userService.getStatesList(req).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.STATES_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.STATES_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.STATES_LIST_FAILURE, error };
  }
}

function getAllCountry() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllCountry().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_ALL_COUNTRY_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ALL_COUNTRY_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_COUNTRY_FAILURE, error };
  }
}

function getAllState() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllState().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_ALL_STATE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ALL_STATE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_STATE_FAILURE, error };
  }
}

function getAllCity() {
  return (dispatch) => {
    dispatch(request());
    userService.getAllCity().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_ALL_CITY_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ALL_CITY_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_CITY_FAILURE, error };
  }
}

function getKYC() {
  return (dispatch) => {
    dispatch(request());
    userService.getKYC().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        // dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_KYC_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_KYC_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_KYC_FAILURE, error };
  }
}

function saveUserInfo(data) {
  console.log("datadatadatadatadatadatadatadata", data);
  return (dispatch) => {
    dispatch(request());
    userService.saveUserInfo(data).then(
      (users) => {
        console.log("usersusersusersusersusers", users);
        dispatch(success(users));
        dispatch(alertActions.success("Success"));
        dispatch(this.getUserInfo());
        dispatch(this.uploadImageClear());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_USER_INFO_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_USER_INFO_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_USER_INFO_FAILURE, error };
  }
}

function saveUserPersonalAddr(data) {
  return (dispatch) => {
    dispatch(request());
    userService.saveUserPersonalAddr(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(alertActions.success("Success"));
        dispatch(this.getUserInfo());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_USER_PERSONAL_ADDR_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_USER_PERSONAL_ADDR_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_USER_PERSONAL_ADDR_FAILURE, error };
  }
}

function saveSocialMedia(data) {
  return (dispatch) => {
    dispatch(request());
    userService.saveSocialMedia(data).then(
      (users) => {
        // dispatch(this.getKYC());
        // dispatch(this.uploadImageClear());
        // dispatch(this.uploadImageClearSelfie());
        dispatch(success(users));
        dispatch(alertActions.success("Success"));
        dispatch(this.getSocialMediaById());
        dispatch(this.getUserInfo());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_SOCIAL_MEDIA_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_SOCIAL_MEDIA_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_SOCIAL_MEDIA_FAILURE, error };
  }
}

function saveKycDocAadhar(data) {
  return (dispatch) => {
    dispatch(request());
    userService.saveKycDocAadhar(data).then(
      (users) => {
        // dispatch(this.uploadImageClearAdhaar());

        dispatch(success(users));
        dispatch(alertActions.success("Success"));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_KYC_DOC_AADHAR_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_KYC_DOC_AADHAR_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_KYC_DOC_AADHAR_FAILURE, error };
  }
}

function saveKycDocPassport(data) {
  return (dispatch) => {
    dispatch(request());
    userService.saveKycDocPassport(data).then(
      (users) => {
        // dispatch(this.uploadImageClearPassport());

        dispatch(success(users));
        dispatch(alertActions.success("Success"));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_KYC_DOC_PASSPORT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_KYC_DOC_PASSPORT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_KYC_DOC_PASSPORT_FAILURE, error };
  }
}

function saveKycDocSelfie(data) {
  // console.log("Action____saveKycDocSelfie____data::", data);

  return (dispatch) => {
    dispatch(request());
    userService.saveKycDocSelfie(data).then(
      (users) => {
        // dispatch(this.uploadImageClearSelfie());

        dispatch(success(users));
        this.createKYC();
        dispatch(alertActions.success("Success"));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_KYC_DOC_SELFIE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_KYC_DOC_SELFIE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_KYC_DOC_SELFIE_FAILURE, error };
  }
}

function saveKycDocPan(data) {
  return (dispatch) => {
    dispatch(request());
    userService.saveKycDocPan(data).then(
      (users) => {
        // dispatch(this.uploadImageClearPan());

        dispatch(success(users));
        // dispatch(alertActions.success("Success"));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_KYC_DOC_PAN_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_KYC_DOC_PAN_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_KYC_DOC_PAN_FAILURE, error };
  }
}

function saveKycDoc(data) {
  return (dispatch) => {
    dispatch(request());
    userService.saveKycDoc(data).then(
      (users) => {
        // dispatch(this.getKYC());
        // dispatch(this.uploadImageClear());
        // dispatch(this.uploadImageClearSelfie());
        dispatch(success(users));
        dispatch(alertActions.success("Success"));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SAVE_KYC_DOC_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_KYC_DOC_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_KYC_DOC_FAILURE, error };
  }
}

function createKYC() {
  return (dispatch) => {
    dispatch(request());
    userService.createKYC().then(
      (users) => {
        dispatch(this.getKYC());
        dispatch(this.uploadImageClear());
        dispatch(this.uploadImageClearSelfie());
        dispatch(success(users));
        dispatch(alertActions.success("Success"));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.USER_KYC_CREATE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.USER_KYC_CREATE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.USER_KYC_CREATE_FAILURE, error };
  }
}

function uploadImagePassport(data) {
  return (dispatch) => {
    userService.uploadImagePassport(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(alertActions.error("File size should not more than 1 mb"));
        dispatch(failure(error));
      }
    );
  };
  function success(users) {
    return { type: userConstants.FILE_UPLOAD_STATUS_PASSPORT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.FILE_UPLOAD_STATUS_PASSPORT_FAILURE, error };
  }
}

function uploadImagePan(data) {
  console.log('data_____________________response', data);
  return (dispatch) => {
    userService.uploadImagePan(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          // dispatch(alertActions.error(error));
          dispatch(alertActions.success("Image upload successfully"));
          dispatch(alertActions.error("File size should not more than 1 mb"));
          dispatch(failure(error));
        }
      );
  };
  function success(users) {
    return { type: userConstants.FILE_UPLOAD_STATUS_PAN_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.FILE_UPLOAD_STATUS_PAN_FAILURE, error };
  }
}

function uploadImageAdhaarBack(data) {
  return (dispatch) => {
    userService.uploadImage(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(alertActions.error("File size should not more than 1 mb"));
        dispatch(failure(error));
      }
    );
  };
  function success(users) {
    return {
      type: userConstants.FILE_UPLOAD_STATUS_ADHAAR_BACK_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: userConstants.FILE_UPLOAD_STATUS_ADHAAR_BACK_FAILURE,
      error,
    };
  }
}

function uploadImageAdhaar(data) {
  return (dispatch) => {
    userService.uploadImageAdhaar(data).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        // dispatch(alertActions.error(error));
        dispatch(alertActions.error("File size should not more than 1 mb"));
        dispatch(failure(error));
      }
    );
  };
  function success(users) {
    return { type: userConstants.FILE_UPLOAD_STATUS_ADHAAR_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.FILE_UPLOAD_STATUS_ADHAAR_FAILURE, error };
  }
}

function uploadImage(data) {
  return (dispatch) => {
    userService.uploadImage(data).then(
      (users) => {
        console.log("usersusersusersusersusers", users);
        dispatch(success(users));
        dispatch(alertActions.success("Image upload successfully"));
      },
      (error) => {
        // dispatch(alertActions.error(error));
        dispatch(alertActions.error("File size should not more than 1 mb"));
        dispatch(failure(error));
      }
    );
  };
  function success(users) {
    return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error };
  }
}

function uploadImageSelfie(data) {
  return (dispatch) => {
    userService.uploadImage(data).then(
      (users) => {
        // console.log("Action1312 uploadImageSelfie:", users);

        dispatch(success(users));
      },
      (error) => {
        // dispatch(alertActions.error(error));
        dispatch(alertActions.error("File size should not more than 1 mb"));
        dispatch(failure(error));
      }
    );
  };
  function success(users) {
    return { type: userConstants.FILE_UPLOAD_SELFIE_STATUS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.FILE_UPLOAD_SELFIE_STATUS_FAILURE, error };
  }
}

function treeBptImageUpload(data) {


  return (dispatch) => {
    dispatch(request());
    userService.treeBptImageUpload(data).then(
      (users) => {
        dispatch(success(users));
        // dispatch(this.getTicketList(tempdata));
        dispatch(alertActions.success("Submit Successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.USER_TICKET_CREATE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.USER_TICKET_CREATE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.USER_TICKET_CREATE_FAILURE, error };
  }
}

function getMakePaymentList(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getMakePaymentList(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_MAKE_PAYMENT_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MAKE_PAYMENT_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MAKE_PAYMENT_LIST_FAILURE, error };
  }
}

function voiceBptImageUpload(data, resData) {
  return (dispatch) => {
    dispatch(request());
    userService
      .voiceBptImageUpload(data)

      .then(
        (users) => {
          dispatch(success(users));
          dispatch(userActions.getBptDetailsForUser(resData));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.ADD_IMAGE_BPT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.ADD_IMAGE_BPT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.ADD_IMAGE_BPT_FAILURE, error };
  }
}

function getTicketList(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getTicketList(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.USER_TICKET_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.USER_TICKET_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.USER_TICKET_LIST_FAILURE, error };
  }
}

function getINRCoinId(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getINRCoinId(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_INR_COIN_ID_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_INR_COIN_ID_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_INR_COIN_ID_FAILURE, error };
  }
}

function getEVMTxList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getEVMTxList(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.EVM_TX_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVM_TX_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVM_TX_LIST_FAILURE, error };
  }
}

function getTxListByAddresss(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getTxListByAddresss(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.EVM_TX_LIST_BY_ADDRESS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.EVM_TX_LIST_BY_ADDRESS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.EVM_TX_LIST_BY_ADDRESS_FAILURE, error };
  }
}

function getValidBtcPassPhrases(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidBtcPassPhrases(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_BTC_PASS_PHRASES_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_BTC_PASS_PHRASES_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_BTC_PASS_PHRASES_FAILURE, error };
  }
}

function getValidBNBPassPhrases(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidBNBPassPhrases(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_BNB_PASS_PHRASES_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_BNB_PASS_PHRASES_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_BNB_PASS_PHRASES_FAILURE, error };
  }
}

function getValidETHPassPhrases(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidETHPassPhrases(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_ETH_PASS_PHRASES_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_ETH_PASS_PHRASES_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_ETH_PASS_PHRASES_FAILURE, error };
  }
}

function getValidSegWitPassPhrases(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidSegWitPassPhrases(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_SEG_WIT_PASS_PHRASES_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_SEG_WIT_PASS_PHRASES_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_SEG_WIT_PASS_PHRASES_FAILURE, error };
  }
}

function getValidLegacyPassPhrases(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidLegacyPassPhrases(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_LEGACY_PASS_PHRASES_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_LEGACY_PASS_PHRASES_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_LEGACY_PASS_PHRASES_FAILURE, error };
  }
}

function getValidNativeSegWitPassPhrases(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidNativeSegWitPassPhrases(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_FAILURE, error };
  }
}


function saveMainPassphrase(data, pageNation) {
  return (dispatch) => {
    dispatch(request());
    userService.saveMainPassphrase(data)
      .then(
        (users) => {
          console.log('users_________________users', users);
          dispatch(success(users?.saveMainPassphrase?.message ?? 'Success.'));
          dispatch(this.getMainPassphreaseList(pageNation));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.SAVE_MAIN_PASS_PHRASE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_MAIN_PASS_PHRASE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_MAIN_PASS_PHRASE_FAILURE, error };
  }
}

function getValidPassPhrasesWithAddress(data, pageNation) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidPassPhrasesWithAddress(data)
      .then(
        (users) => {
          console.log('users_________________users', users);
          dispatch(success(users?.getValidPassPhrasesWithAddress?.message ?? 'Success.'));
          dispatch(this.getValidPassPhrasesWithAddressList(pageNation));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_FAILURE, error };
  }
}

function getValidPassPhrasesWithAddressAllCombination(data, pageNation) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidPassPhrasesWithAddressAllCombination(data)
      .then(
        (users) => {
          console.log('users_________________users', users);
          dispatch(success(users?.getValidPassPhrasesWithAddressAllCombination?.message ?? 'Success.'));
          dispatch(this.getValidPassPhrasesAllCombWithAddressList(pageNation));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_FAILURE, error };
  }
}
function getMainPTwoMissCombBalanceById(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getMainPTwoMissCombBalanceById(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_FAILURE, error };
  }
}
function getAddressBalanceById(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getAddressBalanceById(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_ADDRESS_BALANCE_BY_ID_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ADDRESS_BALANCE_BY_ID_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ADDRESS_BALANCE_BY_ID_FAILURE, error };
  }
}
function showPassPhrasesProgressBar(data) {
  return (dispatch) => {
    dispatch(request());
    userService.showPassPhrasesProgressBar(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.SHOW_PASS_PHRASES_PROGRESS_BAR_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SHOW_PASS_PHRASES_PROGRESS_BAR_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SHOW_PASS_PHRASES_PROGRESS_BAR_FAILURE, error };
  }
}
function showMissingAllCombBalanceBar(data) {
  return (dispatch) => {
    dispatch(request());
    userService.showMissingAllCombBalanceBar(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.SHOW_MISSING_ALL_COMB_BALANCE_BAR_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SHOW_MISSING_ALL_COMB_BALANCE_BAR_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SHOW_MISSING_ALL_COMB_BALANCE_BAR_FAILURE, error };
  }
}
function showAllComPassPhrasesProgressBar(data) {
  return (dispatch) => {
    dispatch(request());
    userService.showAllComPassPhrasesProgressBar(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_FAILURE, error };
  }
}
function showMainPassPhrasesProgressBar(data) {
  return (dispatch) => {
    dispatch(request());
    userService.showMainPassPhrasesProgressBar(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_FAILURE, error };
  }
}
function showMainPhrasesProgressCombBar(data) {
  return (dispatch) => {
    dispatch(request());
    userService.showMainPhrasesProgressCombBar(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_FAILURE, error };
  }
}
function getMissingAllCombBalanceById(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getMissingAllCombBalanceById(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_MISSING_ALL_COMB_BALANCE_BY_ID_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MISSING_ALL_COMB_BALANCE_BY_ID_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MISSING_ALL_COMB_BALANCE_BY_ID_FAILURE, error };
  }
}
function getAddressBalanceByIdAllComb(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getAddressBalanceByIdAllComb(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_FAILURE, error };
  }
}
function getAddressDetailsById(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getAddressDetailsById(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_ADDRESS_DETAILS_BY_ID_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ADDRESS_DETAILS_BY_ID_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ADDRESS_DETAILS_BY_ID_FAILURE, error };
  }
}

function getValidPassPhrasesWithAddressList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidPassPhrasesWithAddressList(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_FAILURE, error };
  }
}

function getValidPassPhrasesAllCombWithAddressList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getValidPassPhrasesAllCombWithAddressList(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_FAILURE, error };
  }
}

function getMissingAllCombList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getMissingAllCombList(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_MISSING_ALL_COMB_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MISSING_ALL_COMB_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MISSING_ALL_COMB_LIST_FAILURE, error };
  }
}

function getBTCTxList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getBTCTxList(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.TX_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.TX_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.TX_LIST_FAILURE, error };
  }
}

function getTxList(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getTxList(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_TX_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_TX_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_TX_LIST_FAILURE, error };
  }
}

function bnbAmontFetchByEvmTx(data) {
  return (dispatch) => {
    dispatch(request());
    userService.bnbAmontFetchByEvmTx(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.BNB_AMOUNT_FETCH_BY_EVM_TX_REQUEST };
  }
  function success(users) {
    return { type: userConstants.BNB_AMOUNT_FETCH_BY_EVM_TX_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.BNB_AMOUNT_FETCH_BY_EVM_TX_FAILURE, error };
  }
}

function btcAmount(data) {
  return (dispatch) => {
    dispatch(request());
    userService.btcAmount(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.BTC_AMOUNT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.BTC_AMOUNT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.BTC_AMOUNT_FAILURE, error };
  }
}

function bnbAmont(data) {
  return (dispatch) => {
    dispatch(request());
    userService.bnbAmont(data)
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.BNB_AMOUNT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.BNB_AMOUNT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.BNB_AMOUNT_FAILURE, error };
  }
}

function getUserTxListOverFlow(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getUserTxListOverFlow(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.TX_LIST_OVERFLOW_REQUEST };
  }
  function success(users) {
    return { type: userConstants.TX_LIST_OVERFLOW_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.TX_LIST_OVERFLOW_FAILURE, error };
  }
}

function getUserWalletByUserId(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getUserWalletByUserId(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_USER_WALLET_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_USER_WALLET_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_WALLET_FAILURE, error };
  }
}

function getTxAllMulti(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getTxAllMulti(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.TX_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.TX_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.TX_LIST_FAILURE, error };
  }
}

function claimPromoPack() {
  return (dispatch) => {
    dispatch(request());
    userService
      .claimPromoPack()

      .then(
        (users) => {
          dispatch(success(users));
          dispatch(this.getUserPromoPackageByUserId());
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.TX_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.TX_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.TX_LIST_FAILURE, error };
  }
}

function getPackageList(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getPackageList(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_PACKAGE_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_PACKAGE_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_PACKAGE_LIST_FAILURE, error };
  }
}

function withdrawBalance(data) {
  let reqData = {
    txType: ["WITHDRAW", "DEPOSIT"],
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    userService
      .withdrawBalance(data)

      .then(
        (users) => {
          let message =
            users.withdrawBalance && users.withdrawBalance.message
              ? users.withdrawBalance.message
              : "NA";
          dispatch(success(users));
          dispatch(alertActions.success(message));
          dispatch(this.getTxAllMulti(reqData));
          dispatch(this.getDftWallet());
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.WITHDRAW_BALANCE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.WITHDRAW_BALANCE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.WITHDRAW_BALANCE_FAILURE, error };
  }
}

function saveDftWalletAddress(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .saveDftWalletAddress(data)

      .then(
        (users) => {
          let message =
            users.saveDftWalletAddress && users.saveDftWalletAddress.message
              ? users.saveDftWalletAddress.message
              : "NA";
          dispatch(success(users));
          dispatch(alertActions.success(message));
          dispatch(this.getDftWallet());
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.SAVE_DFT_WALLET_ADDRESS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SAVE_DFT_WALLET_ADDRESS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SAVE_DFT_WALLET_ADDRESS_FAILURE, error };
  }
}

function createUserPromoPackage(data) {
  return (dispatch) => {
    dispatch(request());
    userService.createUserPromoPackage(data).then(
      (users) => {
        let message =
          users.createUserPromoPackage && users.createUserPromoPackage.message
            ? users.createUserPromoPackage.message
            : "NA";
        dispatch(success(users));
        dispatch(alertActions.success(message));
        // dispatch(this.getDftWallet());
        dispatch(this.getUserPromoPackageByUserId());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.CREATE_USER_PROMO_PACKAGE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.CREATE_USER_PROMO_PACKAGE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.CREATE_USER_PROMO_PACKAGE_FAILURE, error };
  }
}

function getDftWallet() {
  return (dispatch) => {
    dispatch(request());
    userService
      .getDftWallet()

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_DFT_WALLET_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_DFT_WALLET_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_DFT_WALLET_FAILURE, error };
  }
}

function getUserPromoPackageByUserId() {
  return (dispatch) => {
    dispatch(request());
    userService
      .getUserPromoPackageByUserId()

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_USER_PROMO_PACKAGE_BY_USER_ID_REQUEST };
  }
  function success(users) {
    return {
      type: userConstants.GET_USER_PROMO_PACKAGE_BY_USER_ID_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_USER_PROMO_PACKAGE_BY_USER_ID_FAILURE,
      error,
    };
  }
}

// function getUserPackages() {
//     return dispatch => {
//         dispatch(request());
//         userService.getUserPackages()

//             .then(
//                 users => {
//                     dispatch(success(users));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.GET_USER_PACKAGES_REQUEST } }
//     function success(users) { return { type: userConstants.GET_USER_PACKAGES_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.GET_USER_PACKAGES_FAILURE, error } }
// }

function transactionSearch(data) {
  // console.log("11111111111111111111111111111111", data)

  return (dispatch) => {
    dispatch(request());
    userService
      .transactionSearch(data)

      .then(
        (users) => {
          dispatch(success(users));
          // // console.log("9999999999999999999999",this.props)
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.TX_LIST_BY_DATE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.TX_LIST_BY_DATE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.TX_LIST_BY_DATE_FAILURE, error };
  }
}
function getBTST(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getBTST(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_BTST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_BTST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_BTST_FAILURE, error };
  }
}
function getSTAKE(data) {
  return (dispatch) => {
    dispatch(request());
    userService.getSTAKE(data).then(
      (users) => {
        dispatch(success(users));
        // console.log("i am in action getSTAKE ", users);
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_STAKE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_STAKE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_STAKE_FAILURE, error };
  }
}
function getPackageBTST(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getPackageBTST(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_PACKAGE_BTST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_PACKAGE_BTST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_PACKAGE_BTST_FAILURE, error };
  }
}
function getPackageSTAKE(data) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getPackageSTAKE(data)

      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: userConstants.GET_PACKAGE_STAKE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_PACKAGE_STAKE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_PACKAGE_STAKE_FAILURE, error };
  }
}

function uploadImageClear() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(uploadImage) {
    return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage };
  }
}

function clearData() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(clear) {
    return { type: userConstants.DATA_CLEAR_SUCCESS, clear };
  }
}

function clearDataEVM() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(clear) {
    return { type: userConstants.DATA_CLEAR_EVM_SUCCESS, clear };
  }
}

function uploadImageClearPassport() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(uploadImage) {
    return {
      type: userConstants.FILE_UPLOAD_CLEAR_PASSPORT_SUCCESS,
      uploadImage,
    };
  }
}

function uploadImageClearAdhaar() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(uploadImage) {
    return {
      type: userConstants.FILE_UPLOAD_CLEAR_ADHAAR_SUCCESS,
      uploadImage,
    };
  }
}

function uploadImageClearAdhaarBack() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(uploadImage) {
    return {
      type: userConstants.FILE_UPLOAD_CLEAR_ADHAAR_BACK_SUCCESS,
      uploadImage,
    };
  }
}

function uploadImageClearPan() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(uploadImage) {
    return { type: userConstants.FILE_UPLOAD_CLEAR_PAN_SUCCESS, uploadImage };
  }
}

function uploadImageClearSelfie() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(uploadImage) {
    return {
      type: userConstants.FILE_UPLOAD_SELFIE_CLEAR_SUCCESS,
      uploadImage,
    };
  }
}

function resetUser() {
  return (dispatch) => {
    dispatch(success());
  };
  function success() {
    return { type: userConstants.RESET_USER_REDUX };
  }
}

function swapCoinAmount(data, data1, data2) {
  return (dispatch) => {
    dispatch(request());
    userService.swapCoinAmount(data).then(
      (users) => {
        dispatch(success(users));
        dispatch(this.getUserWalletByCoinName(data1));
        dispatch(this.getPrice(data2));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.SWAP_COIN_AMOUNT_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SWAP_COIN_AMOUNT_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SWAP_COIN_AMOUNT_FAILURE, error };
  }
}

function msgListClient(data) {
  console.log("datadatadatadatadatadatadatadatadatadatadatadatadatadata", data);
  return (dispatch) => {
    dispatch(request());
    userService.msgListClient(data).then(
      (users) => {
        console.log("usersusersusersusersusersusersusers", users);
        dispatch(success(users));
        // dispatch(this.getUserWalletByCoinName(data1));
        // dispatch(this.getPrice(data2));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_MESSAGE_CLIENT_LIST_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_MESSAGE_CLIENT_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_MESSAGE_CLIENT_LIST_FAILURE, error };
  }
}

//new Api
function upDateAction(data, data1, data2) {
  return (dispatch) => {
    dispatch(request());
    userService.upDateAction(data).then(
      (users) => {
        dispatch(success(users));
        // dispatch(this.getUserWalletByCoinName(data1));
        // dispatch(this.getPrice(data2));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_USER_BY_NEW_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_USER_BY_NEW_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_BY_NEW_FAILURE, error };
  }
}


//new for Two missingPass 
function removeMissAllCombMultipleDelete(data, paginationData) {
  return dispatch => {
    dispatch(request());
    userService.removeMissAllCombMultipleDelete(data)
      .then(
        users => {
          dispatch(success(users));
          dispatch(alertActions.success("Two Missing Pass Delete Successfully"));
          dispatch(this.getMissingAllCombList(paginationData));

        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_REQUEST } }
  function success(users) { return { type: userConstants.REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_SUCCESS, users } }
  function failure(error) { return { type: userConstants.REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_FAILURE, error } }
}

//new for Two missingPass 
function deleteTwoMissingPass(data, paginationData) {
  return dispatch => {
    dispatch(request());
    userService.deleteTwoMissingPasses(data)
      .then(
        users => {
          dispatch(success(users));
          dispatch(alertActions.success("Two Missing Pass Delete Successfully"));
          dispatch(this.getValidPassPhrasesAllCombWithAddressList(paginationData));

        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.DELETE_TWOMISSINGPASS_REQUEST } }
  function success(users) { return { type: userConstants.DELETE_TWOMISSINGPASS_SUCCESS, users } }
  function failure(error) { return { type: userConstants.DELETE_TWOMISSINGPASS_FAILURE, error } }
}


//new for Two missingPass 
function deleteMainPassphraseCombBalance(data, paginationData) {
  return dispatch => {
    dispatch(request());
    userService.deleteMainPassphraseCombBalance(data)
      .then(
        users => {
          dispatch(success(users));
          dispatch(alertActions.success("Delete Successfully"));
          dispatch(this.getMainPTwoMissCombBalanceById(paginationData));

        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.DELETE_MAIN_PASSPHRASE_COMB_BALANCE_REQUEST } }
  function success(users) { return { type: userConstants.DELETE_MAIN_PASSPHRASE_COMB_BALANCE_SUCCESS, users } }
  function failure(error) { return { type: userConstants.DELETE_MAIN_PASSPHRASE_COMB_BALANCE_FAILURE, error } }
}

//new for Two missingPass 
function deleteMainPassphraseComb(data, paginationData) {
  return dispatch => {
    dispatch(request());
    userService.deleteMainPassphraseComb(data)
      .then(
        users => {
          dispatch(success(users));
          dispatch(alertActions.success("Delete Successfully"));
          dispatch(this.getMainPTwoMissCombList(paginationData));

        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.DELETE_MAIN_PASSPHRASE_COMB_REQUEST } }
  function success(users) { return { type: userConstants.DELETE_MAIN_PASSPHRASE_COMB_SUCCESS, users } }
  function failure(error) { return { type: userConstants.DELETE_MAIN_PASSPHRASE_COMB_FAILURE, error } }
}

//new for Two missingPass 
function deleteMainPassphrase(data, paginationData) {
  return dispatch => {
    dispatch(request());
    userService.deleteMainPassphrase(data)
      .then(
        users => {
          dispatch(success(users));
          dispatch(alertActions.success("Delete Successfully"));
          dispatch(this.getMainPassphreaseList(paginationData));

        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.DELETE_MAIN_PASSPHRASE_REQUEST } }
  function success(users) { return { type: userConstants.DELETE_MAIN_PASSPHRASE_SUCCESS, users } }
  function failure(error) { return { type: userConstants.DELETE_MAIN_PASSPHRASE_FAILURE, error } }
}

function removePassphraseAllCombMultipleDelete(data, paginationData) {
  console.log('data___REQUEST_DATA???????', data);
  return dispatch => {
    dispatch(request());
    userService.removePassphraseAllCombMultipleDelete(data)
      .then(
        users => {
          dispatch(success(users));
          dispatch(alertActions.success("Multiple Delete Successfully"));
          dispatch(this.getValidPassPhrasesAllCombWithAddressList(paginationData));

        },
        error => {
          dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstants.PASSPHASE_ALLCOMB_MULTIPLE_DELETE_REQUEST } }
  function success(users) { return { type: userConstants.PASSPHASE_ALLCOMB_MULTIPLE_DELETE_SUCCESS, users } }
  function failure(error) { return { type: userConstants.PASSPHASE_ALLCOMB_MULTIPLE_DELETE_FAILURE, error } }
}

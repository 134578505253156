import React, { Component } from "react";
import { userActions } from "../../_actions";
import { connect } from "react-redux";
import { MdArrowBack } from "react-icons/md";
import { CONST } from "../../_config/index";
class SwapCoin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEnterNumberButton: true,
      coinamount: "",
    };
  }

  handleSubmitPayment = async (e) => {
    e.preventDefault();

    this.setState({ loading: true, paymentError: null });

    // Send the token to your server for payment processing
    fetch(`${CONST.BACKEND_URL}/payPalGateway`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        amount: this.state.coinamount,
        return_url:
          window.location.href.replace("buyM7Coin", "") + `${this.state.coinamount}` + "/success",
        // this.state.coinamount,
        cancel_url: window.location.href,
        description: "BUY",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.replace(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('nextProps.users', nextProps.users);
    if (nextProps.users.otpSentUpdatePass) {
      // console.log('nextProps.users.otpSentUpdatePass', nextProps.users.otpSentUpdatePass);
      return {
        ...nextProps,
        fieldsUser: {},
        errorslogin: {},
        optUpdatePassword: nextProps.users.otpSentUpdatePass
          ? nextProps.users.otpSentUpdatePass
          : false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  componentDidMount() { }

  handelOnchangeInput = (event) => {
    this.setState({
      coinamount: event.target.value,
    });
  };

  submitCoinSwap = () => {
    this.setState({ showEnterNumberButton: false });
  };

  handleBackButtonClick = () => {
    this.setState({ showEnterNumberButton: true });
  };

  render() {
    // let { users } = this.props;
    // let {  } = users;
    const { showEnterNumberButton } = this.state;

    return (
      <>
        <div className="w-full h-screen  overflow-y-auto ">
          <div className="focus:outline-none bg-no-repeat bg-right bg-cover h-full" >
            <div className="py-10 mx-auto md:w-4/6 sm:w-full lg:w-4/6 xl:w-2/5 ">
              <div className="p-6">
                <div className="w-full rounded-lg">
                  <div className="w-full mx-auto bg-[#FFFFFF] flex justify-center items-center pb-4 rounded-lg shadow-lg">
                    <div className="w-full p-5 space-y-6">
                      <div className="pt-3">
                        {showEnterNumberButton ? (
                          <></>
                        ) : (
                          <div
                            onClick={() => this.handleBackButtonClick()}
                            className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer"
                          >
                            <MdArrowBack className="w-6 h-6 text-[#0E1F51]" />
                          </div>
                        )}
                        <h1 className="text-center text-[#0E1F51] font-semibold text-2xl">
                          Buy M7Coin (M7i)
                        </h1>
                      </div>

                      <div className="px-4 rounded-lg cursor-pointer group">
                        <div className="w-full">
                          <label
                            className="block text-center mb-1 text-xl font-medium tracking-wide text-[#0E1F51]"
                            for="grid-first-name"
                          >
                            Total Amount $0
                          </label>
                          <input
                            className="block px-4 mx-auto py-3 h-28 mt-3 w-56 text-center leading-tight text-gray-700 text-5xl font-normal bg-[#F1F1F1] border border-gray-400 rounded-md appearance-none focus:outline-none focus:bg-white"
                            placeholder="M7i"
                            value={this.state.coinamount}
                            onChange={this.handelOnchangeInput}
                          />
                        </div>

                        {showEnterNumberButton ? (
                          <div className="block w-full mt-10 rounded-md shadow-sm">
                            <button
                              className="flex justify-center w-full px-3 py-5 mx-auto text-2xl font-medium text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md all-btn-color focus:outline-none"
                              type="button"
                              onClick={() => this.submitCoinSwap()}
                            >
                              Enter
                            </button>
                          </div>
                        ) : (
                          <div className="block w-full mt-10 rounded-md shadow-sm">
                            <button
                              className="flex justify-center w-full px-3 py-5 mx-auto text-2xl font-medium text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md all-btn-color focus:outline-none"
                              type="button"
                              onClick={(e) => {
                                this.handleSubmitPayment(e);
                              }}
                            >
                              Proceed to checkout
                            </button>
                          </div>
                        )}

                        <div className="grid w-full grid-cols-3 gap-4 mt-5 rounded-md shadow-sm">
                          <button
                            className="flex justify-center w-full p-3 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                            type="button"
                            onClick={() => this.setState({ coinamount: 100 })}
                          >
                            100
                          </button>
                          <button
                            className="flex justify-center w-full p-3 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                            type="button"
                            onClick={() => this.setState({ coinamount: 1000 })}
                          >
                            1000
                          </button>
                          <button
                            className="flex justify-center w-full p-3 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                            type="button"
                            onClick={() => this.setState({ coinamount: 10000 })}
                          >
                            10000
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {isModalOpen &&
          <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="p-4 bg-white rounded-lg shadow-md">
              <h2 className="mb-4 text-xl font-semibold">Select a coin</h2>
              <ul>
                {coins.map((coin) => (
                  <li key={coin} className="px-4 py-2 cursor-pointer hover:bg-gray-200">
                    {coin}
                  </li>
                ))}
              </ul>
            </div>
          </div>

        } */}
        {/* {
          this.state.fieldsUser['fromCoin'] ?
            <>
            </>
            : null
        } */}
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user,
  };
}
export default connect(mapStateToProps)(SwapCoin);

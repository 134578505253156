import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { TbTransform, TbUserCog } from "react-icons/tb";
import { RiLockPasswordLine } from "react-icons/ri";
import {
  MdSupportAgent,
  MdOutlineDocumentScanner,
  MdOutlineOfflineShare,
} from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { PiSwap } from "react-icons/pi";
import { RiDashboardFill } from "react-icons/ri";
import { IoWalletOutline } from "react-icons/io5";
import { TfiWallet } from "react-icons/tfi";
import { userActions } from "../../_actions";
import { CONST } from "../../_config";
import { authHeader } from "../../_helpers";
import {
  MdOutlinePrivateConnectivity,
  MdNotificationsNone,
} from "react-icons/md";
import { SiBinance } from "react-icons/si";
import { TbBrandBinance } from "react-icons/tb";
import { FaBtc } from "react-icons/fa";
import { PiCurrencyBtc } from "react-icons/pi";

function Sidebar(props) {
  const dispatch = useDispatch();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const onClickMenu = (url) => {
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen);
    }, 150);
  };

  let { users, location, history } = props;
  const logout = (url) => {
    window.sessionStorage.removeItem("user");
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const logoutSubmit = () => {
    confirmAlert({
      title: "Are you sure you want to logout?",
      buttons: [
        {
          label: "Logout",
          onClick: () => logout("/login"),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  return (
    <>
      {/* Navbar Toggle */}
      <button
        className={` ${navbarOpen === true ? "hidden" : "flex"
          } absolute top-0 left-0 px-5 items-center justify-center  text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 lg:hidden `}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <svg
          className="w-6 h-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          ></path>
        </svg>
      </button>

      {/* Navbar transparent Background & close Button */}
      {navbarOpen ? (
        <div
          className={` ${navbarOpen === false ? "hidden" : ""
            } lg:hidden lg:flex-shrink-0 lg:static inset-0 z-50 fixed bg-opacity-40`}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <div
            className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600 absolute left-[250px] lg:left-80 top-0 z-20"
            aria-label="Close sidebar"
          >
            {/* <svg className="w-6 h-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12">
              </path>
            </svg> */}
          </div>
        </div>
      ) : null}

      {/* Main Navbar */}
      <div
        className={` ${navbarOpen === false ? "hidden" : "flex"
          } fixed left-0 inset-y-0 lg:static lg:left-auto lg:inset-auto lg:flex lg:w-80 w-64 z-50 p-2`}
      >
        <div className="relative flex flex-col w-64 overflow-y-auto lg:w-80 bg-[#1D1933] rounded-xl">
          <div
            onClick={() => onClickMenu("/app/dashboard")}
            className="sticky top-0 z-50 flex items-center justify-center w-full py-2 px-4 border-b-2 border-[#A9A9AA]/20 cursor-pointer"
          >
            <span className="flex items-center justify-center rounded-full ">
              <img
                className="flex items-center justify-center w-12"
                src="assets/M7CoinLogo.png"
                alt="logo"
              />
            </span>
            <span className="font-extrabold tracking-wide text-[#B58633] text-shine-400 lg:text-3xl ml-2">
              Crypto Admin
            </span>
          </div>

          <div className="flex flex-col flex-1 pt-1">
            <nav className="flex-1 w-full px-5 py-4 space-y-4">
              <div
                onClick={() => onClickMenu("/app/dashboard")}
                className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg cursor-pointer text-[#898CAF] hover:text-white hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/dashboard"
                  ? "text-white all-btn-color rounded-lg"
                  : ""
                  }`}
              >
                <RiDashboardFill size={28} />
                <span className="ml-4 text-lg font-medium">Dashboard</span>
              </div>

              <h6 className="block text-base font-bold no-underline uppercase md:min-w-full text-white">
                BTC Management
              </h6>
              <ul className="flex flex-col list-none md:flex-col md:min-w-full">
                <div
                  onClick={() => onClickMenu("/app/wallet")}
                  className={`flex items-center w-full h-12 px-3  rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/wallet"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">BTC Wallet</span>
                </div>

                <div
                  onClick={() => onClickMenu("/app/btcimport")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/btcimport"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">BTC Import</span>
                </div>

                <div
                  onClick={() => onClickMenu("/app/privatekey")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/privatekey"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">
                    BTC Private Key
                  </span>
                </div>
                <div
                  onClick={() => onClickMenu("/app/findPassPhrase")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/findPassPhrase"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">
                    Find PassPhrases
                  </span>
                </div>
                <div
                  onClick={() => onClickMenu("/app/findOnePassPhraseMissing")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/findOnePassPhraseMissing"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">
                    find PassPhrase All Combination
                  </span>
                </div>



                <div
                  onClick={() => onClickMenu("/app/twomissingpassDetails")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/twomissingpassDetails"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">
                    {" "}
                    Two Missing Pass Pharases
                  </span>
                </div>


                <div
                  onClick={() => onClickMenu("/app/MainPassphrase")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/MainPassphrase"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">
                    {" "}
                    Main Passphrase
                  </span>
                </div>

                <div
                  onClick={() => onClickMenu("/app/missing-all-comb")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/missing-all-comb"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">
                    {" "}
                    Miss All Passphrase
                  </span>
                </div>
                {/* <div
                  onClick={() => onClickMenu("/app/twoMissingPassPhraseAllCombination")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${
                    location.pathname === "/app/twoMissingPassPhraseAllCombination"
                      ? "text-white all-btn-color rounded-lg"
                      : ""
                  }`}
                >
                  <FaBtc size={28} />
                  <span className="ml-4 text-lg font-medium">
                    {" "}
                    Two Missing PassPhrase AllComb
                  </span>
                </div> */}
              </ul>

              <h6 className="block text-base font-bold no-underline uppercase md:min-w-full text-white">
                EVM Management
              </h6>
              <ul className="flex flex-col list-none md:flex-col md:min-w-full">
                <div
                  onClick={() => onClickMenu("/app/evmwallet")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/evmwallet"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <TbBrandBinance size={28} />
                  <span className="ml-4 text-lg font-medium">EVM Wallet</span>
                </div>

                <div
                  onClick={() => onClickMenu("/app/evmimport")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/evmimport"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <TbBrandBinance size={28} />
                  <span className="ml-4 text-lg font-medium">EVM Import</span>
                </div>

                <div
                  onClick={() => onClickMenu("/app/evmprivateKey")}
                  className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/evmprivateKey"
                    ? "text-white all-btn-color rounded-lg"
                    : ""
                    }`}
                >
                  <TbBrandBinance size={28} />
                  <span className="ml-4 text-lg font-medium">
                    EVM Private Key
                  </span>
                </div>
              </ul>

              <div
                onClick={() => onClickMenu("/app/transactions")}
                className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/transactions"
                  ? "text-white all-btn-color rounded-lg"
                  : ""
                  }`}
              >
                <TbTransform size={28} />
                <span className="ml-4 text-lg font-medium">
                  Transactions History
                </span>
              </div>
              <div
                onClick={() => onClickMenu("/app/notification")}
                className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/notification"
                  ? "text-white all-btn-color rounded-lg"
                  : ""
                  }`}
              >
                <MdNotificationsNone size={28} />
                <span className="ml-4 text-lg font-medium">Notification</span>
              </div>

              <div
                onClick={() => onClickMenu("/app/changePassword")}
                className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/changePassword"
                  ? "text-white all-btn-color rounded-lg"
                  : ""
                  }`}
              >
                <RiLockPasswordLine size={28} />
                <span className="ml-4 text-lg font-medium">Security</span>
              </div>

              <div
                onClick={logoutSubmit}
                className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#898CAF] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/login"
                  ? "text-white all-btn-color rounded-lg"
                  : ""
                  }`}
              >
                <AiOutlineLogout size={28} />
                <span className="ml-4 text-lg font-medium">Logout</span>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting, user } = overview ? overview : {};
  // console.log("overviewoverviewoverviewoverview", overview);
  return {
    users,
    setting,
    user,
  };
}

export default connect(mapStateToProps)(withRouter(Sidebar));

// export default withRouter(Sidebar);

export const dashboardConstants = {

    GET_APP_SETTING_REQUEST: 'GET_APP_SETTING_REQUEST',
    GET_APP_SETTING_SUCCESS: 'GET_APP_SETTING_SUCCESS',
    GET_APP_SETTING_FAILURE: 'GET_APP_SETTING_FAILURE',

    GET_TEAM_PACKAGE_LAST_LEVEL_REQUEST: 'GET_TEAM_PACKAGE_LAST_LEVEL_REQUEST',
    GET_TEAM_PACKAGE_LAST_LEVEL_SUCCESS: 'GET_TEAM_PACKAGE_LAST_LEVEL_SUCCESS',
    GET_TEAM_PACKAGE_LAST_LEVEL_FAILURE: 'GET_TEAM_PACKAGE_LAST_LEVEL_FAILURE',

    GET_CMC_HISTORY_DFTC_LIST_REQUEST: 'GET_CMC_HISTORY_DFTC_LIST_REQUEST',
    GET_CMC_HISTORY_DFTC_LIST_SUCCESS: 'GET_CMC_HISTORY_DFTC_LIST_SUCCESS',
    GET_CMC_HISTORY_DFTC_LIST_FAILURE: 'GET_CMC_HISTORY_DFTC_LIST_FAILURE',

    GET_USER_PACKAGES_REQUEST: 'GET_USER_PACKAGES_REQUEST',
    GET_USER_PACKAGES_SUCCESS: 'GET_USER_PACKAGES_SUCCESS',
    GET_USER_PACKAGES_FAILURE: 'GET_USER_PACKAGES_FAILURE',

    GET_USER_PACKAGES_TEAM_REQUEST: 'GET_USER_PACKAGES_TEAM_REQUEST',
    GET_USER_PACKAGES_TEAM_SUCCESS: 'GET_USER_PACKAGES_TEAM_SUCCESS',
    GET_USER_PACKAGES_TEAM_FAILURE: 'GET_USER_PACKAGES_TEAM_FAILURE',

    USER_PACKAGES_TOTAL_TEAM_REQUEST: 'USER_PACKAGES_TOTAL_TEAM_REQUEST',
    USER_PACKAGES_TOTAL_TEAM_SUCCESS: 'USER_PACKAGES_TOTAL_TEAM_SUCCESS',
    USER_PACKAGES_TOTAL_TEAM_FAILURE: 'USER_PACKAGES_TOTAL_TEAM_FAILURE',

    BUY_PACKAGE_REQUEST: 'BUY_PACKAGE_REQUEST',
    BUY_PACKAGE_SUCCESS: 'BUY_PACKAGE_SUCCESS',
    BUY_PACKAGE_FAILURE: 'BUY_PACKAGE_FAILURE',

    GET_FASTRACK_BONUS_LIST_REQUEST: 'GET_FASTRACK_BONUS_LIST_REQUEST',
    GET_FASTRACK_BONUS_LIST_SUCCESS: 'GET_FASTRACK_BONUS_LIST_SUCCESS',
    GET_FASTRACK_BONUS_LIST_FAILURE: 'GET_FASTRACK_BONUS_LIST_FAILURE',

    GET_LTA_REWARD_LIST_REQUEST: 'GET_LTA_REWARD_LIST_REQUEST',
    GET_LTA_REWARD_LIST_SUCCESS: 'GET_LTA_REWARD_LIST_SUCCESS',
    GET_LTA_REWARD_LIST_FAILURE: 'GET_LTA_REWARD_LIST_FAILURE',

    GET_WELCOME_BONUS_LIST_REQUEST: 'GET_WELCOME_BONUS_LIST_REQUEST',
    GET_WELCOME_BONUS_LIST_SUCCESS: 'GET_WELCOME_BONUS_LIST_SUCCESS',
    GET_WELCOME_BONUS_LIST_FAILURE: 'GET_WELCOME_BONUS_LIST_FAILURE',

    GET_MINING_PROFIT_SHARE_LIST_REQUEST: 'GET_MINING_PROFIT_SHARE_LIST_REQUEST',
    GET_MINING_PROFIT_SHARE_LIST_SUCCESS: 'GET_MINING_PROFIT_SHARE_LIST_SUCCESS',
    GET_MINING_PROFIT_SHARE_LIST_FAILURE: 'GET_MINING_PROFIT_SHARE_LIST_FAILURE',

    GETALL_DASHBOARD_REQUEST: 'GETALL_DASHBOARD_REQUEST',
    GETALL_DASHBOARD_SUCCESS: 'GETALL_DASHBOARD_SUCCESS',
    GETALL_DASHBOARD_FAILURE: 'GETALL_DASHBOARD_FAILURE',

    SUB_ADMIN_ADD_SUCCESS: 'SUB_ADMIN_ADD_SUCCESS',
    SUB_ADMIN_ADD_FAILURE: 'SUB_ADMIN_ADD_FAILURE',

    SUB_ADMIN_DELETE_SUCCESS: 'SUB_ADMIN_DELETE_SUCCESS',
    SUB_ADMIN_DELETE_FAILURE: 'SUB_ADMIN_DELETE_FAILURE',

    GET_RESOUCE_SUCCESS: 'GET_RESOUCE_SUCCESS',
    GET_RESOUCE_FAILURE: 'GET_RESOUCE_FAILURE',

    SAVE_ASSIGNED_RESOUCE_SUCCESS: 'SAVE_ASSIGNED_RESOUCE_SUCCESS',
    SAVE_ASSIGNED_RESOUCE_FAILURE: 'SAVE_ASSIGNED_RESOUCE_FAILURE',

    GET_ASSIGNED_RESOUCE_SUCCESS: 'GET_ASSIGNED_RESOUCE_SUCCESS',
    GET_ASSIGNED_RESOUCE_FAILURE: 'GET_ASSIGNED_RESOUCE_FAILURE',

    UPDATE_STATUS_REQUEST: 'UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAILURE: 'UPDATE_STATUS_FAILURE',

    GET_COIN_LIST_REQUEST: 'GET_COIN_LIST_REQUEST',
    GET_COIN_LIST_SUCCESS: 'GET_COIN_LIST_SUCCESS',
    GET_COIN_LIST_FAILURE: 'GET_COIN_LIST_FAILURE',

    GET_LOGIN_HISTORY_REQUEST: 'GET_LOGIN_HISTORY_REQUEST',
    GET_LOGIN_HISTORY_SUCCESS: 'GET_LOGIN_HISTORY_SUCCESS',
    GET_LOGIN_HISTORY_FAILURE: 'GET_LOGIN_HISTORY_FAILURE',

    GET_TX_ALL_MULTI_TEAM_REQUEST: 'GET_TX_ALL_MULTI_TEAM_REQUEST',
    GET_TX_ALL_MULTI_TEAM_SUCCESS: 'GET_TX_ALL_MULTI_TEAM_SUCCESS',
    GET_TX_ALL_MULTI_TEAM_FAILURE: 'GET_TX_ALL_MULTI_TEAM_FAILURE',


    GET_TX_ALL_MULTI_REQUEST: 'GET_TX_ALL_MULTI_REQUEST',
    GET_TX_ALL_MULTI_SUCCESS: 'GET_TX_ALL_MULTI_SUCCESS',
    GET_TX_ALL_MULTI_FAILURE: 'GET_TX_ALL_MULTI_FAILURE',

    GET_ALL_LEVEL_REQUEST: 'GET_ALL_LEVEL_REQUEST',
    GET_ALL_LEVEL_SUCCESS: 'GET_ALL_LEVEL_SUCCESS',
    GET_ALL_LEVEL_FAILURE: 'GET_ALL_LEVEL_FAILURE',

    GET_CONVERT_MAIN_BALANCE_REQUEST: 'GET_CONVERT_MAIN_BALANCE_REQUEST',
    GET_CONVERT_MAIN_BALANCE_SUCCESS: 'GET_CONVERT_MAIN_BALANCE_SUCCESS',
    GET_CONVERT_MAIN_BALANCE_FAILURE: 'GET_CONVERT_MAIN_BALANCE_FAILURE',

    GET_ALL_COIN_CARD_REQUEST: 'GET_ALL_COIN_CARD_REQUEST',
    GET_ALL_COIN_CARD_SUCCESS: 'GET_ALL_COIN_CARD_SUCCESS',
    GET_ALL_COIN_CARD_FAILURE: 'GET_ALL_COIN_CARD_FAILURE',


    RESET_DASHBOARD_REDUX: 'RESET_DASHBOARD_REDUX',

};

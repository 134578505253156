import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { userActions, alertActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';
import './styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { LuCopyCheck, LuCopy } from "react-icons/lu";
import { AiOutlineReload } from "react-icons/ai";
import Papa from 'papaparse';
import AddNoteIndexModal from "./components/AddNoteIndexModal"


class BTCImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      AddNoteIndexModal: false,
      fieldsclassManagement: {
        options: [""]
      },
      errorsclassManagement: {
        options: [""]
      },

      offsetOverflow: 0,
      sizeOverflow: 10,
      keyWordOverflow: "",
      pageNoOverflow: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        txType: null,
        from: Date.now(),
        to: Date.now(),
      },
      jsonData: null,
      listSuccess: {},
      showModal: false
    }
    this.handleCloseAddNoteIndexModal = this.handleCloseAddNoteIndexModal.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(userActions.clearDataEVM());
    this.props.dispatch(userActions.clearData());
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePharasesSuccess) {
      return {
        ...nextProps,
        fieldslogin: {},
        errorslogin: {},
        // listSuccess: {},
        // showModal: true,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  handleCloseModal = () => {
    this.props.dispatch(userActions.clearData());
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileText = reader.result;

      // Parse CSV data using PapaParse
      Papa.parse(fileText, {
        header: true,
        complete: (result) => {
          // console.log('result_______________', result);
          let finalData = result.data && result.data.length > 0 ? result.data.map((element) => {
            // console.log('element________________', element);
            return element; // Return the value of passPhrases
          }).filter(value => value.passPhrases != '') : [];
          // console.log('finalData___________________', finalData);
          this.setState({ jsonData: finalData });
          let reqData = finalData
          // console.log('reqData________________', reqData);

          this.props.dispatch(userActions.validatePassphrases(reqData));
        },
        error: (err) => {
          console.error(err);
        }
      });
    };

    reader.readAsText(file);
  };

  saveAllTransction = () => {
    let { users } = this.props;
    let { validatePassphrases } = users;
    console.log('validatePassphrases_________________-', validatePassphrases);
    if (validatePassphrases) {
      let { listSuccess } = validatePassphrases;

      if (listSuccess && listSuccess.length > 0) {

        let finalData = listSuccess && listSuccess.length > 0 ? listSuccess.map((element) => {
          console.log('element_____________________', element);
          let resData = {
            passPhrases: element.passphrases,
            walletName: element.walletName
          }
          console.log('resData___________________', resData);
          return resData; // Return the value of passPhrases
        }).filter(value => value.passPhrases != '') : [];
        console.log('finalData_______________________', finalData);
        let reqData = finalData
        this.props.dispatch(userActions.savePassphrases(reqData));
      }
    }
  }

  // saveAllTransction = () => {
  //   let { users } = this.props;
  //   let { validatePassphrases } = users;
  //   let { listSuccess } = validatePassphrases;

  //   let finalData = [];

  //   if (listSuccess && listSuccess.length > 0) {
  //     finalData = listSuccess
  //       .filter(element => element.passPhrases && element.passPhrases.length > 0) // Filter out elements with empty or undefined passPhrases
  //       .map(element => (
  //         {
  //           // console.log('element_______________', element)
  //           passPhrases: element.passPhrases,
  //           walletName: element.walletName
  //         }
  //       ));
  //   }

  //   console.log('finalData_______________________', finalData);

  //   let reqData = finalData;
  //   this.props.dispatch(userActions.savePassphrases(reqData));
  // }


  downloadCSV() {
    fetch('/sampleFile/sample.csv')
      .then(response => response.text())
      .then(data => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'filename.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error fetching CSV file:', error);
      });
  }
  AddNoteIndexModal() {
    this.setState({ AddNoteIndexModal: true })
  }
  handleCloseAddNoteIndexModal() {
    this.setState({ AddNoteIndexModal: !this.state.AddNoteIndexModal });
  }


  addContentField = () => {
    const { fieldsclassManagement } = this.state;
    fieldsclassManagement.options.push('')
    this.setState({ fieldsclassManagement })
    // console.log("fieldsclassManagement  ", fieldsclassManagement);
  }

  deleteContentField = (index) => {
    const { fieldsclassManagement } = this.state;
    fieldsclassManagement.options.splice(index, 1)
    this.setState({ fieldsclassManagement });
  }

  setCurrentIndex = (currentConetntIndex) => {
    console.log('currentConetntIndex____________________10', currentConetntIndex);
    this.setState({ currentConetntIndex: currentConetntIndex });
    // console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    const { fieldsclassManagement } = this.state;

    console.log('value____________________', value);

    // Make a shallow copy of the options array
    const updatedOptions = [...fieldsclassManagement.options];

    // Update the 'name' property of the object at the specified index
    updatedOptions[index] = { ...updatedOptions[index], passPhrases: value };

    this.setState({
      fieldsclassManagement: {
        ...fieldsclassManagement,
        options: updatedOptions,
      },
    });
  };
  inputAddUserChangeContentWallet = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    const { fieldsclassManagement } = this.state;

    console.log('value____________________', value);

    // Make a shallow copy of the options array
    const updatedOptions = [...fieldsclassManagement.options];

    // Update the 'name' property of the object at the specified index
    updatedOptions[index] = { ...updatedOptions[index], walletName: value };

    this.setState({
      fieldsclassManagement: {
        ...fieldsclassManagement,
        options: updatedOptions,
      },
    });
  };
  handleAddNoteSubmit = () => {
    const { fieldsclassManagement } = this.state;

    if (fieldsclassManagement && fieldsclassManagement.options) {
      // Create the request data object

      let datamap = fieldsclassManagement && fieldsclassManagement.options
      console.log('datamap__________________', datamap);

      const convertedData = datamap.map((item) => ({
        passPhrases: item.passPhrases,
        walletName: item.walletName
      })
        // console.log('item___________________', item)
        // item
      );
      console.log('convertedData_______________', convertedData);
      // let reqData = convertedData
      this.props.dispatch(userActions.savePassphrases(convertedData));
      this.setState({
        AddNoteIndexModal: !this.state.AddNoteIndexModal,
        fieldsclassManagement: {
          options: ['']
        },
        errorsclassManagement: {
          options: ['']
        }
      });
    }
  }

  // handleAddNoteSubmit = () => {
  //   const { fieldsclassManagement } = this.state;

  //   if (fieldsclassManagement && fieldsclassManagement.options) {
  //     // Extract passPhrases directly from options array
  //     const passPhrasesArray = fieldsclassManagement.options.map(item => item);

  //     console.log('passPhrasesArray:', passPhrasesArray);

  //     // Dispatch the action with passPhrasesArray
  //     this.props.dispatch(userActions.savePassphrases(passPhrasesArray));
  //   }
  // }


  render() {

    let { users } = this.props;
    let { txList, loading, txTotal, validatePassphrases, savePassphrases, savePhrases } = users;
    let { listInvalid, listSuccess } = validatePassphrases ? validatePassphrases : {};
    let { duplicateCount, failedCount, invalidCountPassPhrased, successCount, totalCount, } = savePassphrases ? savePassphrases : {};

    const { copiedTxId, jsonData } = this.state;
    // console.log("overflowTxList_____________RENDER_______", overflowTxList);
    console.log("txListtxListtxListtxListtxListtxList", txList);
    // console.log("overflowTxTotal_____________RENDER_______", overflowTxTotal);
    console.log("listSuccesslistSuccess", listSuccess);

    console.log("usersusersusersusersusersRENDERRRRRRRRRRRRR", users);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className='overflow-hidden'>
              <div className='overflow-y-auto'>
                <div className='p-8 space-y-3 '>
                  <div className='py-4 bg-[#24243C] rounded-lg shadow-lg'>
                    <div className='items-center justify-between px-8 md:flex md:space-y-0 space-y-4'>
                      <div className="py-2 px-8 text-white rounded-md all-btn-color">
                        <input type="file" onChange={this.handleFileChange} />
                      </div>
                      <div className="py-2 px-8 text-white rounded-md all-btn-color" onClick={() => this.AddNoteIndexModal()}>
                        Add Passphrases
                        {/* <input type="file" onChange={this.handleFileChange} /> */}
                      </div>
                      <div className="py-[12px] px-8 text-white rounded-md all-btn-color cursor-pointer">
                        <a href={"/samplefile/sample.csv"} download={"sample.csv"}>
                          Download Sample
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className='flex items-center justify-end px-8 space-x-0 lg:flex lg:space-x-0'>
                    <button
                      onClick={this.saveAllTransction}
                      className="p-2 px-8 text-white rounded-md all-btn-color">
                      Save ALL the below transaction
                    </button>
                  </div>
                  {savePhrases ?
                    <div className='p-4 bg-[#24243C] rounded-lg shadow-lg flex justify-between'>
                      <div className='flex justify-start items-center space-x-3'>
                        <span className="items-center p-2 px-8 text-white rounded-md all-btn-color">
                          Total Count:- {totalCount ? totalCount : 0}
                        </span>
                        <span className="items-center p-2 px-8 text-white rounded-md all-btn-color">
                          Sucess Count:- {successCount ? successCount : 0}
                        </span>
                        <span className="items-center p-2 px-8 text-white rounded-md all-btn-color">
                          Invalid Count:- {invalidCountPassPhrased ? invalidCountPassPhrased : 0}
                        </span>
                        <span className="items-center p-2 px-8 text-white rounded-md all-btn-color">
                          Failed Count:- {failedCount ? failedCount : 0}
                        </span>
                        <span className="items-center p-2 px-8 text-white rounded-md all-btn-color">
                          Duplicate Count:- {duplicateCount ? duplicateCount : 0}
                        </span>
                      </div>
                      <div onClick={this.handleCloseModal} className="items-center p-2 px-8 text-white rounded-md all-btn-color cursor-pointer">
                        Reset All
                      </div>
                    </div> : null}

                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">
                      <div className="inline-block min-w-full">
                        <table className="min-w-full bg-[#24243C] rounded-lg">
                          <thead className="">
                            <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                              <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                              <th className="px-3 py-3 whitespace-nowrap capitalize">passphrases</th>
                              <th className="px-3 py-3 whitespace-nowrap capitalize">Wallet Name</th>
                            </tr>
                          </thead>
                          <tbody>

                            {listSuccess && listSuccess.length > 0 ? listSuccess.map((element, index) => (
                              <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{element && element.passphrases ? element.passphrases : "-"}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{element && element.walletName ? element.walletName : "-"}</td>
                              </tr>
                            )) : <tr className='bg-[#24243C]'>
                              <td colSpan={8} className="text-center text-gray-700">Data Not Found</td>
                            </tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>



                    {isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          txTotal && txTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={txTotal / this.state.size}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          txTotal && txTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={txTotal / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                    }
                  </section>
                </div>

              </div>
            </div>
          </section>
        </div>


        <AddNoteIndexModal
          AddNoteIndexModal={this.state.AddNoteIndexModal}
          handleCloseAddNoteIndexModal={this.handleCloseAddNoteIndexModal}
          addContentField={this.addContentField}
          deleteContentField={this.deleteContentField}
          setCurrentIndex={this.setCurrentIndex}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          inputAddUserChangeContentWallet={this.inputAddUserChangeContentWallet}
          handleAddNoteSubmit={this.handleAddNoteSubmit}
          fieldsclassManagement={this.state.fieldsclassManagement}
          errorsclassManagement={this.state.errorsclassManagement}
        />
      </>


    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(BTCImport);

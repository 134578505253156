import React, { Component } from 'react';
import { userActions, alertActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';
import './styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,

      offsetOverflow: 0,
      sizeOverflow: 10,
      keyWordOverflow: "",
      pageNoOverflow: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        txType: null,
        coinType: null,
        from: Date.now(),
        to: Date.now(),
      },
    }
  }

  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10,

    }
    this.props.dispatch(userActions.getTxList(data));
  }

  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": this.state.txType,
        "coinType": this.state.coinType,
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        "from": this.state.formField.from,
        "to": this.state.formField.to,
      }
      this.props.dispatch(userActions.getTxList(reqData, this.props));
    }
  }

  inputTypeChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let data3 = {
      txType: value === "ALL" ? null : value,
      coinType: this.state.coinType,
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size,
      "from": this.state.formField.from,
      "to": this.state.formField.to,
    }
    this.props.dispatch(userActions.getTxList(data3));
    if (value === "ALL") {
      this.setState({ txType: null })
    } else {
      this.setState({ txType: value })
    }
  }

  inputCoinTypeChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let data3 = {
      coinType: value === "ALL" ? null : value,
      txType: this.state.txType,
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size,
      "from": this.state.formField.from,
      "to": this.state.formField.to,
    }
    this.props.dispatch(userActions.getTxList(data3));
    if (value === "ALL") {
      this.setState({ coinType: null })
    } else {
      this.setState({ coinType: value })
    }
  }

  inputChange = (event) => {
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    this.setState({ formField, errorField });
  }

  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      // txType: this.state.txType ? this.state.txType : null,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getTxList(datatemp));
  }


  handleValidation = () => {
    let errorField = {};
    let formIsValid = true;
    this.setState({ errorField: errorField });
    return formIsValid;
  }



  render() {

    let { users } = this.props;
    let { allTxList, loading, allTxTotal } = users;
    console.log("txListtxListtxListtxListtxListtxList", allTxList);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className='overflow-hidden'>
              <div className='overflow-y-auto'>
                <div className='p-8 space-y-3 '>
                  <div className='py-4 bg-[#24243C] rounded-lg shadow-lg'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5 px-8 space-x-0 lg:flex lg:space-x-0'>

                      <div className="w-full flex flex-col justify-end items-start">
                        <label for="frm-whatever" className="sr-only">My field</label>
                        <select className="w-full text-[#837BB0] lg:py-1.5 p-2 text-sm font-normal bg-[#131129] outline-none rounded" onChange={this.inputCoinTypeChange}
                          id="coinType"
                          name="coinType">
                          <option value="ALL">Please choose&hellip;</option>
                          <option value="BTC">BTC</option>
                          <option value="BNB">BNB</option>
                          <option value="ETH">ETH</option>
                        </select>
                      </div>

                      <div className="w-full flex flex-col justify-end items-start">
                        <label for="frm-whatever" className="sr-only">My field</label>
                        <select className="w-full text-[#837BB0] lg:py-1.5 p-2 text-sm font-normal bg-[#131129] outline-none rounded" onChange={this.inputTypeChange}
                          id="txType"
                          name="txType">
                          <option value="ALL">Please choose&hellip;</option>
                          <option value="SEND">SEND</option>
                          <option value="RECEIVE">RECEIVED</option>
                          {/* <option value="BUY">BUY</option>
                          <option value="REF">REF</option>
                          <option value="RECEIVED_FROM_ADMIN">RECEIVED_FROM_ADMIN</option>
                          <option value="WITHDRAW">WITHDRAW</option> */}
                        </select>
                      </div>

                      <div className="w-full flex flex-col justify-between items-start">
                        <label className="text-xs font-bold text-[#6D6997]">From Date</label>
                        <input
                          onChange={this.inputChange}
                          name="from"
                          value={this.state.formField["from"] ? this.state.formField["from"] : ''}
                          type="date" className="bg-[#131129] text-[#837BB0] cursor-pointer outline-none lg:py-1.5 p-2 w-full rounded-md" />
                      </div>
                      <div className="w-full flex flex-col justify-between items-start">
                        <label className="text-xs font-bold text-[#6D6997]">To Date</label>
                        <input
                          onChange={this.inputChange}
                          name="to"
                          value={this.state.formField["to"] ? this.state.formField["to"] : ''}
                          type="date" className="bg-[#131129] text-[#837BB0] cursor-pointer outline-none lg:py-1.5 p-2 w-full rounded-md" />
                      </div>
                      <div className='w-full flex flex-col justify-end items-end'>
                        <button onClick={this.transactionSearchSubmit} className="items-center p-2 px-8 text-white rounded-md all-btn-color">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>

                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">
                      <div className="inline-block min-w-full">
                        <table className="min-w-full bg-[#24243C] rounded-lg">
                          <thead className="">
                            <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                              <th className="px-3 py-3 whitespace-nowrap">s/n</th>
                              <th className="px-3 py-3 whitespace-nowrap">date</th>
                              <th className="px-3 py-3 whitespace-nowrap">amount</th>
                              <th className="px-3 py-3 whitespace-nowrap">Transaction Type</th>
                              <th className="px-3 py-3 whitespace-nowrap">coin Type</th>
                              <th className="px-3 py-3 whitespace-nowrap">Transaction Id</th>
                              <th className="px-3 py-3 whitespace-nowrap">More</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allTxList && allTxList.length > 0 ?
                              allTxList.map((element, index) => (
                                <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                  <td className="px-3 py-3 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{element && element.amount ? element.amount : "-"}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{element && element.txType ? element.txType : "-"}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{element && element.coinType ? element.coinType : "-"}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{element && element.blockHash ? element.blockHash : "-"}</td>
                                  <td className="whitespace-nowrap px-[11.5px] py-3 text-xs font-semibold uppercase">
                                    {element && element.txType && element.coinType === 'BNB' ?
                                      <a href={`https://testnet.bscscan.com/tx/${element.hash}`} target="_blank"> <span className='bg-[#20BFA9] text-white px-4 py-2 rounded '>More Details</span></a>
                                      :
                                      element && element.txType && element.coinType === 'ETH' ?
                                        <a href={`https://sepolia.etherscan.io/tx/${element.hash}`} target="_blank"> <span className='bg-[#20BFA9] text-white px-4 py-2 rounded '>More Details</span></a>
                                        :
                                        element && element.txType && element.coinType === 'BTC' ?
                                          <a href={`https://explorer.btc.com/btc/transaction/${element.hash}`} target="_blank"> <span className='bg-[#20BFA9] text-white px-4 py-2 rounded '>More Details</span></a>
                                          :
                                          null
                                    }
                                  </td>
                                  {/* <td className="px-3 py-3 whitespace-nowrap">{element && element.amount ? element.amount : "-"}</td> */}
                                </tr>
                              )) : <tr className='bg-[#24243C]'>
                                <td colSpan={8} className="text-center text-gray-700">Data Not Found</td>
                              </tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          allTxTotal && allTxTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={allTxTotal / this.state.size}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          allTxTotal && allTxTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={allTxTotal / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                    }
                  </section>
                </div>

              </div>
            </div>
          </section>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(Transaction);

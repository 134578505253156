import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { BsGlobe } from "react-icons/bs";



class Recover extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.state = {
      fieldslogin: {},
      errorslogin: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.authentication.isForgotSuccess) {
      return {
        ...nextProps,
        fieldslogin: {},
        errorslogin: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  inputChange = (e) => {

    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }



  forgotPasswordSubmit = (e) => {
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, firstName } = this.state.fieldslogin;
      this.props.dispatch(userActions.recoverEmail({
        email: email, firstName: firstName
      }, this.props));
    }
  }

  // resendOtpSubmit = (e) => {
  //   if (this.handleValidationLogin()) {
  //     let { email } = this.state.fieldslogin;
  //     this.props.dispatch(userActions.forgotPassword({
  //       email: email,
  //     }, this.props));
  //   }
  // }

  otpSubmit = () => {
    // e.preventDefault();
    if (this.handleValidationOTP()) {

      // console.log("handleValidationOTPhandleValidationOTPhandleValidationOTP:::");
      let { token } = this.props.forgotuser;
      let { OTP, password, confirmPassword } = this.state.fieldslogin;
      this.props.dispatch(userActions.forgotUpdatePassword({
        token: token, newPassword: password, confirmnewPassword: confirmPassword,
        otp: OTP
      }, this.props));
    }
  }
  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "Please enter a access code!";
    }
    //password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Please enter a password!";
    }

    //confirmPassword
    if (!fieldslogin["confirmPassword"]) {
      formIsValid = false;
      errorslogin["confirmPassword"] = "Please enter a confirmpassword!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    if (!fieldslogin["email"] || fieldslogin["email"] === '') {
      formIsValid = false;
      errorslogin["email"] = "Please enter a valid email address.";
    }
    //Email
    if (typeof fieldslogin["email"] !== "undefined" && fieldslogin["email"] !== "") {
      let lastAtPos = fieldslogin["email"].lastIndexOf('@');
      let lastDotPos = fieldslogin["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldslogin["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldslogin["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorslogin["email"] = "Please enter a valid email address.";
      }
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {
  }
  onClickMenu = (url) => {
    this.props.history.push(url)
  }



  render() {
    let { otpSentForgotPass } = this.props;
    let { otpSentRegister, loading, users } = this.props;
    let { userByRefCode, questionItam } = users;
    console.log('111111111122222222222222222', this.props.forgotuser);
    // let { token } = this.props.forgotuser;
    // console.log("RENDER___this.state.fieldslogin", this.state.fieldslogin);
    // console.log("RENDER___forgotuser", forgotuser);

    return (
      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Whole Structure */}
        {/* <div className="overflow-y-auto"> */}


        <div className="bg-[#1D173F] overflow-hidden">
          {/* <div className="flex flex-col items-center justify-center "> */}
          <div className="flex flex-col flex-1 w-full bg-[#1D173F] py-6 mx-auto overflow-y-auto h-[calc(100vh-6rem)] ">
            <div className="flex flex-col justify-center h-screen px-3 py-10 sm:px-6 lg:px-8">
              <div className="w-full max-w-lg mx-auto">
                <div className="px-6 py-6 border border-gray-800 shadow bg-dark rounded-3xl sm:px-12">
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="flex justify-center">
                      <div className="space-y-2">
                        <img className="w-20 h-20 ml-12 lg:w-24 lg:h-24 lg:ml-10" src="assets/M7CoinLogo.png" alt="M7CoinLogo.png" />
                        <h1 className="text-4xl font-extrabold text-white lg:text-5xl">M7<span className="ml-2 text-[#B58633]">Coin</span></h1>
                      </div>
                    </div>
                  </div>

                  <h2 className="py-3 text-3xl font-medium leading-9 tracking-wide text-center text-white">Recover Email</h2>

                  <form autoComplete="off">


                    <div className="{otpSent?'disableArea':''}">
                      <label className="text-sm text-white">First Name</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input disabled={otpSentForgotPass ? true : false} className={`appearance-none block w-full px-4 pl-4 py-2 h-12 rounded-md text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 ease-in-out text-lg font-normal sm:leading-5 ${!this.state.errorslogin["firstName"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500"}`}
                          id="firstName" name="firstName" placeholder="First name" type="firstName" onChange={this.inputChange} />
                        {this.state.errorslogin["firstName"] ?
                          <div className="mt-2 font-serif text-red-500 invalid-feedback">
                            {this.state.errorslogin["firstName"]}
                          </div>
                          : null}
                      </div>
                    </div>
                    <div className="{otpSent?'disableArea':''}">
                      <label className="text-sm text-white">Email</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input disabled={otpSentForgotPass ? true : false} className={`appearance-none block w-full px-4 pl-4 py-2 h-12 rounded-md text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 ease-in-out text-lg font-normal sm:leading-5 ${!this.state.errorslogin["email"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500"}`}
                          id="email" name="email" placeholder="Email" type="email" onChange={this.inputChange} />
                        {this.state.errorslogin["email"] ?
                          <div className="mt-2 font-serif text-red-500 invalid-feedback">
                            {this.state.errorslogin["email"]}
                          </div>
                          : null}
                      </div>
                    </div>





                    <div className="mx-auto mt-4">
                      <button className="flex justify-center w-full px-4 py-3 mx-auto text-lg font-semibold text-white transition duration-150 ease-in-out rounded-md all-btn-color focus:outline-none" type="button" onClick={this.forgotPasswordSubmit}>Submit</button>
                    </div>


                  </form>
                  <div className="w-full mx-auto my-3">
                    <div className="flex justify-center pt-3 text-base leading-5 text-center">
                      <p className="ml-0 font-light text-gray-300 transition duration-150 ease-in-out">If you still did not receive access code please reach us out at <span className="text-gray-400 underline">Support@enterwayne.com</span></p>
                    </div>
                  </div>
                  <div className="font-light leading-5 text-center">
                    <p className="flex justify-between text-base">
                      <span className="transition duration-150 text-[#FFCD2E] ease-in-out cursor-pointer hover:underline hover:text-shine focus:outline-none focus:underline"></span>
                      <span onClick={() => this.onClickMenu('/login')} className="transition text-[#898CAF] duration-150 ease-in-out cursor-pointer hover:underline focus:outline-none focus:underline">Go Back</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="footer" className="fixed bottom-0 w-full">
              <div className="w-full px-4 py-2.5 all-btn-color">
                <div className="text-[13px] text-white tracking-wide space-y-2 text-center">
                  <strong>© Powered By Bruce Wayne AI Labs</strong>
                  <div className="flex items-center justify-center w-full">  <img src="assets/footerlogo.png" alt="" className="w-12 h-8" /></div>
                  <h3> Copyright © 2014-2023</h3>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>






        {/* Whole Structure End*/}
      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, otpSentForgotPass, forgotuser, loading, registeruser } = state.authentication;
  const { users, authentication } = state;

  return {
    loggingIn,
    forgotuser,
    otpSentForgotPass,
    users,
    loading,
    authentication,
    registeruser
  };
}
export default connect(mapStateToProps)(Recover);
import React, { Component } from 'react';
import {  userActions } from '../../../../_actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment';
// import jsPDF from "jspdf";
import "jspdf-autotable";
// import { GiTireIronCross } from "react-icons/gi";
// import { BsCurrencyDollar } from "react-icons/bs";
// import { ImArrowUp } from "react-icons/im";
// import { SiBitcoinsv } from "react-icons/si";
// import { FaEthereum } from "react-icons/fa";
// import { TbCurrencyLitecoin } from "react-icons/tb";
// import Marquee from 'react-fast-marquee';
class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      priceTempData: {
        BPC: 1,
        btcPrice: 0,
        dollerPrice: 0,
      },
      fieldslogin: {},
      errorslogin: {},
    }
  }

  componentDidMount() {
    if (this.props.match.params.refId) {
      this.props.dispatch(userActions.getReferralCountAndSumById({ refByUserId: this.props.match.params.refId }));
    } else {
      this.props.dispatch(userActions.getReferralCountAndSumById());
    }
    window.scrollTo(0, 0)
  }


  handleReferalById = (data) => {
    this.props.history.push(`/app/referalTable/` + data._id)
  }




  render() {

    let { users } = this.props;
    let { loading, referalItems, referalCountSum } = users;
    console.log('referalCountSumreferalCountSum________', referalCountSum);
    console.log('referalItems_________________________-_', referalItems);

    return (
      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg_page mb-10"  >

          <section>
            <div className='p-6'>
              <div className='w-full flex justify-center bg-white pt-6'>
                <h2 className='text-2xl text-center text-black'>Reffral Details</h2>
              </div>
              <div className='bg-white pb-6 px-2'>
                <div className="overflow-hidden pb-2 lg:p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full border border-[#B9B9B9] text-center">
                          <thead className="border border-[#B9B9B9] text-center bg-[#7C080B]">
                            <th scope="col" className="whitespace-nowrap border border-[#B9B9B9] text-center px-6 py-3 capitalize">username</th>
                            <th scope="col" className="whitespace-nowrap border border-[#B9B9B9] text-center px-6 py-3 capitalize">email</th>
                            {/* <th scope="col" className="whitespace-nowrap border border-[#B9B9B9] text-center px-6 py-3 capitalize">refByCode</th> */}
                            <th scope="col" className="whitespace-nowrap border border-[#B9B9B9] text-center px-6 py-3 capitalize">refCode</th>
                            <th scope="col" className="whitespace-nowrap border border-[#B9B9B9] text-center px-6 py-3 capitalize">Package sum</th>
                            <th scope="col" className="whitespace-nowrap border border-[#B9B9B9] text-center px-6 py-3 capitalize">Package Count</th>
                            <th scope="col" className="whitespace-nowrap border border-[#B9B9B9] text-center px-6 py-3 capitalize">Referral Count</th>
                          </thead>



                          <tbody>

                            {
                              referalCountSum && referalCountSum.length > 0 ?
                                referalCountSum.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td className="px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center">{element && element.userName ? element.userName : "-"}</td>
                                      <td className="px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center">{element && element.email ? element.email : "-"}</td>
                                      {/* <td className="px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center">{element && element.refByCode ? element.refByCode : "-"}</td> */}
                                      <td className="px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center">{element && element.refCode ? element.refCode : "-"}</td>
                                      <td className="px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center">{element && element.packagesum ? element.packagesum : 0}</td>
                                      <td className="px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center">{element && element.packagecount ? element.packagecount : 0}</td>

                                      {element && element.refCount === 0 ?
                                        <td className={`${element && element.refCount === 0 ? " px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center" : "px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center cursor-pointer"}`} >{element && element.refCount ? element.refCount : 0}</td>

                                        :
                                        <td className={`${element && element.refCount === 0 ? " px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center" : "px-6 py-1 whitespace-nowrap border border-[#B9B9B9] text-base text-black text-center cursor-pointer"}`} onClick={() => this.handleReferalById(element)}>{element && element.refCount ? element.refCount : 0}</td>

                                      }

                                    </tr>
                                  </React.Fragment>

                                )) : null
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

        </main>


      </>
    );
  }

}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting, user } = overview ? overview : {};
  // console.log("mapStateToProps______________dashboard::", dashboard);
  return {
    users,
    setting,
    user,
    dashboard
  };
}
export default connect(mapStateToProps)(Dashboard);
import React, { Component } from 'react';
import { userActions, alertActions, dashboardActions } from '../../_actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { isMobile } from "react-device-detect";
import WithdrawIndexModal from './components/WithdrawIndexModal';
import DepositIndexModal from './components/DepositIndexModal'
import AddNoteIndexModal from './components/AddNoteIndexModal'
import { DateRangePicker } from 'rsuite';
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import "./style.css";
import AddWalletNameIndexModal from './components/AddWalletNameIndexModal'


class Wallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      priceTempData: {
        MAAEXCH: 1,
        btcPrice: 0,
        dollerPrice: 0,
      },
      addNotesStateFields: {},
      fieldsIndex: {},
      errorsIndex: {},
      indexCardDetails: {},
      elementData: {},
      depositIndexCreateModal: false,

      copiedTxId: null,
      // copiedToAddress: null,

      WithdrawIndexCreateModal: false,
      AddNoteIndexModal: false,
      AddWalletNameIndexModal: false,
      commingSoon: false,
      showConvertCoinModal: false,
      currencyType: null,
      withdrawCoinField: '',
      coinCovertFrom: {
        "from": "INR",
        "to": "MAA",
        "amount": 0
      }
    }
  }

  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: this.state.pageNo,
      size: this.state.size
    }
    this.props.dispatch(userActions.getEVMAccountList(data));
    this.props.dispatch(userActions.bnbAmontFetchByEvmTx());
    this.props.dispatch(userActions.bnbAmont());
    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.users.sendCoinSuccess) {
      return {
        ...nextProps,
        WithdrawIndexCreateModal: false,
        AddNoteIndexModal: false,
        AddWalletNameIndexModal: false,
        fieldsIndex: {},
        errorsIndex: {}
      }
    } else if (nextProps.users.isTicketCreated) {
      // console.log('2222222222222333333333344444444444555555555566666666666');
      return {
        ...nextProps,
        commingSoon: true,
      }
    } else {
      return {
        ...nextProps
      }
    }
  }



  inputChange = (event) => {
    console.log('event________________________', event.target.value);
    event.preventDefault();
    const { fieldsIndex, errorsIndex } = this.state;
    fieldsIndex[event.target.name] = event.target.value;
    errorsIndex[event.target.name] = "";
    console.log(event.target.name, event.target.value);
    this.setState({ fieldsIndex, errorsIndex });
  }

  inputChangeAmount = (event) => {
    event.preventDefault();
    let { users } = this.props;
    let { overview } = users;
    let { setting } = overview;
    let { txFee } = setting ? setting : {};
    console.log("event.target.value  ", event.target.value);
    if (event.target.value >= 0) {
      const { fieldsIndex, errorsIndex } = this.state;
      fieldsIndex[event.target.name] = event.target.value;
      fieldsIndex['amount'] = event.target.value;
      fieldsIndex['fees'] = txFee ? txFee : 0.0001;
      fieldsIndex['recievingAmount'] = parseFloat(event.target.value - (txFee ? txFee : 0.0001)).toFixed(8);
      errorsIndex['amount'] = "";
      this.setState({ fieldsIndex, errorsIndex });
    } else {
      const { errorsIndex } = this.state;
      errorsIndex['amount'] = "Amount should be positive number.";
      this.setState({ errorsIndex });
    }
  }

  handlePageChange = (offset, page) => {
    console.log(offset, page)
  }

  inputChangePrice = (event) => {

    let { users } = this.props;
    let { priceDeta } = users;
    let { btcPrice, dollerPrice } = priceDeta ? priceDeta : {}

    event.preventDefault();
    const { priceTempData } = this.state;
    priceTempData[event.target.name] = event.target.value;
    priceTempData['dollerPrice'] = parseFloat(event.target.value * dollerPrice).toFixed(8);
    priceTempData['btcPrice'] = parseFloat(event.target.value * btcPrice).toFixed(8);
    this.setState({ priceTempData });
  }


  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getEVMAccountList(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "txType": "SEND_RECEIVED",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTx(data));
  }

  exportPDF = () => {
    let { users } = this.props;

    let { txList } = users;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Send and Received Report";
    const headers = [["DATE", "TRANSACTION TYPE", "AMOUNT", "COIN", "TRANSACTION STATUS"]];
    const data = txList.map(elt => [moment(new Date(parseInt(elt.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm"), elt.txType, elt.amount, elt.coin, elt.status ? "SUCESS" : "PENDING"]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(Date.now() + "_send_received_report.pdf")
  }

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  handleOpenDepositIndexModal = (data) => {
    this.setState({ elementData: data })
    this.setState({ depositIndexCreateModal: true, });
  }
  handleCloseDepositIndexModal = () => {
    this.setState({ depositIndexCreateModal: false });
  }

  handleOpenWithdrawIndexModal = (data) => {
    console.log('datadatadatadatadatadatadatadata', data);
    this.setState({ WithdrawIndexCreateModal: true, withdrawCoinField: data.id });
  }

  handleCloseWithdrawIndexModal = () => {
    this.setState({ WithdrawIndexCreateModal: false });
  }

  MoreDetails = (data) => {
    this.props.history.push(`evmWalletMoreDetails/${data.address}`)
    // console.log('datadatadatadatadatadatadatadataaddddddddddddddddd', data);
    // this.setState({ AddNoteIndexModal: true, addNotesStateFields: data });
  }

  handleOpenAddNoteIndexModal = (data) => {
    console.log('datadatadatadatadatadatadatadataaddddddddddddddddd', data);
    this.setState({ AddNoteIndexModal: true, addNotesStateFields: data });
  }
  handleCloseAddNoteIndexModal = () => {
    this.setState({ AddNoteIndexModal: false });
  }

  handleOpenAddWalletNameIndexModal = (data) => {
    console.log('datadatadatadatadatadatadatadataaddddddddddddddddd', data);
    this.setState({ AddWalletNameIndexModal: true, addNotesStateFields: data });
  }
  handleCloseAddWalletNameIndexModal = () => {
    this.setState({ AddWalletNameIndexModal: false });
  }

  handleOpencommingSoonModal = (data) => {
    this.setState({ elementData: data })
    let resData = {
      coinId: data.id,
      coinType: data.ticker
    }
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getAddressDepositTime(resData, temp))

    // let { users } = this.props;
    // let { addressDepositTime } = users;
    if (data && data.address) {
      // let dataRes = {
      //   address: data.address
      // }

      this.handleOpenDepositIndexModal()
    } else {
      this.props.dispatch(alertActions.success('Address create successfully.'))
    }
  }

  handleClosecommingSoonModal = () => {
    this.setState({ commingSoon: false });
  }

  createWalletSubmit = (data) => {
    console.log('data_data$$$$$', data);
    let reqData = {
      coinId: data.id
    }
    console.log('_createWallet_______data.id::', reqData);
    this.props.dispatch(userActions.createWallet(reqData));
  }


  handleSelectCovertCoin = (e, name) => {
    let { coinCovertFrom } = this.state;

    if (name === "from" || name === "to") {
      let coinsObj = {
        "MAA Coin": "MAA",
        "INR Coin": "INR"
      }
      coinCovertFrom[name] = coinsObj[e.target.value]
    }
    else {
      coinCovertFrom[e.target.name] = e.target.value
    }
    this.setState({ coinCovertFrom });
  }

  handleCoinConvertSubmit = () => {
    let { coinCovertFrom } = this.state;
    let data = {
      "from": coinCovertFrom.from,
      "to": coinCovertFrom.to,
      "amount": parseFloat(coinCovertFrom.amount)
    }
    this.props.dispatch(userActions.swapCoinAmount(data));
    this.handleCloseConvertCoinModal(false);
  }

  handleCloseConvertCoinModal = (check) => {
    this.setState({ showConvertCoinModal: check });
  }

  copyCodeToClipboard = (data) => {
    navigator.clipboard.writeText(data)
    this.props.dispatch(alertActions.success("Copied!"));
  }

  handleWithdrawSubmit = () => {
    if (this.handaleWithdrawValidation) {
      console.log('this.state.fieldsIndex_________________', this.state.fieldsIndex);
      let data = {
        id: this.state.withdrawCoinField,
        amount: this.state.fieldsIndex["amount"],
        to_address: this.state.fieldsIndex["address"],
        coinType: this.state.fieldsIndex["coinType"],
      }
      this.props.dispatch(userActions.sendCoin(data));
    }
  }

  handleAddNoteSubmit = () => {
    if (this.handaleAddNoteValidation) {
      let data = {
        id: this.state.addNotesStateFields.id,
        adminNote: this.state.fieldsIndex["adminNote"]
      }

      console.log('datadatadatadatadatadatadatadatadata', data);
      this.props.dispatch(userActions.addNotesInEVMAccount(data));
    }
  }

  handleAddWalletNameSubmit = () => {
    if (this.handaleAddNoteValidation) {
      let data = {
        id: this.state.addNotesStateFields.id,
        walletName: this.state.fieldsIndex["adminNote"]
      }

      console.log('datadatadatadatadatadatadatadatadata', data);
      this.props.dispatch(userActions.addNotesInEVMAccount(data));
    }
  }


  handaleWithdrawValidation = () => {
    let fieldsIndex = this.state.fieldsIndex;
    let errorsIndex = {};
    let formIsValid = true;

    //address
    if (!fieldsIndex["address"]) {
      formIsValid = false;
      errorsIndex["address"] = "Please Enter address!";
    }
    //amount
    if (!fieldsIndex["amount"]) {
      formIsValid = false;
      errorsIndex["amount"] = "Please Enter amount!";
    }
    console.log('errorsIndexerrorsIndexerrorsIndexerrorsIndexerrorsIndex', errorsIndex);
    this.setState({ errorsIndex: errorsIndex });
    return formIsValid;
  }

  handaleAddNoteValidation = () => {
    let fieldsIndex = this.state.fieldsIndex;
    let errorsIndex = {};
    let formIsValid = true;

    //address
    if (!fieldsIndex["address"]) {
      formIsValid = false;
      errorsIndex["address"] = "Please Enter address!";
    }
    //amount
    if (!fieldsIndex["amount"]) {
      formIsValid = false;
      errorsIndex["amount"] = "Please Enter amount!";
    }
    console.log('errorsIndexerrorsIndexerrorsIndexerrorsIndexerrorsIndex', errorsIndex);
    this.setState({ errorsIndex: errorsIndex });
    return formIsValid;
  }


  handleReloadTab = () => {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(dashboardActions.getCoinList(temp));
    this.props.dispatch(userActions.getRecieveCoin());
    this.props.dispatch(userActions.getTx(temp));
  }

  copyTxIdToClipboard = (txId) => {
    const tempInput = document.createElement('input');
    tempInput.value = txId;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.setState({ copiedTxId: txId });
    this.props.dispatch(alertActions.success("Copied!"));
  };



  handleSelectDate = (e) => {
    console.log('22222222222222222222222', e);
    let eeee = e[0];
    let eeeeEEEE = e[1];
    let data = moment(eeee).format('YYYY-MM-DD')
    let data1 = moment(eeeeEEEE).format('YYYY-MM-DD')


    let temp1 = {
      from: data,
      to: data1,
    }

    let temp = {
      from: data,
      to: data1,
      keyWord: "",
      pageNo: this.state.pageNo,
      size: this.state.size
    }

    this.props.dispatch(userActions.getEVMAccountList(temp));
    this.props.dispatch(userActions.bnbAmontFetchByEvmTx(temp1));
    this.props.dispatch(userActions.bnbAmont(temp1));
  }

  handleSelectDate1 = (e) => {
    console.log('33333333333333333333', e);
    if (e != null) {
      let data = moment(e[0]).format('YYYY-MM-DD')

      let temp1 = {
        from: data
      }
      let temp = {
        from: data,
        keyWord: "",
        pageNo: this.state.pageNo,
        size: this.state.size
      }

      // this.props.dispatch(userActions.getEVMTxList(temp));
      this.props.dispatch(userActions.getEVMAccountList(temp));
      this.props.dispatch(userActions.bnbAmontFetchByEvmTx(temp1));
      this.props.dispatch(userActions.bnbAmont(temp1));
    }
  }




  render() {

    let { users } = this.props;
    let { getEVMAccountList, loading, bnbAmont, bnbAmontFetchByEvmTx, walletList } = users;

    let total = getEVMAccountList && getEVMAccountList.total ? getEVMAccountList.total : 0

    console.log('getEVMAccountList____________________', getEVMAccountList);

    const predefinedRanges = [
      {
        label: "Today",
        value: [new Date(), new Date()],
        placement: "left",
      },
      {
        label: "Yesterday",
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: "left"
      },
      {
        label: "Last 7 days",
        value: [subDays(new Date(), 6), new Date()],
        placement: "left"
      },
      {
        label: "This month",
        value: [startOfMonth(new Date()), new Date()],
        placement: "left"
      },

      {
        label: "All time",
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        placement: "left"

      },
      {
        label: "Next week",
        closeOverlay: true,
        value: (value) => {
          const [start = new Date()] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
          ];
        },
        appearance: "default"
      }
    ];

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <main className="relative z-0 flex-1 overflow-y-auto bg_page focus:outline-none"  >
          <div className="p-3 space-y-6 2xl:p-10 sm:p-5">

            <div className='flex items-center justify-center'>
              <div className="w-full py-5 cursor-pointer ">
                <div className='flex justify-center space-x-3'>
                  <DateRangePicker
                    ranges={predefinedRanges}
                    placeholder="Select Date"
                    style={{ width: 260 }}
                    onOk={this.handleSelectDate}
                    onChange={this.handleSelectDate1}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-5 lg:gap-6 w-full">
              <div className="w-full px-7 py-7 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                <div className="w-full flex justify-center items-center">
                  <div className="flex flex-col justify-center items-center">
                    <span className="text-xl md:text-2xl lg:text-3xl font-bold text-[#B5862B]">
                      Total Balance
                    </span>
                    <span className="text-xl md:text-2xl lg:text-4xl font-bold text-white">
                      {bnbAmont && bnbAmont.totalBalance ? bnbAmont.totalBalance.toFixed(7) : 0}
                    </span>
                  </div>
                </div>

              </div>
              <div className="w-full px-7 py-7 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                <div className="w-full flex justify-center items-center">
                  <div className="flex flex-col justify-center items-center">
                    <span className="text-xl md:text-2xl lg:text-3xl font-bold text-[#B5862B]">
                      Total Send
                    </span>
                    <span className="text-xl md:text-2xl lg:text-4xl font-bold text-white">
                      {bnbAmontFetchByEvmTx && bnbAmontFetchByEvmTx.sendBalance ? bnbAmontFetchByEvmTx.sendBalance.toFixed(7) : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full px-7 py-7 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                <div className="w-full flex justify-center items-center">
                  <div className="flex flex-col justify-center items-center">
                    <span className="text-xl md:text-2xl lg:text-3xl font-bold text-[#B5862B]">
                      Total Recieved
                    </span>
                    <span className="text-xl md:text-2xl lg:text-4xl font-bold text-white">
                      {bnbAmontFetchByEvmTx && bnbAmontFetchByEvmTx.ReceiveBalance ? bnbAmontFetchByEvmTx.ReceiveBalance.toFixed(7) : 0}
                    </span>
                  </div>
                </div>
              </div>

            </div>

            <section className='overflow-hidden'>
              <div className='overflow-y-auto'>
                <div className='space-y-3 '>

                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">
                      <div className="inline-block min-w-full">
                        <table className="min-w-full bg-[#24243C] rounded-lg">
                          <thead className="">
                            <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                              <th className="px-3 py-3 whitespace-nowrap">S/n</th>
                              <th className="px-3 py-3 whitespace-nowrap">Address</th>
                              <th className="px-3 py-3 whitespace-nowrap">BNB Amount</th>
                              <th className="px-3 py-3 whitespace-nowrap">Eth Amount</th>
                              <th className="px-3 py-3 whitespace-nowrap">Transaction Count</th>
                              <th className="px-3 py-3 whitespace-nowrap">Wallet Name</th>
                              <th className="px-3 py-3 whitespace-nowrap">Admin Note</th>
                              <th className="px-3 py-3 whitespace-nowrap">date</th>
                              <th className="px-3 py-3 whitespace-nowrap">Action</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              getEVMAccountList && getEVMAccountList.list && getEVMAccountList.list.length > 0 ?
                                getEVMAccountList.list.map((element, index) => (
                                  <>
                                    <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.slug ? element.slug : ""}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.address ? element.address : ""}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.bnbInfo && element.bnbInfo.balance && element.bnbInfo.balance > 0 ? (element.bnbInfo.balance).toFixed(7) : 0}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.ethInfo && element.ethInfo.balance && element.ethInfo.balance > 0 ? (element.ethInfo.balance).toFixed(7) : 0}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.bnbInfo && element.bnbInfo.txCount ? element.bnbInfo.txCount + element.ethInfo.txCount : 0}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.walletName ? element.walletName : ""}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{element && element.adminNote ? element.adminNote : ""}</td>
                                      <td className="px-3 py-3 whitespace-nowrap">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                      <td className="px-3 py-3 whitespace-nowrap space-x-2">
                                        <span onClick={() => this.handleOpenWithdrawIndexModal(element)} className='bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer'>Send</span>
                                        <span onClick={() => this.handleOpenDepositIndexModal(element)} className='bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer'>Recieved</span>
                                        <span onClick={() => this.handleOpenAddWalletNameIndexModal(element)} className='bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer'>Add Wallet Name</span>
                                        <span onClick={() => this.handleOpenAddNoteIndexModal(element)} className='bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer'>Add Note</span>
                                        <span onClick={() => this.MoreDetails(element)} className='bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer'>More Details</span>
                                      </td>
                                    </tr>
                                  </>
                                )) : <tr className='bg-[#24243C]'>
                                  <td colSpan={8} className="text-center text-gray-700">Data Not Found</td>
                                </tr>
                            }

                          </tbody>
                        </table>
                      </div>
                    </div>

                    {isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                    }
                  </section>
                </div>

              </div>
            </section>
          </div>
        </main>

        <WithdrawIndexModal
          WithdrawIndexCreateModal={this.state.WithdrawIndexCreateModal}
          handleCloseWithdrawIndexModal={this.handleCloseWithdrawIndexModal}
          handleWithdrawSubmit={this.handleWithdrawSubmit}
          errorsIndex={this.state.errorsIndex}
          fieldsIndex={this.state.fieldsIndex}
          inputChange={this.inputChange}
        />

        <AddNoteIndexModal
          AddNoteIndexModal={this.state.AddNoteIndexModal}
          handleCloseAddNoteIndexModal={this.handleCloseAddNoteIndexModal}
          handleAddNoteSubmit={this.handleAddNoteSubmit}
          errorsIndex={this.state.errorsIndex}
          fieldsIndex={this.state.fieldsIndex}
          inputChange={this.inputChange}
        />
        <AddWalletNameIndexModal
          AddNoteIndexModal={this.state.AddWalletNameIndexModal}
          handleCloseAddNoteIndexModal={this.handleCloseAddWalletNameIndexModal}
          handleAddNoteSubmit={this.handleAddWalletNameSubmit}
          errorsIndex={this.state.errorsIndex}
          fieldsIndex={this.state.fieldsIndex}
          inputChange={this.inputChange}
        />
        <DepositIndexModal
          depositIndexCreateModal={this.state.depositIndexCreateModal}
          handleCloseDepositIndexModal={this.handleCloseDepositIndexModal}
          copyCodeToClipboard={this.copyCodeToClipboard}
          elementData={this.state.elementData}
          addressDepositTime={this.state.addressDepositTime}
        />

      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,

  };
}
export default connect(mapStateToProps)(Wallet);

import React from "react";

import Modal from 'react-modal';
import moment from 'moment';
import { MdClose } from "react-icons/md";

export default function WithdrawIndexModal(props) {

  let { AddNoteIndexModal, handleCloseAddNoteIndexModal, handleAddNoteSubmit, inputChange, fieldsIndex, errorsIndex } = props;

  return (

    <>
      {AddNoteIndexModal ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
          <div className="lg:w-[30%] w-full bg-[#24243C] z-50 mx-auto rounded-lg shadow-lg">
            <div className="flex justify-between items-center w-full p-4">
              <div className="font-semibold text-lg text-white">Add Wallet Name</div>
              <button className="inline-flex p-2 rounded-full " type="button">
                <MdClose className='w-6 h-6 text-white' onClick={() => handleCloseAddNoteIndexModal()} />
              </button>
            </div>
            <div className='p-4 space-y-3'>
              <div className="relative px-4 rounded-md shadow-sm">
                <input className={`w-full bg-[#1D1933] py-2 px-3 rounded-md placeholder:text-[#6D6997] text-gray-600 outline-none ${!errorsIndex["amount"] ? "'border-opacity-20 " : "order-opacity-100  bg-gray-100"}`}
                  name="adminNote"
                  value={fieldsIndex["adminNote"] ? fieldsIndex["adminNote"] : ''}
                  onChange={inputChange} placeholder="WALLET NAME" type="adminNote" />
                {errorsIndex["adminNote"] ?
                  <div className="mt-1 font-serif text-red-500 invalid-feedback">
                    {errorsIndex["adminNote"]}
                  </div>
                  : null}
              </div>
              <div className="w-64 py-6 mx-auto sm:w-72 ">
                <button className="flex justify-center w-full px-4 py-3 mx-auto text-sm font-semibold text-white uppercase rounded-md all-btn-color focus:outline-none" type="button"
                  onClick={() => handleAddNoteSubmit()}
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>

  );
}
